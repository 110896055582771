import {useLocationWatcher} from '@navbar/common/composables';
import {useHeaderNavigationTabs} from '@navbar-logged/features/header-menu';
import {computed} from 'vue';
import type {NavigationTab} from '../aside-menu.types';
import {useNavigationTabs} from './navigation/useNavigationTabs';

export type ActiveRoute = {id: string; isActive: boolean};

/**
 * Returns the ID of the current active route
 *
 * @param routes - The routes collections to check (header is included by default)
 */
export function useActiveRoute() {
    const {pathname, routeUtils} = useLocationWatcher();

    const {filteredNavigationRoutes} = useNavigationTabs();
    const {headerNavigationTabs} = useHeaderNavigationTabs();

    /**
     * - Flatten array with children
     * - Process isActiveWhen functions of each route by providing helper functions
     */
    const filteredAndFlattenRoutes = computed<ActiveRoute[]>(() => {
        return [...filteredNavigationRoutes.value, ...headerNavigationTabs.value]
            .reduce((acc, value) => {
                return acc.concat([value, ...(value.children ?? value.ghostChildren ?? [])]);
            }, [] as NavigationTab[])
            .map(({id, isActiveWhen}) => {
                return {
                    id,
                    isActive: isActiveWhen ? isActiveWhen(routeUtils) : false,
                };
            });
    });

    const currentActiveRoute = computed<string | undefined>(() => {
        const activeRoute = filteredAndFlattenRoutes.value.find(({isActive}) => !!isActive);
        if (activeRoute) {
            return activeRoute.id;
        }
        return undefined;
    });

    return {
        currentActiveRoute,
        pathname,
    };
}
