<template>
    <div id="modify-availability-dialog" class="modify-availability-dialog">
        <ClientOnly>
            <template v-if="showUpdateAvailability">
                <VJoyDialog v-if="componentSelected === ComponentSelected.DIALOG" ref="dialogRef" open @dialog:hide="handleCloseModal">
                    <template #dialog-header>
                        <p>{{ t('profile.availability.edit.question') }}</p>
                    </template>
                    <template #dialog-body>
                        <ModifyAvailability
                            radios-direction="horizontal"
                            :show-calendar-relative="true"
                            :state="updateAvailability"
                            @stateUpdated="updateUpdateAvailability"
                        />
                    </template>
                    <template #dialog-confirm>
                        <VJoyButton variant="main" :disabled="!updateAvailabilityIsValid" @click="onSubmit">
                            {{ t('navbar.availability.cta.confirm') }}
                        </VJoyButton>
                    </template>
                </VJoyDialog>

                <Transition name="fade-scale">
                    <div v-if="!isSmallScreen && componentSelected === ComponentSelected.DROPDOWN" ref="dropdownRef" class="availability-dropdown">
                        <VJoyLabel class="availability-dropdown__label">{{ t('profile.availability.edit.question') }}</VJoyLabel>
                        <ModifyAvailability radios-direction="horizontal" :state="updateAvailability" @state-updated="updateUpdateAvailability" />
                        <VJoyButton
                            data-testid="navbar-availability-dropdown-confirm"
                            variant="main"
                            :disabled="!updateAvailabilityIsValid"
                            @click="onSubmit"
                        >
                            {{ t('navbar.availability.cta.confirm') }}
                        </VJoyButton>
                    </div>
                </Transition>
            </template>

            <Teleport to="body">
                <VJoyBottomSheet v-if="isSmallScreen && showUpdateAvailability" ref="bottomSheetRef" open @bottom-sheet:close="handleCloseModal">
                    <template #bottom-sheet-content>
                        <div>
                            <h2 class="modify-availability-dialog__bottom-sheet-title">{{ t('profile.availability.edit.question') }}</h2>
                            <ModifyAvailability radios-direction="vertical" :state="updateAvailability" @state-updated="updateUpdateAvailability" />
                        </div>
                    </template>
                    <template #bottom-sheet-cta>
                        <VJoyWrapper item-stretch no-margin>
                            <VJoyButton variant="main" :disabled="!updateAvailabilityIsValid" @click="onSubmit">
                                {{ t('navbar.availability.cta.confirm') }}
                            </VJoyButton>
                        </VJoyWrapper>
                    </template>
                </VJoyBottomSheet>
            </Teleport>
        </ClientOnly>
    </div>
</template>
<script setup lang="ts">
    import {computed, useLogger, useTranslation} from '#imports';
    import {useDetectOutsideClick, useMediaQuery} from '#malt/nuxt-utils-module';
    import {pushVJoySnackbar, VJoyLabel, VJoyButton, VJoyDialog, VJoyBottomSheet, VJoyWrapper} from '@maltjoy/core-vue';
    import {storeToRefs} from 'pinia';
    import {ref, watch} from 'vue';
    import {useAvailability} from '../../availability.store';
    import ModifyAvailability from './ModifyAvailability.vue';
    import type {AvailabilityUpdateRequestResource} from '@navbar-api';
    import {ComponentSelected} from '../../availability.types';

    const {t} = useTranslation();
    const logger = useLogger();

    const availabilityStore = useAvailability();
    const {updateAvailability, updateAvailabilityIsValid, showUpdateAvailability, componentSelected} = storeToRefs(availabilityStore);

    const bottomSheetRef = ref<InstanceType<typeof VJoyBottomSheet>>();
    const dropdownRef = ref<HTMLDivElement>();
    const dialogRef = ref<InstanceType<typeof VJoyDialog>>();

    const {matchMedia} = useMediaQuery();
    const isSmallScreen = computed(() => matchMedia.value.max.Xs);

    const {startDetecting, stopDetecting} = useDetectOutsideClick(
        dropdownRef,
        () => {
            if (showUpdateAvailability.value) {
                handleCloseModal();
            }
        },
        {immediate: false},
    );

    function modifyAvailabilityDialogHoverDetect() {
        if (showUpdateAvailability.value && !isSmallScreen.value) {
            startDetecting();
        } else {
            stopDetecting();
        }
    }

    watch([showUpdateAvailability, isSmallScreen], () => modifyAvailabilityDialogHoverDetect(), {immediate: true});

    watch(
        isSmallScreen,
        () => {
            if (!isSmallScreen.value) {
                dialogRef.value?.show();
            } else {
                dialogRef.value?.hide({fireHideEvent: false});
            }
        },
        {immediate: true},
    );

    function handleCloseModal() {
        showUpdateAvailability.value = false;
        componentSelected.value = ComponentSelected.DROPDOWN;
    }

    async function onSubmit() {
        if (updateAvailabilityIsValid.value) {
            try {
                await availabilityStore.saveAvailability();
            } catch (error) {
                pushVJoySnackbar({props: {message: t('profile.availability.modify.error'), level: 'error'}});
                logger.error(error);
                return;
            } finally {
                handleCloseModal();
            }
        }
    }

    function updateUpdateAvailability(arg: Partial<AvailabilityUpdateRequestResource>) {
        if (updateAvailability.value) {
            updateAvailability.value = {...updateAvailability.value, ...arg};
        }
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/text' as text;

    .modify-availability-dialog {
        position: initial;

        &__bottom-sheet-title {
            @include text.getFontBySize(primary-450);
            margin-bottom: var(--joy-core-spacing-7);
        }
    }

    .availability-dropdown {
        position: absolute;
        right: 30px;
        top: calc(var(--layout-padding-top) + 10px);
        background: white;
        box-shadow: var(--joy-core-elevation-4);
        width: 500px;
        padding: 30px;
        border-radius: 16px;

        &__label {
            margin-bottom: var(--joy-core-spacing-6);

            :deep(label) {
                @include text.getFontBySize(primary-450);
            }
        }
    }
</style>
