<template>
    <div class="navigation-tabs-container flex" :class="{isReduced: isDisplayModeReduced, isMobile: isDisplayMobile}">
        <template v-if="renderedList.length">
            <template v-for="tab of renderedList" :key="tab.id">
                <div v-if="tab.ghostChildren?.length" :data-testid="tab.id" class="navigation-tab-container_ghosts flex">
                    <NavigationTabItem v-for="childTab of tab.ghostChildren" :key="childTab.id" :tab="childTab" />
                </div>
                <template v-else>
                    <div v-if="tab.separator === 'top'" :key="`separator-${tab.id}`" class="separator flex"></div>
                    <NavigationTabItem :tab="tab" />
                    <div v-if="tab.separator === 'bottom'" :key="`separator-${tab.id}`" class="separator flex"></div>
                </template>
            </template>
        </template>
        <NavigationTabsSkeletons v-else />
    </div>
</template>

<script setup lang="ts">
    import {useDisplayMobile} from '#malt/nuxt-utils-module';
    import {useUser} from '@navbar-logged/features/user';
    import {useNavbarState} from '@navbar-registry';
    import {storeToRefs} from 'pinia';
    import {computed} from 'vue';
    import type {NavigationTab} from '..';
    import {useAsideMenu} from '../aside-menu.store';
    import {useDisplayMode} from '../stores';
    import NavigationTabItem from './NavigationTabItem.vue';
    import NavigationTabsSkeletons from './NavigationTabsSkeletons.vue';

    const {isDisplayMobile} = useDisplayMobile();
    const asideMenuStore = useAsideMenu();
    const navbarState = useNavbarState();
    const displayModeStore = useDisplayMode();
    const {isDisplayModeReduced} = storeToRefs(displayModeStore);
    const userStore = useUser();
    const {filteredNavigationRoutes} = storeToRefs(asideMenuStore);

    const renderedList = computed<NavigationTab[]>(() => {
        if (navbarState.pending && !userStore.cachedIdentityRole) {
            return [];
        } else {
            return filteredNavigationRoutes.value;
        }
    });
</script>

<style lang="scss" scoped>
    .navigation-tabs-container {
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        margin-top: var(--joy-core-spacing-4);

        &_ghosts {
            flex-flow: column wrap;
        }

        .separator {
            height: 1px;
            width: 210px;
            margin: var(--joy-core-spacing-4) auto;
            background-color: var(--joy-color-neutral-30);
            transition: margin 0.3s ease, width 0.3s ease;
        }

        &.isReduced {
            .separator {
                width: 22px;
            }
        }
    }
</style>
