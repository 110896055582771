/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Photo
 */
export interface Photo {
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    legend?: string;
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    deleteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    baseUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Photo
     */
    cropped?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Photo
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof Photo
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    originalMimeType?: string;
    /**
     * 
     * @type {string}
     * @memberof Photo
     */
    originalExtension?: string;
}

/**
 * Check if a given object implements the Photo interface.
 */
export function instanceOfPhoto(value: object): value is Photo {
    return true;
}

export function PhotoFromJSON(json: any): Photo {
    return PhotoFromJSONTyped(json, false);
}

export function PhotoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Photo {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'legend': json['legend'] == null ? undefined : json['legend'],
        'deleteUrl': json['deleteUrl'] == null ? undefined : json['deleteUrl'],
        'type': json['type'] == null ? undefined : json['type'],
        'baseUrl': json['baseUrl'] == null ? undefined : json['baseUrl'],
        'cropped': json['cropped'] == null ? undefined : json['cropped'],
        'width': json['width'] == null ? undefined : json['width'],
        'height': json['height'] == null ? undefined : json['height'],
        'originalMimeType': json['originalMimeType'] == null ? undefined : json['originalMimeType'],
        'originalExtension': json['originalExtension'] == null ? undefined : json['originalExtension'],
    };
}

export function PhotoToJSON(value?: Photo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'legend': value['legend'],
        'deleteUrl': value['deleteUrl'],
        'type': value['type'],
        'baseUrl': value['baseUrl'],
        'cropped': value['cropped'],
        'width': value['width'],
        'height': value['height'],
        'originalMimeType': value['originalMimeType'],
        'originalExtension': value['originalExtension'],
    };
}

