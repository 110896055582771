import {useRequestHeaders} from '#imports';
import {getCurrentInstance, onScopeDispose, readonly, ref} from 'vue';
import type {SharedBreakpointsOptions} from './types';
import {BreakPointsValues} from './types';
import {useBreakpointOperations} from './useBreakpointOperations';

/**
 * Return reactive computed variables based on browser window size

 * ### ⚠️ Only use inside `setup` function or in a composable
 * ### ⚠️ This version if for use in nuxt applications only, for vue applications see joy-entrypoint's implementation

 * @param options - composable options {@link SharedBreakpointsOptions}
 *
 * @example
 *
 * ```ts
 *  const {windowWidth, currentBreakpoint, matchMedia} = useMediaQuery();
 *
 *  const isSmall = computed(() => matchMedia.value.max.md)
 * ```
 *
 * ## Usage supporting the dynamic side bar nav
 *
 * ```vue
 * <template>
        <div class="root" :class="[matchNavbarMedia.max]"></div>
    </template>

    <script setup lang="ts">
        import {useMediaQuery} from '#malt/nuxt-utils-module';

        const {matchNavbarMedia} = useMediaQuery();
    </script>

    <style lang="scss">
        .root {
            &.Sm {
                margin-left: 10px;
            }
            // Is the same as equivalent before:
            @include mq.screen_sm {
                margin-left: 10px;
            }
        }
    </style>

 * ```
 */
export function useMediaQuery(options?: SharedBreakpointsOptions) {
    const windowWidth = ref(0);
    const windowHeight = ref(0);

    const mountedDocument = import.meta.server ? undefined : document.documentElement;

    let mutationObserver: MutationObserver | undefined;

    const layoutPaddingLeft = ref<number | null>(null);

    const {handleResize, currentBreakpoint, matchMedia, matchNavbarMedia} = useBreakpointOperations({
        container: mountedDocument,
        containerWidth: windowWidth,
        containerHeight: windowHeight,
        options,
        layoutPaddingLeft,
        type: 'window',
    });

    if (import.meta.server) {
        const {'user-agent': userAgent} = useRequestHeaders(['user-agent']);
        const isMobileClient = userAgent && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        if (isMobileClient) {
            windowWidth.value = BreakPointsValues.Xxs;
            windowHeight.value = 1000;
        } else {
            windowWidth.value = BreakPointsValues.Lg;
            windowHeight.value = 1000;
        }
    } else {
        windowWidth.value = window.document.documentElement.clientWidth || window.innerWidth;
        windowHeight.value = window.document.documentElement.clientHeight || window.innerHeight;
        mutationObserver = new MutationObserver(handleMutationChange);
        window.addEventListener('resize', handleResize);
        handleResize();
        handleMutationChange();
        mutationObserver.observe(document.documentElement, {
            attributes: true,
            childList: false,
            attributeFilter: ['style'],
            subtree: false,
        });

        if (getCurrentInstance()) {
            onScopeDispose(() => {
                window.removeEventListener('resize', handleResize);
                mutationObserver?.disconnect();
                mutationObserver = undefined;
            });
        }
    }

    function handleMutationChange() {
        window.requestAnimationFrame(() => {
            const rawValue = parseInt(document.documentElement.style.getPropertyValue('--layout-padding-left'));
            layoutPaddingLeft.value = isNaN(rawValue) ? 0 : rawValue;
        });
    }

    return {
        windowHeight: readonly(windowHeight),
        windowWidth: readonly(windowWidth),
        currentBreakpoint,
        matchMedia,
        matchNavbarMedia,
    };
}
