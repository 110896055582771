<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.1633 3.94737C7.63526 3.94737 3.96456 7.55265 3.96456 12C3.96456 13.2756 4.26618 14.4807 4.80276 15.5513C4.89654 15.7384 4.92968 15.9554 4.885 16.1692L4.09898 19.9311L8.05765 19.2036C8.26516 19.1654 8.47449 19.1963 8.65685 19.2812C9.71937 19.7757 10.9077 20.0526 12.1633 20.0526C15.7403 20.0526 18.7834 17.8025 19.9031 14.6634C19.9912 14.4165 20.2664 14.2866 20.5177 14.3731C20.7691 14.4596 20.9015 14.7297 20.8134 14.9767C19.5624 18.4838 16.1626 21 12.1633 21C10.7619 21 9.4327 20.6907 8.2438 20.1373C8.23844 20.1349 8.23476 20.1349 8.23476 20.1349L4.27637 20.8623C3.60284 20.9861 3.01636 20.3999 3.15412 19.7407L3.94007 15.9791C3.94007 15.9791 3.94013 15.9753 3.93736 15.9697C3.33699 14.7717 3 13.4238 3 12C3 7.02943 7.10254 3 12.1633 3C17.224 3 21 6.70998 21 11.6805C21 11.9421 20.784 12.1542 20.5177 12.1542C20.2514 12.1542 20.0354 11.9421 20.0354 11.6805C20.0354 7.2332 16.6913 3.94737 12.1633 3.94737Z"
                    fill="currentcolor"
                    stroke="currentcolor"
                    stroke-width="0.5"
                />
                <path
                    d="M12.1089 6.41187C10.9328 6.41187 9.97429 7.35285 9.97429 8.50806C9.97429 9.66326 10.9328 10.6047 12.1089 10.6047C13.2851 10.6047 14.2432 9.66326 14.2432 8.50806C14.2432 7.35285 13.2851 6.41187 12.1089 6.41187ZM12.1089 6.85353C13.0421 6.85353 13.7935 7.59154 13.7935 8.50806C13.7935 9.42457 13.0421 10.163 12.1089 10.163C11.1758 10.163 10.424 9.42457 10.424 8.50806C10.424 7.59154 11.1758 6.85353 12.1089 6.85353ZM12.1089 11.3763C11.9414 11.3763 11.7737 11.3827 11.6386 11.3953C9.30185 11.6134 7.4412 13.4409 7.21912 15.736C7.18296 16.1097 7.50105 16.4188 7.87124 16.4188H16.3462C16.7164 16.4188 17.0349 16.1097 16.9988 15.736C16.7767 13.4409 14.9156 11.6134 12.5788 11.3953C12.4438 11.3827 12.2765 11.3763 12.1089 11.3763ZM12.1089 11.8193C12.2668 11.8193 12.4246 11.8243 12.5367 11.8348C14.6605 12.033 16.349 13.6919 16.5509 15.7779C16.5617 15.8897 16.4727 15.9771 16.3462 15.9771H7.87124C7.74473 15.9771 7.65578 15.8897 7.6666 15.7779C7.86844 13.6919 9.55744 12.033 11.6812 11.8348C11.7933 11.8243 11.9511 11.8193 12.1089 11.8193Z"
                    fill="currentcolor"
                    stroke="currentcolor"
                    stroke-width="0.7"
                />
            </svg>
        </template>
        <template #active>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.1633 3.94737C7.63526 3.94737 3.96456 7.55265 3.96456 12C3.96456 13.2756 4.26618 14.4807 4.80276 15.5513C4.89654 15.7384 4.92968 15.9554 4.885 16.1692L4.09898 19.9311L8.05765 19.2036C8.26516 19.1654 8.47449 19.1963 8.65685 19.2812C9.71937 19.7757 10.9077 20.0526 12.1633 20.0526C15.7403 20.0526 18.7834 17.8025 19.9031 14.6634C19.9912 14.4165 20.2664 14.2866 20.5177 14.3731C20.7691 14.4596 20.9015 14.7297 20.8134 14.9767C19.5624 18.4838 16.1626 21 12.1633 21C10.7619 21 9.4327 20.6907 8.2438 20.1373C8.23844 20.1349 8.23476 20.1349 8.23476 20.1349L4.27637 20.8623C3.60284 20.9861 3.01636 20.3999 3.15412 19.7407L3.94007 15.9791C3.94007 15.9791 3.94013 15.9753 3.93736 15.9697C3.33699 14.7717 3 13.4238 3 12C3 7.02943 7.10254 3 12.1633 3C17.224 3 21 6.70998 21 11.6805C21 11.9421 20.784 12.1542 20.5177 12.1542C20.2514 12.1542 20.0354 11.9421 20.0354 11.6805C20.0354 7.2332 16.6913 3.94737 12.1633 3.94737Z"
                    fill="currentcolor"
                    stroke="currentcolor"
                    stroke-width="0.5"
                />
                <g clip-path="url(#clip0_228_6893)">
                    <path
                        d="M12.24 11.3101C12.07 11.3101 11.9 11.3101 11.77 11.3301C9.43001 11.5501 7.57001 13.3801 7.35001 15.6701C7.31001 16.0401 7.63001 16.3501 8.00001 16.3501H16.47C16.84 16.3501 17.16 16.0401 17.12 15.6701C16.9 13.3701 15.04 11.5501 12.7 11.3301C12.56 11.3201 12.4 11.3101 12.23 11.3101H12.24Z"
                        fill="currentcolor"
                        stroke="currentcolor"
                        stroke-width="0.7"
                    />
                    <path
                        d="M12.24 6C10.87 6 9.76001 7.1 9.76001 8.45C9.76001 9.8 10.87 10.9 12.24 10.9C13.61 10.9 14.72 9.8 14.72 8.45C14.72 7.1 13.61 6 12.24 6ZM12.24 10.11C11.57 10.11 11.02 9.61 11.02 9C11.02 8.86 11.14 8.74 11.3 8.74C11.46 8.74 11.58 8.86 11.58 9C11.58 9.33 11.88 9.59 12.24 9.59C12.6 9.59 12.9 9.32 12.9 9C12.9 8.86 13.02 8.74 13.18 8.74C13.34 8.74 13.46 8.86 13.46 9C13.46 9.61 12.91 10.11 12.24 10.11Z"
                        fill="currentcolor"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_228_6893">
                        <rect width="10.49" height="10.71" fill="white" transform="translate(7 6)" />
                    </clipPath>
                </defs>
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
