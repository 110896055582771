
/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  ProfilePreferencesResource,
} from '../models';
import {
    ProfilePreferencesResourceFromJSON,
    ProfilePreferencesResourceToJSON,
} from '../models';

/**
 * 
 */
export class FreelancerProfilePreferencesApi extends runtime.BaseAPI {

    /**
     */
    async getProfilePreferencesRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<ProfilePreferencesResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<ProfilePreferencesResource>({
            path: `/navbar/api/profile-preferences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=ProfilePreferencesResourceFromJSON(response._data); }
        return response;
    }

    /**
     */
    async getProfilePreferences(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<ProfilePreferencesResource> {
        const response = await this.getProfilePreferencesRaw(initOverrides);
        return response._data;
    }

}
