import type {NavigationTab} from '@navbar-logged/features/aside-menu';
import {storeToRefs} from 'pinia';
import {useTranslation} from '#imports';
import {computed, ref} from 'vue';
import {useUser} from '../../user';
import {HelpCenterIcon, ResourcesIcon, ListIcon} from '@navbar-logged/common/icons';
import {navbarEventBus} from '@navbar/events';

export function useHeaderNavigationTabs() {
    const {t} = useTranslation();
    const userStore = useUser();

    const currentLanguage = computed(() => userStore.user?.currentLanguage);

    const isFreelancerIdentity = computed(() => userStore.selectedIdentity?.type === 'FREELANCE');
    const urlHelpCenter = computed(() => (userStore.hasSelectedClientIdentity ? '18214' : '18216'));
    const currentCountry = computed(() => userStore.user?.currentCountry);

    const resourcesUrls: {[index: string]: string} = {
        FR: '/resources',
        'en-FR': 'https://www.malt.fr/resources',
        GB: '/resources',
        BE: '/resources',
        'en-BE': 'https://fr.malt.be/c/resources',
        DE: '/resources',
        'en-DE': 'https://www.malt.de/resources',
        ES: '/resources',
        'en-ES': 'https://www.malt.es/resources',
        NL: '/resources',
        'en-NL': 'https://www.malt.nl/resources',
        'fr-CH': 'https://www.malt.fr/resources',
    };

    const resourceUrl = computed(() => {
        if (currentLanguage.value && currentCountry.value && resourcesUrls[currentLanguage.value + '-' + currentCountry.value]) {
            return resourcesUrls[currentLanguage.value + '-' + currentCountry.value];
        }
        if (currentCountry.value && resourcesUrls[currentCountry.value as string]) {
            return resourcesUrls[currentCountry.value as string];
        }
        return 'https://www.malt.uk/resources';
    });

    const faqUrl = computed(() => ({
        href: `https://help.malt.com/kb/${currentLanguage.value}/${urlHelpCenter.value}`,
        blank: true,
    }));

    const {selectedIdentity} = storeToRefs(userStore);
    const shouldGetStartedOnMaltTabBePresent = computed(
        () =>
            (selectedIdentity.value?.serviceLevel === 'ADVANCED' || selectedIdentity.value?.serviceLevel === 'CORPORATE') &&
            isClientOnboardingPresent.value,
    );

    const isClientOnboardingPresent = ref(false);
    function onClientOnboardingIsPresent() {
        isClientOnboardingPresent.value = true;
    }

    return {
        headerNavigationTabs: computed((): NavigationTab[] => [
            {
                id: 'HelpCenter',
                title: t('navbar.header.tabs.help.center.title'),
                icons: {
                    mainIcon: HelpCenterIcon,
                },
                url: isFreelancerIdentity.value ? faqUrl.value : undefined,
                children:
                    userStore.selectedIdentity?.type === 'FREELANCE'
                        ? undefined
                        : [
                              {
                                  id: 'faq',
                                  title: t('navbar.header.tabs.help.center.title'),
                                  icons: {
                                      mainIcon: HelpCenterIcon,
                                  },
                                  url: faqUrl.value,
                              },
                              {
                                  id: 'freelancer-resources-center',
                                  title: t('navbar.aside.tabs.title.freelancer.resources.center'),
                                  icons: {
                                      mainIcon: ResourcesIcon,
                                  },
                                  url: {href: resourceUrl.value, blank: true},
                              },
                              ...(shouldGetStartedOnMaltTabBePresent.value
                                  ? [
                                        {
                                            id: 'client-walkthrough',
                                            title: t('navbar.header.client.onboarding.get.started'),
                                            customAttrs: {
                                                'data-client-onboarding-get-started-on-malt': 'true',
                                            },
                                            icons: {
                                                mainIcon: ListIcon,
                                            },
                                            isDisabled: () => document.querySelector('[data-testid="client-onboarding-walkthrough"]') !== null,
                                            action: () => navbarEventBus.emit('expand-client-onboarding-walkthrough'),
                                        },
                                    ]
                                  : []),
                          ],
            },
        ]),
        onClientOnboardingIsPresent,
    };
}
