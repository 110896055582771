/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { NavbarStateResource } from './';
import {
    NavbarStateResourceFromJSON,
    NavbarStateResourceFromJSONTyped,
    NavbarStateResourceToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContextResource
 */
export interface ContextResource {
    /**
     * 
     * @type {NavbarStateResource}
     * @memberof ContextResource
     */
    state?: NavbarStateResource;
    /**
     * 
     * @type {boolean}
     * @memberof ContextResource
     */
    isEnforcedIdentity: boolean;
}

/**
 * Check if a given object implements the ContextResource interface.
 */
export function instanceOfContextResource(value: object): value is ContextResource {
    if (!('isEnforcedIdentity' in value) || value['isEnforcedIdentity'] === undefined) return false;
    return true;
}

export function ContextResourceFromJSON(json: any): ContextResource {
    return ContextResourceFromJSONTyped(json, false);
}

export function ContextResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContextResource {
    if (json == null) {
        return json;
    }
    return {
        
        'state': json['state'] == null ? undefined : NavbarStateResourceFromJSON(json['state']),
        'isEnforcedIdentity': json['isEnforcedIdentity'],
    };
}

export function ContextResourceToJSON(value?: ContextResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'state': NavbarStateResourceToJSON(value['state']),
        'isEnforcedIdentity': value['isEnforcedIdentity'],
    };
}

