<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.8749 12C10.4607 12 10.1249 12.3357 10.1249 12.75C10.1249 13.1642 10.4607 13.5 10.8749 13.5H11.9999V14.625C11.9999 15.0392 12.3357 15.375 12.7499 15.375C13.1641 15.375 13.4999 15.0392 13.4999 14.625V13.5H14.6249C15.0391 13.5 15.3749 13.1642 15.3749 12.75C15.3749 12.3357 15.0391 12 14.6249 12H13.4999V10.875C13.4999 10.4607 13.1641 10.125 12.7499 10.125C12.3357 10.125 11.9999 10.4607 11.9999 10.875V12H10.8749Z"
                    fill="#333330"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.2324 4.35C3.2324 5.55558 3.7691 6.63581 4.61661 7.36479C4.08846 7.59803 3.59054 7.90304 3.13788 8.27446C1.99084 9.21562 1.20521 10.525 0.914569 11.98L0.914414 11.9808L0.389712 14.5818C0.307803 14.9878 0.570558 15.3834 0.976593 15.4653C1.38263 15.5472 1.77818 15.2844 1.86009 14.8784L2.38509 12.2759L2.38539 12.2744C2.60803 11.1592 3.21016 10.1555 4.08935 9.43407C4.92194 8.75092 5.9543 8.36089 7.02725 8.32099C7.08696 8.32365 7.14702 8.325 7.2074 8.325C7.2705 8.325 7.33326 8.32353 7.39564 8.32062C8.14523 8.34731 8.87549 8.54838 9.52953 8.90438C8.42626 9.83029 7.72488 11.2195 7.72488 12.7725C7.72488 15.5602 9.98473 17.82 12.7724 17.82C15.56 17.82 17.8199 15.5602 17.8199 12.7725C17.8199 9.98484 15.56 7.725 12.7724 7.725C12.1406 7.725 11.5359 7.84108 10.9785 8.05307C10.609 7.78031 10.2129 7.55014 9.79777 7.36515C10.6455 6.63617 11.1824 5.55578 11.1824 4.35C11.1824 2.15467 9.40273 0.375 7.2074 0.375C5.01207 0.375 3.2324 2.15467 3.2324 4.35ZM7.2074 1.875C5.8405 1.875 4.7324 2.9831 4.7324 4.35C4.7324 5.66218 5.75354 6.73586 7.04452 6.81973C7.09831 6.81834 7.15217 6.81763 7.2061 6.81759C7.26263 6.81743 7.31911 6.81803 7.3755 6.81938C8.66401 6.73296 9.6824 5.66041 9.6824 4.35C9.6824 2.9831 8.5743 1.875 7.2074 1.875ZM9.22488 12.7725C9.22488 10.8133 10.8132 9.225 12.7724 9.225C14.7316 9.225 16.3199 10.8133 16.3199 12.7725C16.3199 14.7317 14.7316 16.32 12.7724 16.32C10.8132 16.32 9.22488 14.7317 9.22488 12.7725Z"
                    fill="#333330"
                />
            </svg>
        </template>
        <template #active>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.61661 7.36479C3.7691 6.63581 3.2324 5.55558 3.2324 4.35C3.2324 2.15467 5.01207 0.375 7.2074 0.375C9.40273 0.375 11.1824 2.15467 11.1824 4.35C11.1824 5.55578 10.6455 6.63617 9.79777 7.36515C10.2129 7.55014 10.609 7.78031 10.9785 8.05307C11.5359 7.84108 12.1406 7.725 12.7724 7.725C15.56 7.725 17.8199 9.98484 17.8199 12.7725C17.8199 15.5602 15.56 17.82 12.7724 17.82C9.98473 17.82 7.72488 15.5602 7.72488 12.7725C7.72488 11.2195 8.42626 9.83029 9.52953 8.90438C8.87549 8.54838 8.14523 8.34731 7.39564 8.32062C7.33326 8.32353 7.2705 8.325 7.2074 8.325C7.14702 8.325 7.08696 8.32365 7.02725 8.32099C5.9543 8.36089 4.92194 8.75092 4.08935 9.43407C3.21016 10.1555 2.60803 11.1592 2.38539 12.2744L2.38509 12.2759L1.86009 14.8784C1.77818 15.2844 1.38263 15.5472 0.976593 15.4653C0.570558 15.3834 0.307803 14.9878 0.389712 14.5818L0.914569 11.98C1.20521 10.525 1.99084 9.21562 3.13788 8.27446C3.59054 7.90304 4.08846 7.59803 4.61661 7.36479ZM4.7324 4.35C4.7324 2.9831 5.84049 1.875 7.2074 1.875C8.5743 1.875 9.6824 2.9831 9.6824 4.35C9.6824 5.66041 8.66401 6.73296 7.3755 6.81938C7.31911 6.81803 7.26263 6.81743 7.2061 6.81759C7.15217 6.81763 7.09831 6.81834 7.04452 6.81973C5.75354 6.73586 4.7324 5.66218 4.7324 4.35ZM11.1001 12.8C11.1001 12.5238 10.8762 12.3 10.6001 12.3C10.324 12.3 10.1001 12.5238 10.1001 12.8C10.1001 14.2761 11.324 15.5 12.8001 15.5C14.2762 15.5 15.5001 14.2761 15.5001 12.8C15.5001 12.5238 15.2762 12.3 15.0001 12.3C14.724 12.3 14.5001 12.5238 14.5001 12.8C14.5001 13.7238 13.724 14.5 12.8001 14.5C11.8762 14.5 11.1001 13.7238 11.1001 12.8Z"
                    fill="#333330"
                />
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
