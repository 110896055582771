export function getPageLocale() {
    return document.documentElement.lang;
}

export function extractPageLanguageCode(locale) {
    if (!locale) {
        return undefined;
    }

    const [lang] = locale.split('-');
    return lang || undefined;
}

export function getPageLanguageCode() {
    return extractPageLanguageCode(getPageLocale());
}

export function extractPageCountryCode(locale) {
    if (!locale) {
        return undefined;
    }

    const [, country] = locale.split('-');
    return (country && country.toUpperCase()) || undefined;
}

export function getPageCountryCode() {
    return extractPageCountryCode(getPageLocale());
}
