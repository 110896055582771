<template>
    <div class="header-sales__wrapper">
        <div ref="headerContactSales" class="header-sales">
            <SalesContactIcon :active="isHovering" :size="24" class="header-sales__icon" />
            <VJoyUserCard
                v-if="isHovering"
                class="header-sales__details"
                :full-name="`${salesManager.firstName} ${salesManager.lastName}`"
                :sub-title="($t('navbar.header.tabs.sales.manager') as string)"
                :photo-url="salesManager.photo"
            >
                <div class="header-sales__contact">
                    <VJoyLink :href="`mailto:${salesManager.email}`" :text="salesManager.email" />
                    <VJoyLink v-if="salesManager.phoneNumber" :href="`tel:${salesManager.phoneNumber}`" :text="salesManager.phoneNumber" />
                </div>
            </VJoyUserCard>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {SalesContactIcon} from '@navbar-logged/common/icons';
    import {useMouseHover} from '#malt/nuxt-utils-module';
    import debounce from 'lodash-es/debounce';
    import {storeToRefs} from 'pinia';
    import {ref, useDataTracking, watch, computed} from '#imports';
    import {VJoyUserCard, VJoyLink} from '@maltjoy/core-vue';
    import type {SalesManagerContactResource} from '@navbar-api';
    import {useUser} from '@navbar-logged/features/user';

    defineProps<{salesManager: SalesManagerContactResource}>();

    const {track} = useDataTracking();
    const userStore = useUser();

    const {selectedIdentity} = storeToRefs(userStore);
    const headerContactSales = ref();

    const {isHovering} = useMouseHover(headerContactSales.value);

    const serviceLevel = computed(() => selectedIdentity.value?.serviceLevel);

    const convertServiceLevelType = computed(() => {
        if (serviceLevel.value === 'CORPORATE') return 'ENTERPRISE';
        if (serviceLevel.value === 'ADVANCED') return 'ADVANCED';
    });

    const pageTitle = computed(() => {
        const url = window.location.pathname;

        if (url.includes('client-settings')) return 'company_settings';
        if (url.includes('messages')) return 'inbox';
        if (url.includes('project-client/find-freelancers')) return 'sourcing_hub';
        if (url.includes('client/freelancers')) return 'pool_of_freelancers';
        if (url.includes('client/sourcing-projects')) return 'sourcing_projects';
        if (url.includes('project-client')) return 'manage_projects';
        if (url.includes('account')) return 'my_settings';
        if (url.includes('client/briefs')) return 'brief_creation';
        if (url.includes('project-proposal-summaries')) return 'process_applications';
        if (url.includes('/profile/')) return 'profile';
        if (url.includes('/invite-freelancer')) return 'invite_freelancer';
        if (url === '/s') return 'search';

        return undefined;
    });

    function tracking() {
        track('temporary_navbar_sales_contact_icon_hovered', {
            page_title: pageTitle.value,
            page_url: window.location.pathname,
            current_service_level: convertServiceLevelType.value,
        });
    }

    const handleDebounce = debounce(() => tracking(), 1000);

    watch(isHovering, () => {
        if (isHovering.value) {
            handleDebounce();
        }
    });
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .header-sales {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;

        &:hover {
            background-color: var(--joy-color-neutral-20);
        }

        &__wrapper {
            cursor: pointer;
            flex-flow: column nowrap;
            margin-top: var(--header-menu-item-margin-top);
            position: relative;
            padding: 0 var(--joy-core-spacing-2);
            align-items: center;
            justify-content: center;
            text-decoration: none;
            cursor: pointer;
            z-index: 2;
        }

        &__icon {
            cursor: pointer;
        }

        &__details {
            background-color: white;
            padding: var(--joy-core-spacing-6);
            border-radius: var(--joy-core-radius-4);
            box-shadow: var(--joy-core-elevation-2);
            position: absolute;
            min-width: 285px;
            top: 42px;
            right: 0px;

            p {
                width: max-content;
            }

            @include mq.screen_xs() {
                position: fixed;
                width: calc(100% - var(--joy-core-spacing-8));
                top: 60px;
                margin: 0 var(--joy-core-spacing-4);
            }

            &::before {
                content: '';
                position: absolute;
                top: -15px;
                left: 0;
                width: 100%;
                height: 15px;
            }
        }

        &__contact {
            @include mq.screen_xs() {
                word-break: break-all;
            }

            .joy-link {
                font-weight: 500;
                &:hover {
                    text-decoration: none;
                }
            }

            display: flex;
            flex-direction: column;
        }
    }
</style>
