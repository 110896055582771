<template>
    <ClientOnly>
        <Teleport to="body">
            <VJoyDialog id="language-selector-modal" open @dialog:hide="handleCloseDialog">
                <template #dialog-header>{{ $t('footer.country.choice.label') }}</template>
                <template #dialog-subheader>{{ $t('footer.country.choice.description') }}</template>
                <template #dialog-body>
                    <div class="language-selector-content / flex">
                        <div class="choices-list / grid">
                            <CountryChoice v-for="country of filteredAvailableLanguages" :key="country.countryCode" :country="country" />
                        </div>
                        <template v-if="selectedCountry">
                            <h3>{{ $t('footer.langage.choice') }}</h3>
                            <div class="choices-list / grid">
                                <LanguageChoice v-for="language of selectedCountry.languages" :key="language.locale" :language="language" />
                            </div>
                        </template>
                    </div>
                </template>
                <template #dialog-confirm>
                    <VJoyButton
                        :loading="loadingChangeLanguage"
                        variant="main"
                        :disabled="!temporarySelectedLanguage"
                        data-testid="language-selector-confirm"
                        @click="languageSelectorStore.handleChangeLanguage"
                    >
                        {{ $t('general.save') }}
                    </VJoyButton>
                </template>
            </VJoyDialog>
        </Teleport>
        <template #fallback>
            <span></span>
        </template>
    </ClientOnly>
</template>

<script setup lang="ts">
    import {useLanguageSelector} from '../language-selector.store';
    import {storeToRefs} from 'pinia';
    import CountryChoice from './CountryChoice.vue';
    import LanguageChoice from './LanguageChoice.vue';
    import {computed} from 'vue';
    import {VJoyDialog, VJoyButton} from '@maltjoy/core-vue';

    const languageSelectorStore = useLanguageSelector();
    const {
        showLanguageSelectorModal,
        availableLanguages,
        selectedCountry,
        temporarySelectedLanguage,
        temporarySelectedCountry,
        loadingChangeLanguage,
    } = storeToRefs(languageSelectorStore);

    const filteredAvailableLanguages = computed(() => availableLanguages.value.filter((lang) => !!lang.countryName));

    function handleCloseDialog() {
        showLanguageSelectorModal.value = false;
        temporarySelectedCountry.value = undefined;
        temporarySelectedLanguage.value = undefined;
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .language-selector-content {
        flex-flow: column nowrap;

        .choices-list {
            grid-template-columns: repeat(2, 1fr);
            gap: var(--joy-core-spacing-6);
            justify-items: flex-start;
            padding-bottom: var(--joy-core-spacing-5);

            @include mq.screen_xs {
                grid-template-columns: 1fr;
            }
        }
    }
</style>
