<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17 9.40039C17 9.20039 17 9.00039 16.9 8.80039C16.8 8.60039 16.6 8.50039 16.4 8.50039H7.9C7.5 8.50039 7.3 8.30039 7.2 8.00039L7 7.00039C6.9 6.70039 6.6 6.40039 6.3 6.40039H1.7C1.5 6.40039 1.3 6.50039 1.2 6.70039C1 6.80039 1 7.10039 1 7.30039L2.8 16.5004C2.9 16.8004 3.2 17.1004 3.5 17.1004H14.8C15.1 17.1004 15.4 16.9004 15.5 16.5004C15.9 15.1004 16.6 11.1004 17 9.40039Z"
                    stroke="#263035"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M3.69922 4.29961V1.79961C3.69922 1.39961 3.99922 1.09961 4.39922 1.09961H13.5992C13.9992 1.09961 14.2992 1.39961 14.2992 1.79961V6.39961"
                    stroke="#263035"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path d="M5.80078 3.2002H11.7008" stroke="#263035" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 5.40039H11.7" stroke="#263035" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </template>
        <template #active>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17 9.40039C17 9.20039 17 9.00039 16.9 8.80039C16.8 8.60039 16.6 8.50039 16.4 8.50039H7.9C7.5 8.50039 7.3 8.30039 7.2 8.00039L7 7.00039C6.9 6.70039 6.6 6.40039 6.3 6.40039H1.7C1.5 6.40039 1.3 6.50039 1.2 6.70039C1 6.80039 1 7.10039 1 7.30039L2.8 16.5004C2.9 16.8004 3.2 17.1004 3.5 17.1004H14.8C15.1 17.1004 15.4 16.9004 15.5 16.5004C15.9 15.1004 16.6 11.1004 17 9.40039Z"
                    fill="#263035"
                    stroke="#263035"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M3.69922 4.29961V1.79961C3.69922 1.39961 3.99922 1.09961 4.39922 1.09961H13.5992C13.9992 1.09961 14.2992 1.39961 14.2992 1.79961V6.39961"
                    stroke="#263035"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path d="M5.80078 3.2002H11.7008" stroke="#263035" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 5.40039H11.7" stroke="#263035" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                    d="M11.2008 12C11.2008 13.2 10.2008 14.2 9.00078 14.2C7.80078 14.2 6.80078 13.2 6.80078 12"
                    stroke="white"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
