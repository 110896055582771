<template>
    <div class="aside-root" :class="{isMobile: isDisplayMobile, isMounted}">
        <aside
            ref="asideRef"
            class="aside-main flex"
            :class="{isFull: isDisplayModeFull, isReduced: isDisplayModeReduced, isMobile: isDisplayMobile, isMounted}"
        >
            <div class="aside-main_sticky">
                <WhiteLabel
                    v-if="selectedIdentity?.whitelabellingEnabled"
                    :company-name="selectedIdentity?.organizationName"
                    :company-logo="selectedIdentity?.organizationLogoUrl"
                    :is-reduced="isDisplayModeReduced"
                />
                <LogoWrapper v-else />
            </div>
            <div class="aside-main_actions flex">
                <NavigationTabsContainer />
                <div ref="bottomActionsRef" class="bottom-actions">
                    <div class="aside-main_actions_company-wrapper flex">
                        <Skeleton v-if="pending && !userStore.selectedIdentity" />
                        <template v-else>
                            <template v-if="isAdmin">
                                <NuxtLink external to="/admin" target="_blank" class="aside-main_actions_company-wrapper_link">
                                    <VJoyIcon
                                        v-if="isDisplayModeReduced"
                                        size="xsmall"
                                        name="home"
                                        color="white"
                                        :title="t('navbar.aside.tabs.title.admin')"
                                        class="aside-main_actions_company-wrapper_icon"
                                    />
                                    <VJoyButton
                                        v-else
                                        variant="admin"
                                        size="small"
                                        class="aside-main_actions_company-wrapper_cta"
                                        data-testid="Admin"
                                    >
                                        {{ $t('navbar.aside.tabs.title.admin') }}
                                    </VJoyButton>
                                </NuxtLink>
                            </template>
                            <CompanySelectorSideDisplay @company-selector-state-changed="handleCompanySelectorToggle" />
                        </template>
                    </div>
                    <LanguageIndicator />
                    <LogoWrapper v-if="selectedIdentity?.whitelabellingEnabled" is-small />
                </div>
            </div>
        </aside>
        <Transition name="fade-opacity">
            <div v-if="isDisplayMobile && isMobileNavBarOpened" class="aside-overlay" @click="handleCloseMobileNavBar"></div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
    import {LogoWrapper, Skeleton, WhiteLabel} from '@navbar-logged/common/ui';
    import {useUser} from '@navbar-logged/features/user';
    import {useNavbarState} from '@navbar-registry';
    import {storeToRefs} from 'pinia';
    import {ref, watch, computed} from 'vue';
    import {useAsideMenu} from '../aside-menu.store';
    import {useDisplayMode} from '../stores';
    import CompanySelectorSideDisplay from './CompanySelectorSideDisplay.vue';
    import {LanguageIndicator} from '@navbar-logged/features/language-selector';
    import NavigationTabsContainer from './NavigationTabsContainer.vue';
    import {useDebounceMount, useDisplayMobile} from '#malt/nuxt-utils-module';
    import {VJoyButton, VJoyIcon} from '@maltjoy/core-vue';
    import {useTranslation} from '#imports';

    const asideMenuStore = useAsideMenu();
    const navbarState = useNavbarState();
    const userStore = useUser();
    const displayModeStore = useDisplayMode();

    const {t} = useTranslation();

    const {selectedIdentity} = storeToRefs(userStore);
    const {isMobileNavBarOpened} = storeToRefs(asideMenuStore);
    const {isDisplayModeFull, isDisplayModeReduced} = storeToRefs(displayModeStore);
    const {pending} = storeToRefs(navbarState);
    const isAdmin = computed<boolean>(() => !!userStore.user?.options.platformAdmin);
    const {isDisplayMobile} = useDisplayMobile();

    const asideRef = ref<HTMLDivElement>();
    const bottomActionsRef = ref<HTMLDivElement>();

    const {isMounted} = useDebounceMount(0, {mode: 'client'});

    function handleCloseMobileNavBar() {
        asideMenuStore.setMobileNavBarOpened(false);
    }

    function redirectToAdmin() {
        window.open('/admin');
    }

    function handleCompanySelectorToggle(isOpen: boolean) {
        if (!isDisplayModeReduced.value && asideRef.value && bottomActionsRef.value) {
            if (isOpen) {
                bottomActionsRef.value.classList.add('isSticky');
                asideRef.value.style.overflow = 'visible';
            } else {
                setTimeout(() => {
                    if (asideRef.value && bottomActionsRef.value) {
                        asideRef.value.style.removeProperty('overflow');
                        bottomActionsRef.value.classList.remove('isSticky');
                    }
                }, 200);
            }
        }
    }

    watch(isDisplayMobile, (value) => {
        if (value) {
            setTimeout(() => {
                if (asideRef.value) {
                    asideRef.value.style.transitionProperty = 'transform';
                }
            }, 300);
        } else {
            setTimeout(() => {
                if (asideRef.value) {
                    asideRef.value.style.transitionProperty = 'width, transform';
                }
            }, 300);
        }
    });
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .aside-root {
        z-index: 998;
        $r: &;
        user-select: none;

        &::before {
            content: '';
            position: fixed;
            left: var(--aside-menu-left);
            width: var(--aside-menu-width);
            z-index: 25;
            height: calc(100vh - var(--header-menu-height));
            box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
            top: calc(var(--header-menu-height));
        }

        &.isMounted::before {
            transition-duration: 0.3s;
            transition-timing-function: ease;
            transition-property: width, transform;
        }

        &.isMobile:before {
            display: none;
        }

        .aside-main {
            width: var(--aside-menu-width);
            height: 100vh;
            max-width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            flex-flow: column nowrap;
            background-color: white;
            overflow-y: auto;
            z-index: 25;
            transform: translateX(var(--aside-menu-left));
            $r: &;

            &_sticky {
                align-self: flex-start;
                position: sticky;
                top: 0px;
                background-color: white;
                z-index: 11;
                width: 100%;
            }

            &_actions {
                flex-flow: column wrap;
                justify-content: space-between;
                flex: 1 1 auto;
                padding-bottom: var(--joy-core-spacing-5);

                &_company-wrapper {
                    padding: var(--joy-core-spacing-6) var(--joy-core-spacing-2) var(--joy-core-spacing-4) var(--joy-core-spacing-2);
                }

                .bottom-actions {
                    display: flex;
                    flex-flow: column wrap;

                    &.isSticky {
                        position: sticky;
                        bottom: 0;
                        background: white;
                    }
                }
            }

            &.isMounted {
                transition-duration: 0.3s;
                transition-timing-function: ease;
                transition-property: width, transform;
            }

            &.isReduced {
                overflow-y: visible;
                .aside-main_sticky {
                    position: relative;
                }

                .aside-main_actions_company-wrapper {
                    padding: var(--joy-core-spacing-6) 0 var(--joy-core-spacing-4) 0;
                    align-items: center;

                    @media screen and (max-height: 700px) {
                        padding: var(--joy-core-spacing-2);
                        gap: var(--joy-core-spacing-1);
                    }
                }
            }
            .aside-main_actions_company-wrapper {
                flex-direction: column;
                gap: var(--joy-core-spacing-4);

                &_link {
                    text-decoration: none;
                }

                &_icon {
                    cursor: pointer;
                    border-radius: 50%;
                    background-color: var(--joy-color-quaternary-70);
                    font-size: var(--joy-font-size-primary-600);
                    margin: auto;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &_cta {
                    width: 100%;
                }
            }
        }

        .aside-overlay {
            position: fixed;
            inset: 0;
            height: 100vh;
            width: 100vw;
            background-color: var(--joy-color-overlay);
            z-index: 24;
        }
    }
</style>
