/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvailabilityUpdateRequestResource
 */
export interface AvailabilityUpdateRequestResource {
    /**
     * 
     * @type {string}
     * @memberof AvailabilityUpdateRequestResource
     */
    value: AvailabilityUpdateRequestResourceValueEnum;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityUpdateRequestResource
     */
    frequency: AvailabilityUpdateRequestResourceFrequencyEnum;
    /**
     * 
     * @type {Date}
     * @memberof AvailabilityUpdateRequestResource
     */
    nextAvailabilityDate?: Date;
}


/**
 * @export
 */
export const AvailabilityUpdateRequestResourceValueEnum = {
    Available: 'AVAILABLE',
    NotAvailable: 'NOT_AVAILABLE'
} as const;
export type AvailabilityUpdateRequestResourceValueEnum = typeof AvailabilityUpdateRequestResourceValueEnum[keyof typeof AvailabilityUpdateRequestResourceValueEnum];

/**
 * @export
 */
export const AvailabilityUpdateRequestResourceFrequencyEnum = {
    OneDayPerWeek: 'ONE_DAY_PER_WEEK',
    TwoDaysPerWeek: 'TWO_DAYS_PER_WEEK',
    ThreeDaysPerWeek: 'THREE_DAYS_PER_WEEK',
    FourDaysPerWeek: 'FOUR_DAYS_PER_WEEK',
    EveningsAndWeekends: 'EVENINGS_AND_WEEKENDS',
    FullTime: 'FULL_TIME'
} as const;
export type AvailabilityUpdateRequestResourceFrequencyEnum = typeof AvailabilityUpdateRequestResourceFrequencyEnum[keyof typeof AvailabilityUpdateRequestResourceFrequencyEnum];


/**
 * Check if a given object implements the AvailabilityUpdateRequestResource interface.
 */
export function instanceOfAvailabilityUpdateRequestResource(value: object): value is AvailabilityUpdateRequestResource {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('frequency' in value) || value['frequency'] === undefined) return false;
    return true;
}

export function AvailabilityUpdateRequestResourceFromJSON(json: any): AvailabilityUpdateRequestResource {
    return AvailabilityUpdateRequestResourceFromJSONTyped(json, false);
}

export function AvailabilityUpdateRequestResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailabilityUpdateRequestResource {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'frequency': json['frequency'],
        'nextAvailabilityDate': json['nextAvailabilityDate'] == null ? undefined : (new Date(json['nextAvailabilityDate'])),
    };
}

export function AvailabilityUpdateRequestResourceToJSON(value?: AvailabilityUpdateRequestResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'frequency': value['frequency'],
        'nextAvailabilityDate': value['nextAvailabilityDate'] == null ? undefined : ((value['nextAvailabilityDate']).toISOString().substring(0,10)),
    };
}

