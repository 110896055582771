import {defu} from 'defu';
import {getCurrentInstance, onMounted, readonly, ref} from 'vue';

interface UseDebounceMountOptions {
    /** @default 'client' */
    mode?: 'client' | 'server' | 'all';
}

/**
 * Returns a readonly boolean indicating if the component is mounted
 * It can be debounced with the `count` parameter
 *
 * @param count - amount of milliseconds to wait after mount
 * @param options - composable options {@link UseDebounceMountOptions}
 * @param callback - callback function called when delay finishes
 * @usage
 *
 * ```ts
 *  const {isMounted} = useDebounceMount();
 * // or
 *  const {isMounted} = useDebounceMount(1000); // isMounted is true 1s after mount
 * ```
 */
export function useDebounceMount(count: number = 0, options?: UseDebounceMountOptions, callback?: () => void) {
    const isMounted = ref(false);

    const resolvedOptions = defu(options, {
        mode: 'client',
    });

    if (
        !((resolvedOptions?.mode === 'client' && !import.meta.client) || (resolvedOptions?.mode === 'server' && !import.meta.server)) &&
        getCurrentInstance()
    ) {
        onMounted(() => {
            setTimeout(() => {
                isMounted.value = true;
                callback?.();
            }, count);
        });
    }

    return {
        isMounted: readonly(isMounted),
    };
}
