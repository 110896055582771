export interface SharedBreakpointsOptions {
    onResize?: (sizes: {width: number; height: number}) => void;
}

export enum BreakPointsTypes {
    /** Very small phone screen (eg iphone4-5) */
    Tiny = 'Tiny',
    /** Small phone screen (eg Nexus 5) */
    Xxs = 'Xxs',
    /** Phone screen */
    Xs = 'Xs',
    /** Tablet */
    Sm = 'Sm',
    /** Small desktop screen */
    Md = 'Md',
    /** Common desktop screen */
    Lg = 'Lg',
    /** Large desktop screen */
    Xl = 'Xl',
}

export enum BreakPointsValues {
    /** Very small phone screen (eg iphone4-5) */
    Tiny = 359,
    /** Small phone screen (eg Nexus 5) */
    Xxs = 479,
    /** Phone screen */
    Xs = 767,
    /** Tablet */
    Sm = 991,
    /** Small desktop screen */
    Md = 1199,
    /** Common desktop screen */
    Lg = 1599,
    /** Large desktop screen */
    Xl = Infinity,
}
