<template>
    <div class="language-choice / flex" :class="{isSelected}" @click="handleCountrySelect">
        <div class="language-choice_icon / flex">
            <IconRenderer :icon="getFlagIcon" :size="30" />
        </div>
        <span class="language-choice_label">{{ props.country.countryName }}</span>
    </div>
</template>

<script setup lang="ts">
    import type {LanguageConfig} from '../language-selector.types';
    import {useLanguageSelector} from '../language-selector.store';
    import {storeToRefs} from 'pinia';
    import type {PropType} from 'vue';
    import {computed} from 'vue';
    import {IconRenderer} from '@navbar-logged/common/ui';

    const languageSelectorStore = useLanguageSelector();
    const {countryFlagsIcons, selectedCountry, temporarySelectedCountry, temporarySelectedLanguage} = storeToRefs(languageSelectorStore);

    const props = defineProps({
        country: {type: Object as PropType<LanguageConfig>, required: true},
    });

    const isSelected = computed(() => selectedCountry.value?.countryCode === props.country.countryCode);

    const getFlagIcon = computed(() => countryFlagsIcons.value[props.country.countryCode] ?? 'languages/ic_flag_eu');

    function handleCountrySelect() {
        temporarySelectedCountry.value = props.country;
        temporarySelectedLanguage.value = undefined;
    }
</script>

<style lang="scss" scoped>
    @import './language-common.scss';
</style>
