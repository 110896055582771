<template>
    <div ref="badgeRef" data-testid="navbar-notification-badge" class="notification-badge">
        <span data-testid="navbar-notification-count">{{ formattedCount }}</span>
    </div>
</template>

<script setup lang="ts">
    import {onMounted, ref, watch, computed} from 'vue';

    const props = defineProps({
        count: {type: Number, default: 0},
        offset: {type: Number, default: 0},
    });

    const formattedCount = computed(() => {
        if (props.count > 10) {
            return '10+';
        }
        return props.count;
    });

    const badgeRef = ref<HTMLDivElement>();

    function setOffset() {
        badgeRef.value?.style.setProperty('--offset', `${props.offset}px`);
    }
    watch(() => props.offset, setOffset);

    onMounted(setOffset);
</script>

<style lang="scss" scoped>
    .notification-badge {
        --offset: 0px;

        position: absolute;
        left: 100%;
        top: 0px;
        transform: translate(var(--offset, 0px), -40%);
        min-width: 14px;
        height: 14px;
        font-size: var(--joy-font-size-primary-100);
        background-color: var(--joy-color-primary-30);
        text-align: center;
        color: white;
        border-radius: 60px;
        line-height: 1;
        padding: 0 4px;
        z-index: 10;

        span {
            vertical-align: middle;
            margin-top: -2px;
            display: inline-block;
            letter-spacing: -0.2px;
        }
    }
</style>
