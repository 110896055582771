<template>
    <div ref="datePickerRef" class="joy-calendar">
        <VJoyInput
            :type="isTouchDevice() ? 'date' : 'text'"
            :name="name || 'calendar'"
            :placeholder="placeholder"
            :label="label"
            :optional-label="optionalLabel"
            :invalid="error"
            :data-testid="dataTestid"
            :disabled="disabled"
            icon="calendar"
            @click="handleInputClick"
            @keydown.prevent.enter="handleInputClick"
            :required="required"
            :model-value="inputValue"
            @update:model-value="handleInputValueChange"
        >
            <slot />
        </VJoyInput>
        <Calendar
            v-if="calendarOpened"
            :disabled="disabled"
            inline
            class="joy-calendar__calendar"
            :class="{'joy-calendar__calendar--top': calendarOnTop}"
            :minDate="minDate"
            :maxDate="maxDate"
            :modelValue="calendarValue"
            :showTime="showTime"
            hourFormat="24"
            :dateFormat="datePattern"
            :view="view"
            @update:modelValue="handleCalendarValueChange"
        />
    </div>
</template>

<script setup lang="ts">
    import {onClickOutside} from '@vueuse/core';
    import {toZonedTime, fromZonedTime} from 'date-fns-tz';    
    import {format} from 'date-fns';
    import Calendar from 'primevue/datepicker';
    import {computed, ref, watch} from 'vue';
    import {WrongFormattedDateError, stringToDate} from './dateConverter';
    import {isTouchDevice} from './helpers';
    import {setupCalendarTranslations} from './setupCalendarTranslations';
    import {VJoyInput} from '@maltjoy/core-vue';

    const props = defineProps({
        modelValue: Date,
        error: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        label: String,
        optionalLabel: String,
        dataTestid: String,
        datePattern: {type: String, required: true},
        datePatternRegex: {type: String, required: true},
        placeholder: {type: String, default: ''},
        minDate: Date,
        maxDate: Date,
        required: {type: Boolean, default: false},
        name: String,
        showTime: {type: Boolean, default: false},
        view: {type: String, default: 'date'},
    });

    const emit = defineEmits(['update:modelValue', 'toggled']);

    setupCalendarTranslations();

    const inputValue = computed(() => {
        if (!props.modelValue) {
            return '';
        }

        // We're using a default input date for touch devices, it requires ISO8601 formatted date as value
        return isTouchDevice()
            ? format(toZonedTime(props.modelValue, 'UTC'), 'yyyy-MM-dd')
            : format(toZonedTime(props.modelValue, 'UTC'), props.datePattern);
    });

    const calendarValue = computed(() => (props.modelValue && !isNaN(props.modelValue.valueOf()) ? toZonedTime(props.modelValue, 'UTC') : ''));

    const calendarOpened = ref(false);
    const calendarOnTop = ref(false);
    const datePickerRef = ref<HTMLElement>();

    watch(calendarOpened, () => {
        emit('toggled', calendarOpened.value);
    });

    onClickOutside(datePickerRef, () => {
        if (calendarOpened.value) {
            calendarOpened.value = false;
        }
    });

    function handleInputClick() {
        if (!isTouchDevice() && datePickerRef.value) {
            calendarOnTop.value = window.innerHeight - datePickerRef.value.getBoundingClientRect().top < 320;
            calendarOpened.value = true;
        }
    }

    function handleInputValueChange(event: string) {
        try {
            const dateValue = stringToDate(event, isTouchDevice(), props.datePattern, props.datePatternRegex);

            emit('update:modelValue', dateValue || undefined);
        } catch (error) {
            // Nothing to do if the date is wrongly formatted: the user is probably typing the date
            if (!(error instanceof WrongFormattedDateError)) {
                throw error;
            }
        }
    }

    function handleCalendarValueChange(value?: string | string[] | Date | Date[] | undefined) {
        calendarOpened.value = false;
        if (value instanceof Date) {
            emit('update:modelValue', fromZonedTime(value, 'UTC'));
        } else {
            emit('update:modelValue', value);
        }
    }
</script>

<style lang="scss">
    @use './calendar';

    .joy-calendar {
        &__calendar {
            margin-top: var(--joy-core-spacing-4);

            &--top {
                margin-top: 0;
                margin-bottom: var(--joy-core-spacing-4);
            }
        }
    }
</style>
