import {useUser} from '@navbar-logged/features/user';
import {computed, effectScope, shallowRef, watch} from 'vue';
import type {NavigationTabsRecord} from './navigation.types';
import {useClientNavigationTabs} from './useClientNavigationTabs';
import {useFreelancerNavigationTabs} from './useFreelancerNavigationTabs';

export function useNavigationTabs(): NavigationTabsRecord {
    const userStore = useUser();

    const isFreelanceIdentity = computed(() => {
        return userStore.hasSelectedFreelanceIdentity || userStore.cachedIdentityRole === 'FREELANCE';
    });

    const tabsRecord = shallowRef<NavigationTabsRecord>({} as NavigationTabsRecord);
    const tabsScope = effectScope();

    function runEffect() {
        if (isFreelanceIdentity.value) {
            tabsRecord.value = tabsScope.run(() => {
                return useFreelancerNavigationTabs();
            })!;
        } else {
            tabsRecord.value = tabsScope.run(() => {
                return useClientNavigationTabs();
            })!;
        }
    }

    watch(isFreelanceIdentity, runEffect);
    runEffect();

    const allNavigationRoutes = computed(() => tabsRecord.value?.allNavigationRoutes.value);
    const filteredNavigationRoutes = computed(() => tabsRecord.value?.filteredNavigationRoutes.value);

    const logoNavigationRoute = computed(() => tabsRecord.value?.logoNavigationRoute.value);

    return {
        logoNavigationRoute,
        allNavigationRoutes,
        filteredNavigationRoutes,
    };
}
