import {useVuelidate} from '@vuelidate/core';
import {helpers, maxLength, minLength, required} from '@vuelidate/validators';
import {ref} from 'vue';
import {useSearchRouteDefinitions} from '@navbar/features/search';
import {defineStore} from 'pinia';
import {guestHost, useRoute} from '#imports';
import type {LocationItem} from './search.types';
import {redirectToSearch} from './utils';

export const useSearchForm = defineStore('navbar-search-form', () => {
    const {currentPage} = useSearchRouteDefinitions();
    const route = useRoute();
    const host = guestHost();

    const jobQuery = ref<string>((route.query.q as string) ?? '');
    const locationQuery = ref<string>((route.query.location as string) ?? '');

    const fullJobSuggestion = ref<string | null>(jobQuery.value);

    let locationSuggestionFromRoute: LocationItem | null = null;
    if (route.query.formattedAddress) {
        locationSuggestionFromRoute = {
            administrativeAreaLevel1: route.query.administrativeAreaLevel1 as string,
            administrativeAreaLevel1Code: route.query.administrativeAreaLevel1Code as string,
            administrativeAreaLevel2: route.query.administrativeAreaLevel2 as string,
            administrativeAreaLevel2Code: route.query.administrativeAreaLevel2Code as string,
            administrativeAreaLevel3: route.query.administrativeAreaLevel3 as string,
            administrativeAreaLevel3Code: route.query.administrativeAreaLevel3Code as string,
            administrativeAreaLevel4: route.query.administrativeAreaLevel4 as string,
            administrativeAreaLevel4Code: route.query.administrativeAreaLevel4Code as string,
            city: route.query.city as string,
            country: route.query.country as string,
            countryCode: route.query.countryCode as string,
            formattedAddress: route.query.location as string,
            loc: {
                lat: parseInt(route.query.lat as string),
                lon: parseInt(route.query.lon as string),
            },
            region: route.query.region as string,
            street: route.query.street as string,
            street2: route.query.street2 as string,
            zipCode: route.query.zipCode as string,
            remoteAllowed: route.query.remoteAllowed === ('true' as string),
        };
    }

    const fullLocationSuggestion = ref<LocationItem | null>(locationSuggestionFromRoute);

    const isJobFocused = ref(false);
    const jobZoomEffect = ref(false);
    const isLocationFocused = ref(false);

    const v$ = useVuelidate(
        {
            jobQuery: {required, minLength: minLength(1), maxLength: maxLength(128), pattern: helpers.regex(/^.*\S.*$/)},
            locationQuery: {minLength: minLength(2), maxLength: maxLength(500)},
        },
        {jobQuery, locationQuery},
    );

    function onSubmit() {
        if (currentPage.value?.name === 'Search') {
            // pickBy will remove all null / undefined entries
            const searchEvent = new CustomEvent('navbar:searchRequested', {
                bubbles: true,
                detail: {
                    query: jobQuery.value,
                    location: fullLocationSuggestion.value,
                    sourceComponent: 'navbar_search',
                },
            });
            document.dispatchEvent(searchEvent);
        } else if (fullJobSuggestion.value) {
            const searchUrl = redirectToSearch({
                baseURLLink: `${host}/s`,
                location: fullLocationSuggestion.value,
                query: fullJobSuggestion.value,
                sourceComponent: 'navbar_search',
            });
            window.location.href = searchUrl;
        }
    }

    return {
        v$,
        jobQuery,
        locationQuery,
        onSubmit,
        isJobFocused,
        jobZoomEffect,
        isLocationFocused,
        fullJobSuggestion,
        fullLocationSuggestion,
    };
});
