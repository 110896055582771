import {defu} from 'defu';
import type {ComponentPublicInstance, Ref} from 'vue';
import {getCurrentInstance, onBeforeUnmount, onMounted, readonly, ref} from 'vue';
import {useVueInternals} from '../useVueInternals';

export interface UseMouseHoverOptions {
    /**
     * The template ref you want to target
     * @default root component element
     * */
    customRef?: Ref<HTMLElement | ComponentPublicInstance | undefined | null>;
    /**
     * Triggers mouse listening on mount
     * @default true
     */
    immediate?: boolean;
    /**
     * onmouseenter callback
     */
    onMouseEnter?: (event: MouseEvent) => void;
    /**
     * onmouseleave callback
     */
    onMouseLeave?: (event: MouseEvent) => void;
}

/**
 * Detects mouse hovering on a element (root of the component by default if not using fragment)
 * Returns a readonly ref indicating if the element is being hovered
 *
 * @param options - composable options {@link UseMouseHoverOptions}
 *
 * @usage
 *
 * ```ts
 *  const {isHovering} = useMouseHover();
 *  // Or
 * const myRef = ref<HtmlDivElement>();
 *
 * const {isHovering, startDetecting, stopDetecting} = useMouseHover({customRef: myRef, immediate: false});
 *
 * function doStuff() {
 *     startDetecting();
 * }
 * ```
 */
export function useMouseHover(options?: UseMouseHoverOptions) {
    const {proxy} = useVueInternals();
    const resolvedOptions = defu(options, {immediate: true});

    const isHovering = ref(false);
    const target = ref<HTMLElement>();

    function handleMouseEnter(event: MouseEvent) {
        isHovering.value = true;
        options?.onMouseEnter?.(event);
    }

    function handleMouseLeave(event: MouseEvent) {
        isHovering.value = false;
        options?.onMouseLeave?.(event);
    }

    /** */
    function startDetecting() {
        if (resolvedOptions.customRef?.value) {
            if (resolvedOptions.customRef.value instanceof HTMLElement) {
                target.value = resolvedOptions.customRef.value;
            } else if (!Array.isArray(resolvedOptions.customRef.value.$el)) {
                target.value = resolvedOptions.customRef.value.$el as HTMLElement;
            }
        } else if (!Array.isArray(proxy?.$el)) {
            target.value = proxy?.$el as HTMLElement;
        }
        if (target.value) {
            target.value.addEventListener('mouseenter', handleMouseEnter);
            target.value.addEventListener('mouseleave', handleMouseLeave);
        } else {
            /* eslint-disable-next-line no-console */
            console.warn(
                "[useMouseHover] Can't find target element. Check if element exists or if there is multiple root nodes",
                new Error("[useMouseHover] Can't find target element. Check if element exists or if there is multiple root nodes"),
            );
        }
    }

    function stopDetecting() {
        if (target.value) {
            target.value.removeEventListener('mouseenter', handleMouseEnter);
            target.value.removeEventListener('mouseleave', handleMouseLeave);
        }
    }

    if (getCurrentInstance()) {
        onMounted(() => {
            if (resolvedOptions.immediate) {
                startDetecting();
            }
        });

        onBeforeUnmount(stopDetecting);
    }

    return {
        isHovering: readonly(isHovering),
        startDetecting,
        stopDetecting,
    };
}
