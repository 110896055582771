import type {AvailabilityResource, AvailabilityUpdateRequestResource} from '@navbar-api';
import {AvailabilityResourceFrequencyEnum, AvailabilityResourceValueEnum, AvailabilityUpdateRequestResourceValueEnum} from '@navbar-api';
import {FreelanceContext} from '@navbar-registry';
import {defineStore} from 'pinia';
import {computed, ref, watch} from 'vue';
import {useRoute, navbarEventBus, useDataTracking} from '#imports';
import {ComponentSelected} from './availability.types';

/** Freelance availability */
export const useAvailability = defineStore('navbar-availability', () => {
    const availability = ref<AvailabilityResource>();
    const updateAvailability = ref<AvailabilityUpdateRequestResource | null>(null);
    const route = useRoute();
    const {track} = useDataTracking();

    const showUpdateAvailability = ref(false);
    const componentSelected = ref(ComponentSelected.DROPDOWN);

    watch(availability, (newAvailability) => {
        updateAvailability.value = {
            value:
                newAvailability?.value === AvailabilityResourceValueEnum.NotAvailable ||
                newAvailability?.value === AvailabilityResourceValueEnum.NotAvailableWithDate ||
                newAvailability?.value === AvailabilityResourceValueEnum.AvailableSoon ||
                route.hash.startsWith('#edit-availability-not-available')
                    ? AvailabilityResourceValueEnum.NotAvailable
                    : AvailabilityResourceValueEnum.Available,
            frequency: newAvailability?.frequency ?? AvailabilityResourceFrequencyEnum.FullTime,
            nextAvailabilityDate: newAvailability?.nextAvailabilityDate,
        };
    });

    const updateAvailabilityIsValid = computed(() => {
        return (
            updateAvailability.value?.value === AvailabilityUpdateRequestResourceValueEnum.Available ||
            (updateAvailability.value?.value === AvailabilityUpdateRequestResourceValueEnum.NotAvailable &&
                updateAvailability.value.nextAvailabilityDate != null)
        );
    });

    async function saveAvailability() {
        if (updateAvailability.value) {
            availability.value = await FreelanceContext.updateAvailability({availabilityUpdateRequestResource: updateAvailability.value});
            navbarEventBus.emit('availability-navbar:update');
        }
    }

    function openAvailibility(source: string) {
        showUpdateAvailability.value = true;
        track('profile_edition_opened', {
            profile_section: 'availability',
            entry_point: entryPoint(source),
            workflow: 'manual',
        });
    }

    function entryPoint(source: string): string {
        switch (source) {
            case 'DASHBOARD_FREELANCER':
                return 'dashboard';
            case 'USER_CLICK_ON_NAVBAR':
                return 'navbar';
            case 'CLICK_ON_PROFILE_ALERT':
                return 'profile_page';
            default:
                return source;
        }
    }

    return {
        availability,
        updateAvailability,
        saveAvailability,
        updateAvailabilityIsValid,
        showUpdateAvailability,
        openAvailibility,
        componentSelected,
    };
});
