<template>
    <header ref="headerRef" class="header-menu flex" :class="{isMobile: isDisplayMobile}">
        <LogoWrapper v-if="isDisplayModeFunnel" />
        <div v-if="!isDisplayModeFunnel" class="header-menu_items flex">
            <div data-testid="navbar-toggle-state" class="header-menu__toggle" @click="handleChangeNavBarDisplayMode">
                <HeaderMenuItem
                    :title="isDisplayModeFull ? t('navbar.header.menu.reduce') : t('navbar.header.menu.expand')"
                    icon="header/burger"
                    :size="26"
                    :show-title="false"
                />
            </div>
            <a v-if="isDisplayMobile" :href="asideMenuStore.logoNavigationRoute">
                <MaltImg
                    key="expanded"
                    class="aside-main_sticky_logo-wrapper_mask_image"
                    src="/navbar/logos/malt-logo-red.svg?vh=9690b2"
                    width="100"
                    height="28"
                    alt="Malt logo"
                />
            </a>
            <HeaderSearchWrapper
                v-if="!isDisplayMobile && (searchFreelancerDisplayHandler || (userStore.hasSelectedClientIdentity && searchDisplayMode !== 'NONE'))"
            />

            <HeaderAvailability v-if="userStore.hasSelectedFreelanceIdentity" />

            <HeaderPunchout v-if="isPunchoutBadgeShownForCompany && isPunchoutEnabled" />

            <div class="header-menu_actions flex">
                <HeaderSalesContactDetails v-if="salesManagerDetails" :sales-manager="salesManagerDetails" />
                <HeaderMenuItem v-for="menu of headerNavigationTabs" :key="menu.id" :tab="menu" />
            </div>
        </div>

        <HeaderSingleLogout v-if="showSingleLogout" />

        <HeaderSearchWrapper
            v-if="
                isDisplayMobile &&
                !isDisplayModeFunnel &&
                (searchFreelancerDisplayHandler || (userStore.hasSelectedClientIdentity && searchDisplayMode !== 'NONE'))
            "
            class="isMobile"
        />
    </header>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {useDisplayMobile, useDetectOutsideClick} from '#malt/nuxt-utils-module';
    import {LogoWrapper} from '@navbar-logged/common/ui';
    import {storeToRefs} from 'pinia';
    import {HeaderAvailability} from '@navbar-logged/features/availability';
    import HeaderPunchout from '@navbar-logged/features/punchout/HeaderPunchout.vue';
    import HeaderSalesContactDetails from './HeaderSalesContactDetails.vue';
    import HeaderMenuItem from './HeaderMenuItem.vue';
    import {HeaderSearchWrapper} from '@navbar-logged/features/search';
    import {useAsideMenu, useDisplayMode} from '@navbar-logged/features/aside-menu';
    import {useHeaderMenu} from '@navbar-logged/features/header-menu';
    import {useUser} from '@navbar-logged/features/user';
    import {computed, onMounted, ref, watch} from 'vue';
    import {useTranslation} from '#imports';
    import {useSearchRouteDefinitions} from '@navbar/features/search';
    import {SearchDisplayMode} from '@navbar/features/search/search.types';
    import {ClientSalesManagerContact, FreelanceCompanyContext} from '../../../registry';
    import HeaderSingleLogout from '@navbar-logged/features/single-logout/HeaderSingleLogout.vue';
    import type {SalesManagerContactResource} from '@navbar-api';

    const {t} = useTranslation();
    const headerRef = ref<HTMLElement>();

    const userStore = useUser();
    const {isDisplayMobile} = useDisplayMobile();
    const asideMenuStore = useAsideMenu();
    const headerMenuStore = useHeaderMenu();
    const displayModeStore = useDisplayMode();

    const {isDisplayModeFunnel, isDisplayModeFull} = storeToRefs(displayModeStore);
    const {searchDisplayMode, headerNavigationTabs, searchPreviewTarget} = storeToRefs(headerMenuStore);
    const isPunchoutBadgeShownForCompany = computed<boolean>(() => userStore.selectedIdentity?.punchoutDetails?.showPunchoutBadge === true);
    const isPunchoutEnabled = computed<boolean>(() => userStore.selectedIdentity?.punchoutDetails?.punchoutEnabled === true);
    const showSingleLogout = ref(false);

    const salesManagerDetails = ref<SalesManagerContactResource>();

    onMounted(async () => {
        if (isDisplayModeFunnel.value) {
            try {
                const freelancerCompany = await FreelanceCompanyContext.getFreelancerCompany();
                showSingleLogout.value = freelancerCompany.showLogoutButton;
            } catch {}
        }
    });

    onMounted(async () => {
        try {
            salesManagerDetails.value = userStore.hasSelectedClientIdentity ? await ClientSalesManagerContact.getSalesManagerContact() : undefined;
        } catch (e) {
            console.warn(e, 'Cannot fetch Sales manager contact details');
        }
    });

    function handleChangeNavBarDisplayMode() {
        if (isDisplayMobile.value) {
            asideMenuStore.toggleMobileNavBarOpened();
        } else {
            displayModeStore.toggleNavbarDisplayMode();
        }
    }

    const {currentPage} = useSearchRouteDefinitions();
    const searchFreelancerDisplayHandler = computed(() => userStore.hasSelectedFreelanceIdentity && currentPage.value?.name === 'Search');

    const {startDetecting, stopDetecting} = useDetectOutsideClick(
        headerRef,
        () => {
            if (!isDisplayMobile.value) {
                // searchDisplayMode.value = SearchDisplayMode.PREVIEW;
                searchPreviewTarget.value = 'NONE';
            }
        },
        {immediate: false},
    );

    function headerMenuOverDetect() {
        if (searchDisplayMode.value === SearchDisplayMode.FORM) {
            startDetecting();
        } else {
            stopDetecting();
        }
    }

    watch(searchDisplayMode, () => headerMenuOverDetect(), {immediate: true});
</script>

<style lang="scss" scoped>
    .header-menu {
        flex-direction: column;
        justify-content: center;
        width: calc(100vw - var(--layout-padding-left));
        height: var(--header-menu-height);
        padding: 0 var(--joy-core-spacing-4);
        background-color: white;
        position: fixed;
        top: 0;
        left: var(--layout-padding-left);
        z-index: 24;
        transition-property: width, left;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        box-shadow: 0px 0 5px rgba(0, 0, 0, 0.1);

        &.isMobile {
            --layout-padding-left: 0px;
            padding: 0 var(--joy-core-spacing-2);
        }

        &_items {
            justify-content: space-between;
            align-items: center;
            flex: 1;
            flex-wrap: nowrap;
            position: relative;
        }

        &_actions {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: var(--joy-core-spacing-2);
        }

        &__toggle + .punchout-header {
            margin-left: auto;
        }
    }
</style>
