/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SalesManagerContactResource
 */
export interface SalesManagerContactResource {
    /**
     * Absolute URL of the photo
     * @type {string}
     * @memberof SalesManagerContactResource
     */
    photo?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesManagerContactResource
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesManagerContactResource
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesManagerContactResource
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesManagerContactResource
     */
    phoneNumber?: string;
}

/**
 * Check if a given object implements the SalesManagerContactResource interface.
 */
export function instanceOfSalesManagerContactResource(value: object): value is SalesManagerContactResource {
    return true;
}

export function SalesManagerContactResourceFromJSON(json: any): SalesManagerContactResource {
    return SalesManagerContactResourceFromJSONTyped(json, false);
}

export function SalesManagerContactResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesManagerContactResource {
    if (json == null) {
        return json;
    }
    return {
        
        'photo': json['photo'] == null ? undefined : json['photo'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'] == null ? undefined : json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
    };
}

export function SalesManagerContactResourceToJSON(value?: SalesManagerContactResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'photo': value['photo'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
    };
}

