<template>
    <div
        class="navigation-tab flex"
        :class="{
            isActive,
            isCollapsable,
            isExpanded,
            isFull: isDisplayModeFull,
            isReduced: isDisplayModeReduced,
            isChildrenShownInADropdown: tab.showChildrenInADropdown,
            isMobile: isDisplayMobile,
            isHovering: isHoveringOnReduced,
            isAnchoredBottom,
        }"
        :style="tab.customStyle"
        :data-testid="tab.id"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
    >
        <Component
            :is="getComponentType"
            class="navigation-tab-item flex"
            :class="{hasBorder: tab.border, isMounted: isMountedDelayed}"
            :data-testid="`${tab.id}-Item`"
            v-bind="getComputedAttributes"
            :aria-label="tab.title"
            @click="handleClick"
        >
            <template v-if="navbarState.pending && tab.constraint != null">
                <Skeleton height="20px" :width="isDisplayModeReduced ? '20px' : '100%'" />
            </template>
            <template v-else>
                <div v-if="tab.icons?.mainIcon" class="navigation-tab-item_icon-wrapper">
                    <IconRenderer :icon="tab.icons.mainIcon" :active="isActive || isHovering" :name="tab.title" />
                    <Transition name="fade-opacity" mode="out-in">
                        <NotificationBadge v-if="isNotificationBadgeVisibleForReduced" :count="getAllNotificationsCount" :offset="0" />
                    </Transition>
                </div>
                <Transition name="fade-opacity" mode="out-in">
                    <div v-if="isDisplayModeFull || isDisplayMobile" class="navigation-tab-item_title-wrapper" :aria-label="tab.title">
                        <div class="navigation-tab-item_title-wrapper_notifications-container">
                            <span>{{ tab.title }}</span>
                            <IconRenderer class="alt-icon" :icon="tab.icons?.altIcon" :active="isActive || isHovering" :name="tab.title" :size="14" />
                            <Transition name="fade-opacity">
                                <NotificationBadge v-if="isNotificationBadgeVisibleForFull" :count="getAllNotificationsCount" :offset="0" />
                            </Transition>
                        </div>
                    </div>
                </Transition>
                <Transition name="fade-opacity" mode="out-in">
                    <div
                        v-if="isCollapsable && (isDisplayModeFull || isDisplayMobile) && !hideCollapseOnFull"
                        :class="{isExpanded}"
                        class="navigation-tab-item_collapse-icon-wrapper flex"
                    >
                        <VJoyIcon name="chevron-down" color="neutral" />
                    </div>
                </Transition>
            </template>
        </Component>
        <Transition :name="getTransitionMode" mode="out-in">
            <div
                v-if="isCollapseMenuDisplaying"
                ref="tabChildrenRef"
                :class="{
                    isExpanded: isCollapsable && isExpanded,
                    isReduced: isDisplayModeReduced,
                    isChildrenShownInADropdown: tab.showChildrenInADropdown,
                    isMobile: isDisplayMobile,
                    isAnchoredBottom,
                    isEmpty: !tab.children?.length,
                    fromSSR: fromSSR,
                }"
                class="navigation-tab-children flex"
            >
                <Component
                    :is="getComponentType"
                    v-bind="getComputedAttributes"
                    v-if="isDisplayModeReduced && tab.showTitleOnHover"
                    :class="{isEmpty: !tab.children, isActive}"
                    class="navigation-tab-children_reduced-title flex"
                >
                    <span>{{ tab.title }}</span>
                </Component>
                <div class="navigation-tab-children_list flex">
                    <NavigationTabItemChild
                        v-for="childTab of getChildren"
                        :key="childTab.id"
                        :tab="childTab"
                        :is-anchored-bottom="isAnchoredBottom"
                        :is-shown-in-a-dropdown="tab.showChildrenInADropdown"
                    />
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
    import {useDebounceMount, useMouseHover, useDisplayMobile} from '#malt/nuxt-utils-module';
    import {IconRenderer, Skeleton} from '@navbar-logged/common/ui';
    import {NotificationBadge} from '@navbar-logged/features/notifications';
    import {useNavbarState} from '@navbar-registry';
    import {storeToRefs} from 'pinia';
    import type {AnchorHTMLAttributes, PropType} from 'vue';
    import {computed, nextTick, onMounted, ref, toRefs, watch} from 'vue';
    import {useAsideMenu} from '../aside-menu.store';
    import type {NavigationTab} from '../aside-menu.types';
    import {useDisplayMode, useExpandedTabs} from '../stores';
    import NavigationTabItemChild from './NavigationTabItemChild.vue';
    import {useHydration, useNuxtApp} from 'nuxt/app';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const props = defineProps({
        /** Main tab object */
        tab: {
            type: Object as PropType<NavigationTab<string>>,
            required: true,
        },
        /** Hide children on Full mode for specific tabs (ex: Language selection) */
        hideCollapseOnFull: {type: Boolean},
        selector: {type: Boolean},
    });

    useHydration(
        'tabItem',
        () => {
            return {
                fromSSR: true,
            };
        },
        () => {},
    );

    const nuxtApp = useNuxtApp();
    const fromSSR = ref<boolean | undefined>(nuxtApp.payload.tabItem?.fromSSR || import.meta.server);

    const {tab} = toRefs(props);

    const navbarState = useNavbarState();
    const {isDisplayMobile} = useDisplayMobile();

    const asideMenuStore = useAsideMenu();
    const {currentActiveRoute} = storeToRefs(asideMenuStore);

    const expandedTabsStore = useExpandedTabs();

    const displayModeStore = useDisplayMode();
    const {isDisplayModeFull, isDisplayModeReduced} = storeToRefs(displayModeStore);

    const tabChildrenRef = ref<HTMLDivElement>();

    const {isHovering} = useMouseHover();

    /** Dynamic component type */
    const getComponentType = computed<'div' | 'a'>(() => (tab.value.url ? 'a' : 'div'));

    // - Notifications
    const getNotificationsCount = computed<number>(() => {
        const baseCount = props.tab.notificationsCount ?? 0;
        if (props.tab.children?.length) {
            return props.tab.children.reduce((acc, value) => (acc += value.notificationsCount ?? 0), 0) + baseCount;
        } else {
            return baseCount;
        }
    });

    const getAllNotificationsCount = computed(() => getNotificationsCount.value);

    const isNotificationBadgeVisibleForReduced = computed(() => isDisplayModeReduced.value && getNotificationsCount.value > 0);
    const isNotificationBadgeVisibleForFull = computed(() => getNotificationsCount.value > 0 && !isExpanded.value);

    // - Hovering reduced mode
    const isHoveringOnReduced = ref(false);
    const isAnchoredBottom = ref(false);
    let timeOutHovering: number | null = null;

    const isCollapseMenuDisplaying = computed(
        () =>
            (isCollapsable.value && (isDisplayModeFull.value || isDisplayMobile.value) && !props.hideCollapseOnFull) ||
            ((isDisplayModeReduced.value || props.tab.showChildrenInADropdown) && isHoveringOnReduced.value),
    );

    function handleMouseEnter() {
        if (isDisplayModeReduced.value || props.tab.showChildrenInADropdown) {
            showChildrenInReduced();
        }
    }
    function handleMouseLeave() {
        if (isDisplayModeReduced.value || props.tab.showChildrenInADropdown) {
            if (timeOutHovering) {
                window.clearTimeout(timeOutHovering);
            }
            isHoveringOnReduced.value = false;
            isAnchoredBottom.value = false;
        }
    }

    function showChildrenInReduced() {
        if (timeOutHovering) {
            window.clearTimeout(timeOutHovering);
        }
        timeOutHovering = window.setTimeout(() => {
            isHoveringOnReduced.value = true;
            nextTick().then(() => {
                if (tabChildrenRef.value) {
                    const {height, top} = tabChildrenRef.value.getBoundingClientRect();
                    const offsetTop = height + top;
                    const threshold = 20;
                    if (offsetTop >= document.documentElement.clientHeight - threshold) {
                        isAnchoredBottom.value = true;
                    } else {
                        isAnchoredBottom.value = false;
                    }
                }
            });
        }, 200);
    }

    // - Collapse and expand tabs

    const {isMounted: isMountedDelayed} = useDebounceMount(100, {mode: 'client'});
    useDebounceMount(500, {mode: 'client'}, () => {
        fromSSR.value = false;
    });

    const isCollapsable = computed<boolean>(() => !!tab.value.children?.length && !props.tab.showChildrenInADropdown);
    const isExpanded = computed<boolean>(
        () => expandedTabsStore.expandedTabs.includes(tab.value.id) && (isDisplayModeFull.value || isDisplayMobile.value),
    );

    let expandTimeOut: number | null = null;

    async function onExpandChange(fromDisplayMode = false) {
        if (isDisplayModeFull.value || isDisplayMobile.value) {
            await nextTick();
            const panel = tabChildrenRef.value;
            const transitionDuration = 200;
            if (panel) {
                if (expandTimeOut) {
                    window.clearTimeout(expandTimeOut);
                }
                /** Avoid fixed size after transition */
                if (!isExpanded.value) {
                    if (!fromSSR.value) {
                        // Panel is closing
                        if (isMountedDelayed.value) {
                            panel.style.maxHeight = `${panel.scrollHeight}px`;
                            // Prevent animation on first mount
                            panel.style.transition = 'max-height 0.2s ease-out';
                        }
                        if (!fromSSR.value) {
                            window.setTimeout(() => {
                                panel.style.maxHeight = '0';
                            }, 1);
                        }
                    }
                } else {
                    // Panel is opening
                    if (isMountedDelayed.value) {
                        // Prevent animation on first mount
                        panel.style.transition = 'max-height 0.2s ease-out';
                    }
                    if (!fromSSR.value) {
                        panel.style.maxHeight = `${panel.scrollHeight}px`;
                    }
                    expandTimeOut = window.setTimeout(() => {
                        panel.style.maxHeight = 'none';
                        panel.style.transition = '';
                    }, transitionDuration);
                }
            }
        } else if (fromDisplayMode) {
            const panel = tabChildrenRef.value;
            if (panel) {
                // Panel is closing
                panel.style.maxHeight = `${panel.scrollHeight}px`;
                // Prevent animation on first mount
                panel.style.transition = 'max-height 0.2s ease-out';
                window.setTimeout(() => {
                    panel.style.maxHeight = '0';
                }, 1);
            }
        }
    }

    function handleClick() {
        if (isCollapsable.value && (isDisplayModeFull.value || isDisplayMobile.value) && !props.hideCollapseOnFull) {
            expandedTabsStore.toggleExpandTab(tab.value.id);
        } else if (tab.value.action && typeof tab.value.action === 'function') {
            tab.value.action();
        }
    }

    watch(isExpanded, () => onExpandChange());
    watch(isDisplayModeReduced, (value) => onExpandChange(value));
    onMounted(onExpandChange);

    // - Active state

    const isActive = computed<boolean>(() => currentActiveRoute.value === tab.value.id || isAnyChildActive.value);
    const isAnyChildActive = computed<boolean>(() => !!tab.value.children?.some((child) => isTabActive(child.id)));

    function isTabActive(id: string) {
        return currentActiveRoute.value === id;
    }

    // - Attributes
    const getComputedAttributes = computed<Partial<AnchorHTMLAttributes>>(() => {
        if (!isCollapsable.value && tab.value.url) {
            return {
                href: tab.value.url.href,
                ...(tab.value.url.blank && {
                    target: '_blank',
                    rel: 'noopener noreferer',
                }),
                ...tab.value.customAttrs,
            };
        } else {
            return {};
        }
    });

    const getTransitionMode = computed<'fade-opacity' | 'default'>(() => {
        if (isExpanded.value) {
            return 'default';
        } else {
            return 'fade-opacity';
        }
    });

    // - Children

    const getChildren = computed(() => {
        if (isDisplayModeReduced.value && !props.tab.children?.length && !props.selector) {
            return [tab.value];
        } else {
            return props.tab.children;
        }
    });

    // Type-check in unit tests
    defineExpose({
        isCollapsable,
        isExpanded,
        isActive,
        isAnyChildActive,
        getComponentType,
    });
</script>

<style lang="scss" scoped>
    $freelancer-avatar-size: 32px;

    .navigation-tab {
        flex-flow: column nowrap;
        width: 100%;
        overflow: hidden;
        $r: &;
        padding: 2px var(--joy-core-spacing-2);

        &-item {
            height: 48px;
            flex-flow: row nowrap;
            align-items: center;
            padding: 0 var(--joy-core-spacing-4);
            text-decoration: none;
            color: var(--joy-color-neutral-50);
            border-radius: var(--joy-core-radius-3);
            font-size: var(--joy-font-size-primary-300);

            background-color: transparent;
            cursor: pointer;

            &.isMounted {
                transition: background-color 0.3s ease-out, padding 0.4s ease;
            }

            &.hasBorder {
                border: 1px var(--joy-color-neutral-30) solid;
            }

            &_icon-wrapper {
                flex: 0 0 auto;
                display: flex;
                justify-content: center;
            }

            &_custom-icon-wrapper {
                flex: 0 0 auto;
            }

            &_title-wrapper {
                display: flex;

                flex: 1 1 auto;
                color: var(--joy-color-neutral-60);
                margin-left: var(--joy-core-spacing-2);
                line-height: 1;
                max-width: 100%;
                position: relative;
                align-items: center;

                &_notifications-container {
                    position: relative;
                    display: flex;
                    flex-flow: row nowrap;
                    max-width: 100%;
                    flex: 0 1 auto;
                    min-width: 0;
                    align-items: center;

                    span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 100%;
                        display: inline-block;
                        line-height: 1.3;
                        flex-shrink: 1;
                        flex-grow: 0;
                        min-width: 0;
                    }
                    .alt-icon {
                        flex: 0 0 auto;
                        vertical-align: text-top;
                        margin-left: 6px;
                    }
                }
            }

            &_collapse-icon-wrapper {
                flex: 0 0 auto;
                margin-left: var(--joy-core-spacing-2);
                transition: transform 0.2s ease-out;
                transform-origin: center;
                align-items: center;

                joy-icon {
                    height: 18px;
                    color: var(--joy-color-neutral-60);
                }

                &.isExpanded {
                    transform: rotate(-180deg);
                }
            }
        }

        &-children {
            flex-flow: column nowrap;
            max-height: 0px;
            border-radius: var(--joy-core-radius-3);
            overflow: hidden;
            $child: &;
            background-color: white;

            &.isReduced {
                max-height: 400px;
                max-width: none;
                position: absolute;
                overflow-y: auto;
                left: calc(100% + var(--joy-core-spacing-2));
                top: -2px;
                z-index: 1000;
                box-shadow: 2px 0 15px rgba(24, 24, 24, 0.05);
                border-radius: var(--joy-core-radius-3);
                background-color: white;
                // overflow: visible;
                padding: 2px var(--joy-core-spacing-1);

                &.isAnchoredBottom {
                    bottom: -2px;
                    top: auto;
                    flex-flow: column;
                }
            }
            &.isChildrenShownInADropdown {
                &:not(.isReduced) {
                    max-height: 400px;
                    max-width: none;
                    position: absolute;
                    overflow-y: auto;
                    z-index: 1000;
                    border-radius: var(--joy-core-radius-3);
                    background-color: white;
                    // overflow: visible;
                    padding: 2px var(--joy-core-spacing-1);

                    top: calc(100%);
                    left: 0;
                    width: calc(100% - calc(var(--joy-core-spacing-2) * 2));
                    margin-left: var(--joy-core-spacing-2);
                    margin-top: var(--joy-core-spacing-1);
                    box-shadow: var(--joy-core-elevation-3);
                }
            }

            &_reduced-title {
                height: 48px;
                flex: 0 0 auto;
                flex-flow: row nowrap;
                align-items: center;
                padding: 0 var(--joy-core-spacing-4);
                text-decoration: none;
                color: var(--joy-color-neutral-60);
                font-weight: bold;
                transition: background-color 0.15s ease-out, padding 0.3s ease;

                span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                &.isEmpty {
                    border-radius: var(--joy-core-radius-3);
                    padding: 0 var(--joy-core-spacing-6);
                }

                &:not(.isEmpty) {
                    cursor: default !important;
                }
            }

            &_list {
                flex-flow: column wrap;
                background-color: white;
                border-radius: 0 0 10px 0;
            }

            &.isMobile {
                overflow: hidden;
            }

            &:not(.isReduced):not(.isChildrenShownInADropdown) {
                border-radius: 0px !important;
            }

            &.isExpanded.fromSSR {
                max-height: none;
            }
        }

        &.isCollapsable.isReduced {
            #{$r}-children {
                min-width: 200px;
            }
        }

        // Active/not Active state

        &:not(.isActive) {
            &.isFull {
                #{$r}-item:hover {
                    background-color: var(--joy-color-neutral-20);
                }
            }
            &.isReduced:hover,
            &.isChildrenShownInADropdown:hover,
            &.isCollapsable.isReduced:hover {
                #{$r}-item {
                    background-color: var(--joy-color-neutral-20);
                }
            }
        }

        &.isActive {
            &.isExpanded {
                #{$r}-item {
                    &:hover {
                        background-color: var(--joy-color-neutral-20);
                    }
                    &_title-wrapper {
                        font-weight: bold;
                    }
                }
            }
            &:not(.isExpanded) {
                #{$r}-item {
                    background-color: var(--joy-color-secondary-10);

                    &_title-wrapper {
                        font-weight: bold;
                    }
                }
            }
        }

        // - Reduced

        &.isReduced {
            justify-content: center;
            overflow: visible;
            padding: 2px var(--joy-core-spacing-2);

            #{$r}-item {
                padding: 0 calc(var(--joy-core-spacing-4) + 2px);

                &_icon-wrapper {
                    .svg-icon-wrapper {
                        transform: scale(1.2);
                    }
                }
            }

            &.isHovering.isCollapsable {
                &.isAnchoredBottom {
                    &::before {
                        content: '';
                        position: absolute;
                        background: transparent;
                        height: 48px;
                        width: 30px;
                        right: -10px;
                        bottom: 100%;
                        z-index: 10;
                    }
                }
                &:not(.isAnchoredBottom) {
                    &::before {
                        content: '';
                        position: absolute;
                        background: transparent;
                        height: 48px;
                        width: 30px;
                        right: -10px;
                        top: 100%;
                        z-index: 10;
                    }
                }
            }

            &.isHovering {
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 100%;
                    width: calc(var(--joy-core-spacing-2) * 2);
                    height: 60px;
                }
            }
        }

        &.isChildrenShownInADropdown {
            &:not(.isReduced) {
                justify-content: center;
                overflow: visible;
                padding: 2px var(--joy-core-spacing-2);

                #{$r}-item {
                    padding: 0 calc(var(--joy-core-spacing-4) + 2px);

                    &_icon-wrapper {
                        .svg-icon-wrapper {
                            transform: scale(1.2);
                        }
                    }
                }

                &.isHovering {
                    &::after {
                        content: '';
                        position: absolute;
                        top: 100%;
                        left: 0;
                        height: var(--joy-core-spacing-1);
                        width: 100%;
                        z-index: 1;
                    }
                }
            }
        }

        &.isCustom {
            #{$r}-item {
                justify-content: center;
                padding: 0 var(--joy-core-spacing-4) !important;
            }
        }

        &.isReduced {
            @media screen and (max-height: 700px) {
                padding-top: 0;

                #{$r}-item {
                    height: 35px;
                }
            }
        }
    }
</style>
