<template>
    <FieldAutocomplete
        ref="autocompleteRef"
        v-model:focused="isJobFocused"
        v-model:search-value="jobQuery"
        :suggestions="formatedSuggestions"
        name="q-search"
        data-testid="header_search-form__job-input"
        :placeholder="t('navbar.header.search.form.job.label')"
        :prevent-tab-default="true"
        @input="handleInput"
        @select="handleSelect"
    />
</template>

<script setup lang="ts">
    import {onMounted, ref, useTranslation, navbarEventBus, onBeforeUnmount, watch} from '#imports';
    import {storeToRefs} from 'pinia';
    import {useSearchForm} from '../../search.store';
    import type {FocusSearchJobInputParams} from '../../search.types';
    import FieldAutocomplete from './FieldAutocomplete.vue';
    import {useHeaderMenu} from '@navbar-logged/features/header-menu';
    import {useJobAutocomplete} from '../../composables/useJobAutocomplete';

    const headerMenuStore = useHeaderMenu();

    const autocompleteRef = ref<InstanceType<typeof FieldAutocomplete>>();

    const {t} = useTranslation();

    const searchFormStore = useSearchForm();
    const {jobQuery, isJobFocused, fullJobSuggestion, jobZoomEffect} = storeToRefs(searchFormStore);

    const {formatedSuggestions, getSuggestions, setSelectedValue} = useJobAutocomplete();

    function handleSelect(value: string | null) {
        setSelectedValue(value);
        fullJobSuggestion.value = value;
        navbarEventBus.emit('focus-search-location-input');
    }

    function handleInput(value: string | null) {
        fullJobSuggestion.value = value;
        getSuggestions(value);
    }

    function focusField() {
        autocompleteRef.value?.focus();
    }

    // set jobZoomEffect to false if isJobFocused is false
    watch(isJobFocused, () => {
        if (!isJobFocused.value) {
            jobZoomEffect.value = false;
        }
    });

    function focusSearchJobInput(param: FocusSearchJobInputParams | undefined) {
        focusField();
        if (param?.enableZoom) {
            jobZoomEffect.value = true;
        }
    }

    onMounted(() => {
        if (headerMenuStore.searchPreviewTarget === 'JOB') {
            focusField();
        }
        navbarEventBus.on('focus-search-job-input', focusSearchJobInput);
    });

    onBeforeUnmount(() => {
        navbarEventBus.off('focus-search-job-input', focusSearchJobInput);
    });
</script>
