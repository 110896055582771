/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserOptions
 */
export interface UserOptions {
    /**
     * 
     * @type {boolean}
     * @memberof UserOptions
     */
    admin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOptions
     */
    maySeeMembers: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOptions
     */
    accessGrantedToInsights: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOptions
     */
    platformAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOptions
     */
    newUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserOptions
     */
    onboarded: boolean;
}

/**
 * Check if a given object implements the UserOptions interface.
 */
export function instanceOfUserOptions(value: object): value is UserOptions {
    if (!('admin' in value) || value['admin'] === undefined) return false;
    if (!('maySeeMembers' in value) || value['maySeeMembers'] === undefined) return false;
    if (!('accessGrantedToInsights' in value) || value['accessGrantedToInsights'] === undefined) return false;
    if (!('platformAdmin' in value) || value['platformAdmin'] === undefined) return false;
    if (!('newUser' in value) || value['newUser'] === undefined) return false;
    if (!('onboarded' in value) || value['onboarded'] === undefined) return false;
    return true;
}

export function UserOptionsFromJSON(json: any): UserOptions {
    return UserOptionsFromJSONTyped(json, false);
}

export function UserOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOptions {
    if (json == null) {
        return json;
    }
    return {
        
        'admin': json['admin'],
        'maySeeMembers': json['maySeeMembers'],
        'accessGrantedToInsights': json['accessGrantedToInsights'],
        'platformAdmin': json['platformAdmin'],
        'newUser': json['newUser'],
        'onboarded': json['onboarded'],
    };
}

export function UserOptionsToJSON(value?: UserOptions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'admin': value['admin'],
        'maySeeMembers': value['maySeeMembers'],
        'accessGrantedToInsights': value['accessGrantedToInsights'],
        'platformAdmin': value['platformAdmin'],
        'newUser': value['newUser'],
        'onboarded': value['onboarded'],
    };
}

