import {ref, watch} from 'vue';

import type {SearchPreviewTarget} from '../search.types';
import {useNavbarState} from '@navbar-registry';
import {SearchDisplayMode} from '@navbar/features/search/search.types';
import {useSearchRouteDefinitions} from '@navbar/features/search';

export function useSearchDisplayMode() {
    const {currentPage} = useSearchRouteDefinitions();
    const searchDisplayMode = ref<SearchDisplayMode>(SearchDisplayMode.NONE);
    const searchPreviewTarget = ref<SearchPreviewTarget>('NONE');

    function onCurrentPageChange() {
        if (currentPage.value) {
            searchDisplayMode.value = currentPage.value?.searchDisplayMode;
        } else {
            // FORM is applied as well on freelancer profiles... but freelancers don't have the search, need a refactor here
            searchDisplayMode.value = SearchDisplayMode.FORM;
        }
    }

    watch(currentPage, onCurrentPageChange);
    onCurrentPageChange();

    function setSearchDisplayModeToForm(target: SearchPreviewTarget) {
        searchDisplayMode.value = SearchDisplayMode.FORM;
        searchPreviewTarget.value = target;
    }

    return {
        searchDisplayMode,
        searchPreviewTarget,
        setSearchDisplayModeToForm,
    };
}
