/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvailabilityResource
 */
export interface AvailabilityResource {
    /**
     * 
     * @type {string}
     * @memberof AvailabilityResource
     */
    value: AvailabilityResourceValueEnum;
    /**
     * 
     * @type {string}
     * @memberof AvailabilityResource
     */
    frequency?: AvailabilityResourceFrequencyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AvailabilityResource
     */
    partial: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AvailabilityResource
     */
    nextAvailabilityDate?: Date;
}


/**
 * @export
 */
export const AvailabilityResourceValueEnum = {
    AvailableAndVerified: 'AVAILABLE_AND_VERIFIED',
    Available: 'AVAILABLE',
    AvailableSoon: 'AVAILABLE_SOON',
    NotAvailableWithDate: 'NOT_AVAILABLE_WITH_DATE',
    NotAvailable: 'NOT_AVAILABLE'
} as const;
export type AvailabilityResourceValueEnum = typeof AvailabilityResourceValueEnum[keyof typeof AvailabilityResourceValueEnum];

/**
 * @export
 */
export const AvailabilityResourceFrequencyEnum = {
    OneDayPerWeek: 'ONE_DAY_PER_WEEK',
    TwoDaysPerWeek: 'TWO_DAYS_PER_WEEK',
    ThreeDaysPerWeek: 'THREE_DAYS_PER_WEEK',
    FourDaysPerWeek: 'FOUR_DAYS_PER_WEEK',
    EveningsAndWeekends: 'EVENINGS_AND_WEEKENDS',
    FullTime: 'FULL_TIME'
} as const;
export type AvailabilityResourceFrequencyEnum = typeof AvailabilityResourceFrequencyEnum[keyof typeof AvailabilityResourceFrequencyEnum];


/**
 * Check if a given object implements the AvailabilityResource interface.
 */
export function instanceOfAvailabilityResource(value: object): value is AvailabilityResource {
    if (!('value' in value) || value['value'] === undefined) return false;
    if (!('partial' in value) || value['partial'] === undefined) return false;
    return true;
}

export function AvailabilityResourceFromJSON(json: any): AvailabilityResource {
    return AvailabilityResourceFromJSONTyped(json, false);
}

export function AvailabilityResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailabilityResource {
    if (json == null) {
        return json;
    }
    return {
        
        'value': json['value'],
        'frequency': json['frequency'] == null ? undefined : json['frequency'],
        'partial': json['partial'],
        'nextAvailabilityDate': json['nextAvailabilityDate'] == null ? undefined : (new Date(json['nextAvailabilityDate'])),
    };
}

export function AvailabilityResourceToJSON(value?: AvailabilityResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'value': value['value'],
        'frequency': value['frequency'],
        'partial': value['partial'],
        'nextAvailabilityDate': value['nextAvailabilityDate'] == null ? undefined : ((value['nextAvailabilityDate']).toISOString().substring(0,10)),
    };
}

