<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.65772 13.2755H2.18121C1.53691 13.2755 1 12.7385 1 12.0942V5.7584C1 5.11408 1.53691 4.57715 2.18121 4.57715H15.8188C16.4631 4.57715 17 5.11408 17 5.7584V12.0942C17 12.7385 16.4631 13.2755 15.8188 13.2755H10.4497"
                    stroke="#263035"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                />
                <path
                    d="M16.4613 13.1689V15.2093C16.4613 15.8536 15.9244 16.3905 15.2801 16.3905H2.71636C2.07207 16.3905 1.53516 15.8536 1.53516 15.2093V13.2763"
                    stroke="#263035"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                />
                <path d="M3.4707 16.4004V17.2484" stroke="#263035" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
                <path d="M14.6367 16.4463V17.2498" stroke="#263035" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
                <path
                    d="M9.16164 14.3496C8.40996 14.3496 7.87305 13.8127 7.87305 13.1684V11.665C7.87305 11.5576 7.98043 11.4502 8.08781 11.4502H10.1281C10.2355 11.4502 10.3428 11.5576 10.3428 11.665V13.1684C10.3428 13.8127 9.80593 14.3496 9.16164 14.3496Z"
                    stroke="#263035"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    d="M6.47656 4.57728V3.18125C6.47656 2.53693 7.01348 2 7.65777 2H10.5571C11.2014 2 11.7383 2.53693 11.7383 3.18125V4.57728"
                    stroke="#263035"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                />
            </svg>
        </template>
        <template #active>
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5449 16.4004V17.3604" stroke="#263035" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
                <path
                    d="M10.4933 13.1996H15.8267C16.4667 13.1996 17 12.6663 17 12.0263V5.73291C17 5.09291 16.4667 4.55957 15.8267 4.55957H2.17333C1.53333 4.55957 1 5.09291 1 5.73291V12.0263C1 12.6663 1.53333 13.1996 2.17333 13.1996H7.61333V10.7463H10.4933V13.1996Z"
                    fill="#263035"
                    stroke="#263035"
                    stroke-width="1.06667"
                    stroke-miterlimit="10"
                />
                <path
                    d="M1.64208 13.0938V15.1204C1.64208 15.7604 2.17542 16.2938 2.81542 16.2938H15.2954C15.9354 16.2938 16.4688 15.7604 16.4688 15.1204V13.2004"
                    stroke="#263035"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                />
                <path d="M3.45508 16.4004V17.3604" stroke="#263035" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" />
                <path
                    d="M8.89383 14.2667C9.64049 14.2667 10.1738 13.7334 10.1738 13.0934V11.6001C10.1738 11.4934 10.0672 11.3867 9.96049 11.3867H7.93383C7.82716 11.3867 7.7205 11.4934 7.7205 11.6001V13.0934C7.7205 13.7334 8.25383 14.2667 8.89383 14.2667Z"
                    fill="#263035"
                    stroke="#263035"
                    stroke-width="1.06667"
                    stroke-miterlimit="10"
                />
                <path
                    d="M11.5605 4.56001V3.17334C11.5605 2.53334 11.0272 2 10.3872 2H7.50721C6.86721 2 6.33388 2.53334 6.33388 3.17334V4.56001"
                    stroke="#263035"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                />
                <path
                    d="M6.65237 9.46582C6.65237 10.7458 7.71904 11.8125 8.99904 11.8125C10.279 11.8125 11.3457 10.7458 11.3457 9.46582"
                    stroke="white"
                    stroke-width="1.06667"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
