<template>
    <VJoyWrapper no-margin class="modify-availability" align="stretch" direction="column" wrap="nowrap">
        <div>
            <VJoySelectableItemGroup v-model:value="selectAvailabilityModel" :options="availabilityOptions" :full-width="true">
                <template #item="item">
                    <template v-if="item.value === 'NOT_AVAILABLE'">
                        <VJoyIcon :name="(item.icon as JoyIconsId)" />
                        {{ item.label }}
                    </template>
                    <template v-else>
                        <VJoyIcon :name="(item.icon as JoyIconsId)" />
                        {{ item.label }}
                    </template>
                </template>
            </VJoySelectableItemGroup>
        </div>

        <div v-if="isNotAvailable">
            <VJoyLabel class="modify-availability__label">{{ t('profile.availability.modify.next.availability.title') }}</VJoyLabel>
            <VJoyRadioGroup :direction="radiosDirection">
                <VJoyRadio
                    v-for="item in months"
                    :key="item"
                    v-model="nextAvailabilityDateByMonthSelected"
                    data-testid="navbar-set-availability-by-month"
                    :value="item"
                >
                    {{ t('profile.availability.edit.next.availability.date.choice', [item]) }}
                </VJoyRadio>
            </VJoyRadioGroup>

            <VJoyWrapper item-stretch>
                <MaltCalendar
                    data-testid="navbar-date-picker"
                    class="modify-availability__calendar"
                    :class="{'modify-availability__calendar--relative': showCalendarRelative}"
                    :date-pattern="datePattern"
                    :date-pattern-regex="datePatternRegex"
                    :model-value="state?.nextAvailabilityDate"
                    :placeholder="placeholder"
                    :min-date="minDateForAvailabilityInput"
                    :max-date="maxDateForAvailabilityInput"
                    @update:model-value="nextAvailabilityDateUpdate"
                    @click.stop
                />
            </VJoyWrapper>
        </div>
        <div v-else>
            <VJoyLabel class="modify-availability__label">{{ t('profile.availability.modify.frequency.title') }}</VJoyLabel>
            <VJoyWrapper item-stretch>
                <VJoySelect v-model="frequencyModel" data-testid="navbar-set-availability-frequency">
                    <option v-for="frequency in frequencies" :key="frequency" :value="frequency">
                        {{ t(`profile.frequency.text.${frequency}`) }}
                    </option>
                </VJoySelect>
            </VJoyWrapper>
        </div>
    </VJoyWrapper>
</template>

<script setup lang="ts">
    import {useTranslation} from '#imports';
    import type {AvailabilityUpdateRequestResource, AvailabilityUpdateRequestResourceFrequencyEnum} from '@navbar-api';
    import {AvailabilityResourceFrequencyEnum, AvailabilityUpdateRequestResourceValueEnum} from '@navbar-api';
    import {VJoyIcon, VJoySelectableItemGroup, VJoyRadio, VJoyRadioGroup, VJoyLabel, VJoyWrapper, VJoySelect} from '@maltjoy/core-vue';
    import {addMonths, addYears, format} from 'date-fns';
    import {getCalendarProperties} from '#malt/nuxt-utils-module';
    import {computed, ref, watch, onMounted} from 'vue';
    import type {TJoySelectableItemOption} from '@maltjoy/core-vue';
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    import type {JoyIconsId} from '@maltjoy/icons';
    import type {PropType} from 'vue';

    const {t} = useTranslation();
    const {datePattern, datePatternRegex, placeholder} = getCalendarProperties();

    const props = defineProps({
        state: {type: Object as PropType<AvailabilityUpdateRequestResource | null>, default: null},
        radiosDirection: {type: String as PropType<'horizontal' | 'vertical'>, required: true},
        showCalendarRelative: {type: Boolean, default: false},
    });

    const selectAvailabilityModel = ref<AvailabilityUpdateRequestResourceValueEnum>();
    const availabilityOptions = ref<TJoySelectableItemOption[]>([
        {
            icon: 'ban',
            value: 'NOT_AVAILABLE',
            id: 'NOT_AVAILABLE',
            label: t('profile.availability.edit.NOT_AVAILABLE'),
            attrs: {'data-testid': 'navbar-availability-set-available-no'},
        },
        {
            icon: 'check',
            value: 'AVAILABLE',
            id: 'AVAILABLE',
            label: t('profile.availability.edit.AVAILABLE'),
            attrs: {'data-testid': 'navbar-availability-set-available-yes'},
        },
    ]);

    const frequencyModel = ref<AvailabilityUpdateRequestResourceFrequencyEnum>();
    const frequencies = Object.values(AvailabilityResourceFrequencyEnum);
    const months = [1, 3, 6];

    const emit = defineEmits<{(e: 'stateUpdated', state: Partial<AvailabilityUpdateRequestResource>): void}>();

    onMounted(() => {
        selectAvailabilityModel.value = props.state?.value;
        frequencyModel.value = props.state?.frequency;
        nextAvailabilityDateByMonthSelected.value = numberOfMonthSelected.value;
    });

    watch(
        () => props.state?.nextAvailabilityDate,
        () => {
            if (numberOfMonthSelected.value) {
                nextAvailabilityDateByMonthSelected.value = numberOfMonthSelected.value;
            }
        },
    );

    watch(selectAvailabilityModel, () => {
        if (selectAvailabilityModel.value) {
            emit('stateUpdated', {value: selectAvailabilityModel.value});
        }
    });

    watch(frequencyModel, () => {
        if (frequencyModel.value) {
            emit('stateUpdated', {frequency: frequencyModel.value});
        }
    });

    function nextAvailabilityDateUpdate(value?: Date) {
        emit('stateUpdated', {nextAvailabilityDate: value});
    }

    const isNotAvailable = computed(() => selectAvailabilityModel.value === AvailabilityUpdateRequestResourceValueEnum.NotAvailable);

    const nextAvailabilityDateByMonthSelected = ref<number>();

    watch(nextAvailabilityDateByMonthSelected, () => {
        const month = Number(nextAvailabilityDateByMonthSelected.value);
        const nextAvailabilityDate = addMonths(new Date(), month);

        emit('stateUpdated', {nextAvailabilityDate});
    });

    const numberOfMonthSelected = computed(() => {
        if (!props.state?.nextAvailabilityDate) {
            return undefined;
        }

        for (const numberOfMonth of months) {
            const now = addMonths(new Date(), numberOfMonth);

            if (getDateToString(now) === getDateToString(props.state.nextAvailabilityDate)) {
                return numberOfMonth;
            }
        }

        return undefined;
    });

    const minDateForAvailabilityInput = new Date();
    const maxDateForAvailabilityInput = addYears(new Date(), 3);

    function getDateToString(date: Date) {
        return format(date, 'yyyy-MM-dd');
    }
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/text' as text;

    .modify-availability {
        &__selectable-item-content {
            display: flex;
            gap: var(--joy-core-spacing-2);
            align-items: center;
        }

        &__label {
            margin-bottom: var(--joy-core-spacing-6);

            label {
                @include text.getFontBySize(primary-450);
            }
        }

        .joy-radio-label {
            @include text.getFontBySize(primary-400);
        }

        &__calendar {
            &--relative .joy-calendar__calendar {
                position: relative !important;
                transform: none !important;
            }
        }
    }
</style>
