import {setCssVariable, useDisplayMobile} from '#malt/nuxt-utils-module';
import {ref, watch} from 'vue';
import {NavBarWidth} from '../aside-menu.types';
import {useDisplayMode} from '../stores/display-mode.store';

export function useMobileNavBar() {
    const {isDisplayMobile} = useDisplayMobile();
    const displayModeStore = useDisplayMode();

    const isMobileNavBarOpened = ref(false);

    let timeoutCssSetter: NodeJS.Timeout | null = null;

    function onDisplayMobileChange(state: boolean) {
        if (import.meta.client) {
            if (timeoutCssSetter) {
                clearTimeout(timeoutCssSetter);
            }
            if (state) {
                setCssVariable('--aside-menu-left', '-100%');
                setCssVariable('--layout-padding-left', '0px');
                timeoutCssSetter = setTimeout(() => {
                    setCssVariable('--aside-menu-width', `${NavBarWidth.Mobile}px`);
                }, 300);
            } else {
                isMobileNavBarOpened.value = false;
                displayModeStore.setNavbarDisplayMode();
                setCssVariable('--aside-menu-left', '0px');
            }
        }
    }

    function setMobileNavBarOpened(value: boolean) {
        if (value) {
            isMobileNavBarOpened.value = true;
            setCssVariable('--aside-menu-left', '0px');
        } else {
            isMobileNavBarOpened.value = false;
            setCssVariable('--aside-menu-left', '-100%');
        }
    }

    function toggleMobileNavBarOpened() {
        setMobileNavBarOpened(!isMobileNavBarOpened.value);
    }

    watch(isDisplayMobile, onDisplayMobileChange, {immediate: true});

    return {
        toggleMobileNavBarOpened,
        setMobileNavBarOpened,
        isMobileNavBarOpened,
    };
}
