import {useTranslation} from '#imports';

/**
 * Return calendar properties for a Prime Vue Calendar component
 *
 * @usage
 *
 * ```vue
    <template>
        <MaltCalendar 
            :placeholder="placeholder"
            :datePattern="datePattern"
            :datePatternRegex="datePatternRegex"
        />
    </template>
    <script setup lang='ts'>
        import {getCalendarProperties} from '#malt/nuxt-utils-module';
            
        const {datePattern, datePatternRegex, placeholder} = getCalendarProperties();
    </script>
 * ```

    @see https://doc.malt.tech/guide/frontend/include-design-system.html
 */
export function getCalendarProperties() {
    const {t} = useTranslation();

    return {
        placeholder: t('generic.form.date.human.pattern'),
        datePattern: t('generic.form.date.java.pattern'),
        datePatternRegex: t('generic.form.date.pattern'),
    };
}
