<template>
    <ClientOnly>
        <ModifyAvailabilityDialog />
        <VJoyAvailability
            v-if="availability"
            class="availability"
            data-testid="navbar-availability"
            :is-partial="availability?.partial"
            :status="availability?.value"
            :label="headerLabel"
            @click.stop="onClick"
        ></VJoyAvailability>
        <template #fallback>
            <span></span>
        </template>
    </ClientOnly>
</template>

<script setup lang="ts">
    import type {Pinia} from 'pinia';
    import {storeToRefs} from 'pinia';
    import ModifyAvailabilityDialog from './availability-dialog/ModifyAvailabilityDialog.vue';
    import {computed, navbarEventBus, onMounted, onScopeDispose, useLogger, useNuxtApp, useRoute, useTranslation, watch} from '#imports';
    import {useAvailability} from '../availability.store';
    import {ComponentSelected} from '../availability.types';
    import {VJoyAvailability} from '@maltjoy/core-vue';

    const {$pinia} = useNuxtApp();
    const logger = useLogger();

    const route = useRoute();
    const {t} = useTranslation();

    const availabilityStore = useAvailability($pinia as Pinia);
    const {availability, componentSelected} = storeToRefs(availabilityStore);
    const {openAvailibility} = availabilityStore;

    function onClick() {
        openAvailibility('USER_CLICK_ON_NAVBAR');
    }

    const headerLabel = computed(() => {
        return t('profile.availability.indicator.explicit.label.v2', {availability: availability.value?.value});
    });

    watch(
        () => route.hash + route.query,
        () => {
            if (process.client) {
                const modifyAvailabilityParam = route.query.modifyAvailability;
                // Warn, route hash are not sent to server
                if (route.hash.startsWith('#edit-availability') || modifyAvailabilityParam) {
                    const source = route.params.source ?? 'UNKNOWN';
                    if (source === 'UNKNOWN') {
                        logger.warn('Unknown tracking availability source', document.referrer);
                    }
                    componentSelected.value = ComponentSelected.DIALOG;
                    const trackingEntryPoint = modifyAvailabilityParam ? 'email' : 'notification';
                    openAvailibility(trackingEntryPoint);
                }
            }
        },
        {immediate: true},
    );

    const openAvailibilityFromEvent = (param: {source: string}) => openAvailibility(param.source);
    onMounted(() => {
        navbarEventBus.on('open-availibility-dialog', openAvailibilityFromEvent);
    });
    onScopeDispose(() => {
        navbarEventBus.off('open-availibility-dialog', openAvailibilityFromEvent);
    });
</script>

<style scoped>
    .availability {
        margin-left: auto;
        cursor: pointer;
    }
</style>
