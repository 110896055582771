<template>
    <div class="svg-icon-wrapper">
        <div class="svg-icon" :alt="name">
            <ClientOnly>
                <Transition name="partial-fade-opacity">
                    <slot v-if="!props.active || (props.active && !$slots.active)" />
                    <slot v-else-if="$slots.active" name="active" />
                </Transition>
                <template #fallback>
                    <slot v-if="!props.active || (props.active && !$slots.active)" />
                    <slot v-else-if="$slots.active" name="active" />
                </template>
            </ClientOnly>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {commonIconProps} from '../icons/common-props';

    const props = defineProps(commonIconProps);
</script>

<style lang="scss" scoped>
    .svg-icon-wrapper {
        height: calc(v-bind(size) * 1px);
        width: calc(v-bind(size) * 1px);
        color: v-bind(color);
        position: relative;
        display: inline-block;
        transition: transform 0.3s ease;
        flex: 0 0 auto;

        .svg-icon {
            position: absolute;
            height: 100%;
            width: 100%;
            inset: 0;

            :deep(svg) {
                height: 100%;
                width: 100%;
            }
        }
    }
</style>
