/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NavbarStateResource
 */
export interface NavbarStateResource {
    /**
     * 
     * @type {string}
     * @memberof NavbarStateResource
     */
    navbarDisplayMode: NavbarStateResourceNavbarDisplayModeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof NavbarStateResource
     */
    isBusinessReferralOnboardingAcknowledged: boolean;
}


/**
 * @export
 */
export const NavbarStateResourceNavbarDisplayModeEnum = {
    Full: 'FULL',
    Reduced: 'REDUCED',
    Funnel: 'FUNNEL'
} as const;
export type NavbarStateResourceNavbarDisplayModeEnum = typeof NavbarStateResourceNavbarDisplayModeEnum[keyof typeof NavbarStateResourceNavbarDisplayModeEnum];


/**
 * Check if a given object implements the NavbarStateResource interface.
 */
export function instanceOfNavbarStateResource(value: object): value is NavbarStateResource {
    if (!('navbarDisplayMode' in value) || value['navbarDisplayMode'] === undefined) return false;
    if (!('isBusinessReferralOnboardingAcknowledged' in value) || value['isBusinessReferralOnboardingAcknowledged'] === undefined) return false;
    return true;
}

export function NavbarStateResourceFromJSON(json: any): NavbarStateResource {
    return NavbarStateResourceFromJSONTyped(json, false);
}

export function NavbarStateResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): NavbarStateResource {
    if (json == null) {
        return json;
    }
    return {
        
        'navbarDisplayMode': json['navbarDisplayMode'],
        'isBusinessReferralOnboardingAcknowledged': json['isBusinessReferralOnboardingAcknowledged'],
    };
}

export function NavbarStateResourceToJSON(value?: NavbarStateResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'navbarDisplayMode': value['navbarDisplayMode'],
        'isBusinessReferralOnboardingAcknowledged': value['isBusinessReferralOnboardingAcknowledged'],
    };
}

