<template>
    <VJoyAvatar
        size="small"
        :full-name="freelancerIdentity?.fullName"
        :photo-url="freelancerPhoto"
        :color="freelancerPhoto ? 'transparent' : 'secondary'"
        class="navbar-freelancer-avatar"
        :class="{'navbar-freelancer-avatar--text': !freelancerPhoto}"
    ></VJoyAvatar>
</template>
<script setup lang="ts">
    import {storeToRefs} from 'pinia';
    import {useUser} from '../../user';
    import {computed} from 'vue';
    import {VJoyAvatar} from '@maltjoy/core-vue';

    const userStore = useUser();
    const {userIdentities} = storeToRefs(userStore);

    const freelancerIdentity = userIdentities.value.find((identity) => {
        return identity.type === 'FREELANCE';
    });
    const freelancerPhoto = computed(() => {
        return freelancerIdentity?.photo ? `${freelancerIdentity.photo?.baseUrl}/${freelancerIdentity.photo?.name}` : undefined;
    });
</script>
<style lang="scss">
    $avatar-size: 32px;
    .navbar-freelancer-avatar {
        font-size: var(--joy-font-size-primary-800);
        width: $avatar-size;
        height: $avatar-size;

        &--text {
            font-size: unset;
        }

        .joy-avatar {
            width: $avatar-size;
        }
    }
</style>
