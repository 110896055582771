<template>
    <div class="header-search-wrapper">
        <HeaderSearchPreview v-if="searchPreviewVisible" data-client-onboarding-search-input @open-search-form="openSearchForm" />
        <HeaderSearchForm v-else-if="!isDisplayMobile" data-client-onboarding-search-input />
        <HeaderSearchBriefEntries />
        <ClientOnly v-if="isDisplayMobile">
            <Teleport to="body" append>
                <VJoyDrawer id="searchFormDialog" ref="searchFormDialog" data-dialog="searchFormDialog" aria-labelledby="searchFormDialogTitle">
                    <template #drawer-header>{{ t('navbar.header.search.dialog.title') }}</template>
                    <template #drawer-body>
                        <HeaderSearchForm />
                    </template>
                </VJoyDrawer>
            </Teleport>
        </ClientOnly>
    </div>
</template>

<script setup lang="ts">
    import {useDisplayMobile} from '#malt/nuxt-utils-module';
    import {useHeaderMenu} from '@navbar-logged/features/header-menu';
    import {storeToRefs} from 'pinia';
    import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
    import HeaderSearchForm from './HeaderSearchForm.vue';
    import HeaderSearchPreview from './HeaderSearchPreview.vue';
    import {useTranslation} from '#imports';
    import type {SearchPreviewTarget} from '../search.types';
    import {useUser} from '@navbar-logged/features/user';
    import {SearchDisplayMode} from '@navbar/features/search/search.types';
    import {VJoyDrawer} from '@maltjoy/core-vue';
    import {navbarEventBus} from '@navbar/events';
    import HeaderSearchBriefEntries from '@navbar/logged/features/search/ui/HeaderSearchBriefEntries.vue';

    const {t} = useTranslation();

    const {isDisplayMobile} = useDisplayMobile();
    const headerMenuStore = useHeaderMenu();
    const {searchDisplayMode} = storeToRefs(headerMenuStore);
    const searchFormDialog = ref<InstanceType<typeof VJoyDrawer> | null>(null);
    const userStore = useUser();

    const searchPreviewVisible = computed(
        () => searchDisplayMode.value === SearchDisplayMode.PREVIEW || (searchDisplayMode.value === SearchDisplayMode.FORM && isDisplayMobile.value),
    );

    async function openSearchForm(target: SearchPreviewTarget) {
        headerMenuStore.setSearchDisplayModeToForm(target);
        if (isDisplayMobile.value) {
            await searchFormDialog.value?.show();
        }
    }

    async function closeSearchForm() {
        if (isDisplayMobile.value) {
            await searchFormDialog.value?.hide();
        }
    }

    onMounted(() => {
        navbarEventBus.on('navbar-search-form-submit', closeSearchForm);
    });

    onBeforeUnmount(() => {
        navbarEventBus.off('navbar-search-form-submit', closeSearchForm);
    });
</script>

<style lang="scss" scoped>
    .header-search-wrapper {
        display: flex;
        justify-content: center;
        align-self: flex-start;

        &:not(.isMobile) {
            margin: auto;
        }

        &.isMobile {
            padding: 0 var(--joy-core-spacing-2) var(--joy-core-spacing-6);
            width: 100%;
        }
    }
</style>
