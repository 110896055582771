import {createFeatureFlagsStore, useNuxtApp} from '#imports';
import {NavbarStateResourceNavbarDisplayModeEnum} from '@navbar-api';
import {useAvailability} from '@navbar-logged/features/availability/availability.store';
import {useLanguageSelector} from '@navbar-logged/features/language-selector';
import {useNotifications} from '@navbar-logged/features/notifications';
import {useProfilePreferences, useUser} from '@navbar-logged/features/user';
import {callWithNuxt} from 'nuxt/app';
import {FreelanceContext, FreelanceProfilePreferencesContext, NavbarContext} from './apis.registry';
import {useNavbarState} from './navbar-state.store';

export const useFeatureFlagsStoreLogged = createFeatureFlagsStore(
    [
        'sse-navbar',
        'repatriation-opportunity-entrypoint-v2',
        'invite-freelancer-starter-plan',
        'organization-settings-admin-console',
        'platform-shutdown-alert',
    ],
    'navbar-feature-flags',
);

export function useAppContext() {
    const nuxtApp = useNuxtApp();
    const pinia = nuxtApp.$pinia;

    const navbarState = useNavbarState(pinia);
    const userStore = useUser(pinia);
    const availabilityStore = useAvailability(pinia);
    const profilePreferencesStore = useProfilePreferences(pinia);
    const notificationsStore = useNotifications(pinia);
    const languageSelector = useLanguageSelector(pinia);
    const featureFlagsStoreLogged = useFeatureFlagsStoreLogged(pinia);

    async function loadNavbarState() {
        const context = await NavbarContext.getContext();
        if (context.state && navbarState.state.navbarDisplayMode === NavbarStateResourceNavbarDisplayModeEnum.Funnel) {
            context.state.navbarDisplayMode = NavbarStateResourceNavbarDisplayModeEnum.Funnel;
        }
        if (context) {
            navbarState.$patch(context);
        }
    }

    async function loadUser() {
        const user = await NavbarContext.getUser();
        userStore.$patch({user});
    }

    /** Load availability and preferences */
    async function loadAvailability() {
        if (userStore.hasSelectedFreelanceIdentity) {
            const [availability, preferences] = await Promise.allSettled([
                FreelanceContext.getAvailability(),
                FreelanceProfilePreferencesContext.getProfilePreferences(),
            ]);
            if (availability.status === 'fulfilled') {
                availabilityStore.availability = availability.value;
            }
            if (preferences.status === 'fulfilled') {
                profilePreferencesStore.preferences = preferences.value;
            }
        }
    }

    async function loadNotifications() {
        await notificationsStore.updateNotifications();
        if (import.meta.client) {
            notificationsStore.startUpdating();
        }
    }

    async function loadAppContext() {
        async function loadAllStates() {
            await Promise.allSettled([
                featureFlagsStoreLogged.loadFeatureFlags(),
                loadNavbarState(),
                loadUser(),
                languageSelector.fetchAllLanguages(),
            ]);
            await loadNotifications();
            await languageSelector.redirectIfLgQueryPresent();
            await callWithNuxt(nuxtApp, loadAvailability);
        }
        try {
            navbarState.pending = true;
            await loadAllStates();
        } catch (e) {
            /* eslint-disable */
            console.error(`Navbar loader error:`, e);
            return null;
        } finally {
            navbarState.pending = false;
        }
    }

    return {
        loadNotifications,
        loadAppContext,
    };
}
