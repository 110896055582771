import {defu} from 'defu';
import type {Ref, ComponentPublicInstance} from 'vue';
import {onBeforeUnmount, onMounted, getCurrentInstance, nextTick} from 'vue';

interface UseDetectOutsideClickOptions {
    /** Triggers listener on mount
     * @default true
     */
    immediate: boolean;
}

/**
 * Detect an outside click from any template ref (Html element or Component)
 *
 * @param element - The target element
 * @param options - composable options {@link UseDetectOutsideClickOptions}
 *
 * @usage
 *
 * ```ts
 * const myRef = ref<HtmlDivElement>();
 * const {startDetecting, stopDetecting} = useDetectOutsideClick(myRef, () => {
 *    // Do stuff
 * })
 *
 * // Or listen on mount
 *
 * const myRef = ref<HtmlDivElement>();
 * const {startDetecting, stopDetecting} = useDetectOutsideClick(myRef, () => {
 *    // Do stuff
 * }, {immediate: true});
 * ```
 */
export function useDetectOutsideClick(
    element: Ref<HTMLElement | ComponentPublicInstance | null | undefined>,
    callback: (event: MouseEvent) => void,
    options?: UseDetectOutsideClickOptions,
) {
    const resolvedOptions = defu(options, {immediate: true});

    function isClickOutside(event: MouseEvent): boolean {
        if (event.target instanceof Element && element.value != null) {
            if (element.value instanceof Element) {
                return !element.value.contains(event.target);
            } else if (!Array.isArray(element.value.$el)) {
                return !(element.value.$el as Element).contains(event.target);
            } else {
                /* eslint-disable-next-line no-console */
                console.warn("[useDetectOutsideClick] Can't find target element. Check if element exists or if there is multiple root nodes");
            }
        }
        return false;
    }

    function handleWindowClick(event: MouseEvent) {
        if (isClickOutside(event)) {
            callback(event);
        }
    }

    /** Start detecting outisde clicks from the element */
    function startDetecting() {
        if (import.meta.client) {
            nextTick(() => {
                window.addEventListener('click', handleWindowClick, {capture: true});
            });
        }
    }

    /** Unsubscribe from detecting outisde clicks */
    function stopDetecting() {
        if (import.meta.client) {
            window.removeEventListener('click', handleWindowClick, {capture: true});
        }
    }

    if (getCurrentInstance()) {
        onMounted(() => {
            if (resolvedOptions.immediate) {
                startDetecting();
            }
        });

        onBeforeUnmount(() => {
            stopDetecting();
        });
    }

    return {
        startDetecting,
        stopDetecting,
    };
}
