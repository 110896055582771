<template>
    <div class="company-selector flex" :class="{isDisplayModeReduced}">
        <div
            class="company-selector_wrapper flex"
            :class="{isOpened: isOpened || isOpenedOnHover}"
            :aria-label="computedLabel"
            data-testid="companySelector"
            @click.stop="handleToggleSelect"
        >
            <FreelancerAvatar v-if="selectedIdentity && selectedIdentity.type === 'FREELANCE'" />
            <SwapCompanyIcon v-else class="main-icon" :active="isOpened || isOpenedOnHover" />

            <template v-if="!isDisplayModeReduced">
                <div class="company-selector_wrapper_label">
                    <span>{{ computedLabel }}</span>
                </div>
                <div class="company-selector_wrapper_icon flex">
                    <VJoyIcon name="chevron-double" />
                </div>
            </template>
        </div>
        <Transition name="fade-scale">
            <div v-if="isOpened || isOpenedOnHover" class="company-selector_options flex" @click.stop>
                <div class="company-selector_options_inner flex">
                    <div
                        v-for="identity of userIdentities"
                        :key="identity.id"
                        :class="{isSelected: selectedIdentity && identity.id === selectedIdentity.id}"
                        class="company-selector_options_inner_item flex"
                        :aria-label="identity.label"
                        @click="handleItemClick(identity.id)"
                    >
                        <FreelancerAvatar v-if="identity.type == 'FREELANCE'" />
                        <span :title="identity.label">{{ identity.label }}</span>
                    </div>
                    <template v-if="!hasFreelancerIdentity">
                        <div v-if="userIdentities.length > 0" class="company-selector__separator" />
                        <NuxtLink
                            external
                            target="_blank"
                            to="/profile/signup/headline"
                            data-testid="BecomeFreelancer"
                            class="company-selector_options_inner_item link flex"
                        >
                            {{ $t('navbar.aside.tabs.title.become.freelancer') }}
                        </NuxtLink>
                    </template>
                    <div class="company-selector__separator"></div>
                    <div class="company-selector_options_inner_item logout flex" data-testid="company-selector-logout" @click="logout">
                        <VJoyIcon name="tab-inverted" color="error" />
                        <span>
                            {{ $t('navbar.aside.tabs.title.logout') }}
                        </span>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
    import {useUser} from '@navbar-logged/features/user';
    import {storeToRefs} from 'pinia';
    import {computed, nextTick, ref, watch} from 'vue';
    import {useDisplayMode} from '..';
    import {SwapCompanyIcon} from '@navbar-logged/common/icons';
    import FreelancerAvatar from './FreelancerAvatar.vue';
    import {useMouseHover} from '#malt/nuxt-utils-module';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const userStore = useUser();
    const displayModeStore = useDisplayMode();
    const {selectedIdentity, userIdentities} = storeToRefs(userStore);
    const {isDisplayModeReduced} = storeToRefs(displayModeStore);
    const {isHovering, startDetecting, stopDetecting} = useMouseHover();

    const isOpened = ref(false);

    const hasFreelancerIdentity = computed<boolean>(
        () => userIdentities.value && userIdentities.value.length > 0 && userIdentities.value.some((identity) => identity.type === 'FREELANCE'),
    );
    const isOpenedOnHover = computed<boolean>(() => isHovering.value && isDisplayModeReduced.value);

    const emit = defineEmits<{
        (e: 'company-selector-state-changed', isOpen: boolean): void;
    }>();

    function closePanel() {
        isOpened.value = false;
        document.documentElement.removeEventListener('click', closePanel);
        emit('company-selector-state-changed', false);
    }

    async function handleToggleSelect() {
        if (isOpened.value) {
            closePanel();
        } else {
            isOpened.value = true;
            await nextTick();
            document.documentElement.addEventListener('click', closePanel);
            emit('company-selector-state-changed', true);
        }
    }

    const computedLabel = computed<string>(() => {
        if (selectedIdentity.value) {
            return selectedIdentity.value.label;
        }
        // TODO translation
        return 'No company selected';
    });

    function handleItemClick(id: string) {
        userStore.setUserIdentity(id);
    }

    function logout() {
        userStore.logoutUser();
    }

    async function companySelectorSideDisplayHoverDetect(value: boolean) {
        await nextTick();
        if (value) {
            startDetecting();
        } else {
            stopDetecting();
        }
    }

    if (import.meta.client) {
        watch(
            () => isDisplayModeReduced.value,
            (value) => companySelectorSideDisplayHoverDetect(value),
            {immediate: true},
        );
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    $company-selector-height: 48px;
    $company-selector-width: 320px;

    .company-selector {
        width: 100%;
        z-index: 13;

        &_wrapper {
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 100%;
            padding: var(--joy-core-spacing-3) var(--joy-core-spacing-4);
            border-radius: var(--joy-core-radius-3);
            user-select: none;
            height: $company-selector-height;
            z-index: 13;

            &_icon {
                flex: 0 0 auto;
                place-items: center;
                margin-left: 8px;
                margin-top: 2px;

                joy-icon {
                    color: var(--joy-color-neutral-60);
                }
            }

            &_label {
                flex: 1 1 auto;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-left: var(--joy-core-spacing-2);
            }

            .loader {
                flex: 0 0 auto;
                margin-left: 4px;
            }

            &.isDisabled {
                cursor: default;
            }

            &:not(.isDisabled):hover {
                background-color: var(--joy-color-neutral-20);
            }

            &.isOpened {
                background-color: var(--joy-color-neutral-20);
                font-weight: bold;
            }
        }
        &_options {
            position: absolute;
            flex-flow: column wrap;
            bottom: calc(100% + 3px);
            left: 0;
            width: auto;
            min-width: 100%;
            max-width: $company-selector-width;
            max-height: 60vh;
            overflow: hidden;
            border-radius: var(--joy-core-radius-3);
            background-color: white;
            box-shadow: 0 -4px 15px 2px rgba(0, 0, 0, 0.1);
            z-index: 1112;
            cursor: pointer;

            &_inner {
                overflow-y: auto;
                flex-flow: column nowrap;
                padding: var(--joy-core-spacing-1);
                gap: var(--joy-core-spacing-1);

                &_item {
                    height: 48px;
                    align-items: center;
                    padding: 0 var(--joy-core-spacing-4);
                    transition: background-color 0.3s ease;
                    text-decoration: none;
                    font-size: var(--joy-font-size-primary-400);
                    flex: 0 0 auto;
                    gap: var(--joy-core-spacing-1);
                    border-radius: var(--joy-core-radius-3);

                    span {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    &.isSelected {
                        background-color: var(--joy-color-secondary-10);
                        font-weight: bold;
                        cursor: default;
                    }

                    &.logout {
                        color: var(--joy-color-primary-70);
                    }

                    &.link {
                        color: var(--joy-color-secondary-30);
                        font-weight: bold;
                    }

                    &:not(.isSelected):hover {
                        background-color: var(--joy-color-neutral-20);

                        &.logout {
                            background-color: var(--joy-color-primary-10);
                        }
                    }

                    .joy-avatar {
                        border: none;
                    }

                    .joy-icon {
                        width: 24px;
                        color: var(--joy-color-primary-70);
                        // justify-content: center;
                    }
                }
            }
        }

        &.isDisplayModeReduced {
            padding: 0 var(--joy-core-spacing-2);

            .company-selector_wrapper {
                padding: var(--joy-core-spacing-3) var(--joy-core-spacing-1);
            }
        }

        &__separator {
            height: 1px;
            background: var(--joy-color-neutral-20);
            width: 90%;
            left: 5%;
            margin: 2px 0;
        }

        @include mq.screen_sm(min) {
            flex-direction: column;

            &_options {
                left: var(--aside-menu-width);
                bottom: calc(100% - $company-selector-height);
            }

            &.isDisplayModeReduced {
                .company-selector_options {
                    width: $company-selector-width;
                    left: calc(var(--aside-menu-width) + var(--joy-core-spacing-2));
                }

                .company-selector_wrapper.isOpened {
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 100%;
                        width: var(--joy-core-spacing-4);
                        height: 100px;
                        cursor: default;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        background: transparent;
                        height: 48px;
                        width: 30px;
                        right: -10px;
                        bottom: 100%;
                        z-index: 10;
                        cursor: default;
                    }
                }
            }
        }
    }
</style>
