<template>
    <div v-if="displayNpsScore" class="nps" :class="{show: showNpsScore, hide: hideNpsScore}">
        <p v-if="currentIdentityIsFreelance" class="nps__title">
            {{ $t('nps.feedback.title.freelance') }}
        </p>
        <p v-else class="nps__title">
            {{ $t('nps.feedback.title') }}
        </p>
        <button class="nps__close-button" @click="closePanel">
            <VJoyIcon size="small" name="cancel" />
        </button>

        <div class="nps-list">
            <p>{{ $t('nps.feedback.detractor.label') }}</p>
            <template v-for="index in 11" :key="index">
                <input :id="'score' + index" type="radio" name="nps" :value="index - 1" @click="submitAnswer(index - 1)" />
                <label :for="'score' + index">{{ index - 1 }}</label>
            </template>
            <p>{{ $t('nps.feedback.promoter.label') }}</p>
        </div>

        <div v-if="displayFeedback" class="nps__comment">
            <p class="nps-list-legend">{{ feedbackQuestion }}</p>
            <div class="nps__comment_textarea">
                <VJoyTextarea v-model="feedback" :placeholder="t('nps.feedback.comment.placeholder')" :maxlength="300"></VJoyTextarea>
            </div>
            <VJoyButton variant="main" @click="submitFeedback">{{ $t('nps.feedback.comment.submit') }}</VJoyButton>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {computed, onMounted, ref} from 'vue';
    import {useAuth, useTranslation, useUniversalPost, useUniversalFetch, useLogger} from '#imports';
    import {storeToRefs} from 'pinia';
    import {pushVJoySnackbar, VJoyIcon, VJoyTextarea, VJoyButton} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const store = useAuth();
    const {currentIdentityIsFreelance} = storeToRefs(store);
    const logger = useLogger();

    const displayFeedback = ref(false);
    const displayNpsScore = ref(false);
    const showNpsScore = ref(false);
    const hideNpsScore = ref(true);
    const score = ref<undefined | number>();
    const feedback = ref('');

    onMounted(async () => {
        try {
            const shouldWeDisplayNps = await useUniversalFetch<boolean>('/api/nps/feedbacks/user/active');
            if (shouldWeDisplayNps) {
                displayNpsScore.value = true;
                showNpsScore.value = true;
                hideNpsScore.value = false;
            }
        } catch (e) {
            logger.warn(e);
        }
    });

    const feedbackQuestion = computed(() => {
        if (!score.value) return t('nps.feedback.score.promoter.question');
        if (score.value < 7) {
            return t('nps.feedback.score.detractor.question');
        } else if (score.value < 9) {
            return t('nps.feedback.score.neutral.question');
        } else {
            return t('nps.feedback.score.promoter.question');
        }
    });

    async function closePanel() {
        showNpsScore.value = false;
        hideNpsScore.value = true;

        if (!score.value) {
            try {
                await useUniversalPost('/api/nps/feedbacks', {
                    body: {
                        responsePath: window.location.pathname,
                        isDismissal: true,
                    },
                });
            } catch (e) {
                logger.warn(e);
            }
        }
    }

    async function submitAnswer(index: number) {
        score.value = index;
        try {
            await useUniversalPost('/api/nps/feedbacks', {
                body: {
                    score: score.value,
                    comment: feedback.value,
                    responsePath: window.location.pathname,
                    isDismissal: false,
                },
            });
            pushVJoySnackbar({props: {message: t('nps.feedback.score.success.label')}});
            displayFeedback.value = true;
        } catch (e) {
            pushVJoySnackbar({
                props: {
                    message: t('nps.feedback.error.label'),
                    level: 'error',
                },
            });
        }
    }

    async function submitFeedback() {
        try {
            await useUniversalPost('/api/nps/feedbacks', {
                body: {
                    score: score.value,
                    comment: feedback.value,
                    responsePath: window.location.pathname,
                    isDismissal: true,
                },
            });
            pushVJoySnackbar({props: {message: t('nps.feedback.score.success.label')}});
            displayFeedback.value = false;
            showNpsScore.value = false;
            hideNpsScore.value = true;
        } catch (e) {
            pushVJoySnackbar({
                props: {
                    message: t('nps.feedback.error.label'),
                    level: 'error',
                },
            });
        }
    }
</script>

<style lang="scss" scoped>
    .nps-list {
        width: 100%;
        display: flex;
        justify-content: center;

        p {
            margin: 0 8px;
            line-height: 60px;
        }

        label {
            margin-left: 4px;
            margin-right: 4px;
            display: inline-block;
            width: 60px;
            height: 60px;
            background-color: var(--joy-color-brand-secondary-70);
            color: white;
            border-radius: 50%;
            text-align: center;
            line-height: 60px;
            cursor: pointer;
            aspect-ratio: 1/1;
        }

        input[type='radio'] {
            display: none;
        }

        input[type='radio']:checked + label {
            color: white;
        }

        input[type='radio'][value='0']:checked + label,
        input[type='radio'][value='1']:checked + label,
        input[type='radio'][value='2']:checked + label,
        input[type='radio'][value='3']:checked + label,
        input[type='radio'][value='4']:checked + label,
        input[type='radio'][value='5']:checked + label,
        input[type='radio'][value='6']:checked + label {
            background-color: var(--joy-color-primary-30);
        }

        input[type='radio'][value='7']:checked + label,
        input[type='radio'][value='8']:checked + label {
            background-color: var(--joy-color-quaternary-50);
        }

        input[type='radio'][value='9']:checked + label,
        input[type='radio'][value='10']:checked + label {
            background-color: var(--joy-color-tertiary-50);
        }
    }

    @keyframes slide-up {
        0% {
            bottom: -300px;
        }
        100% {
            bottom: 0;
        }
    }

    .hide {
        animation: slide-down 0.5s ease-out forwards;
    }

    .show {
        animation: slide-up 0.5s ease-out forwards;
    }

    @keyframes slide-down {
        0% {
            bottom: 0;
        }
        100% {
            bottom: -300px;
        }
    }

    .nps {
        z-index: var(--joy-core-z-index-dialog);
        position: fixed;
        left: 0;
        bottom: -300px;
        width: 100%;
        padding-bottom: 40px;
        background-color: var(--joy-color-neutral-10);
        transition: all 0.3s;

        border-top: 1px solid var(--joy-color-neutral-30);

        p.nps__title {
            font-size: var(--joy-font-size-primary-400);
            font-weight: 700;
            text-align: center;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &__close-button {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 20px;
        }

        &__comment {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 40px auto 0;
            max-width: 600px;

            > p {
                font-weight: 700;
                margin-bottom: 30px;
            }
            &_textarea {
                width: 100%;
            }
        }

        @media screen and (max-width: 991px) {
            display: none;
        }
    }
</style>
