<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <clipPath id="a"><path d="m0 .191406h18v18h-18z" /></clipPath>
                <g clip-path="url(#a)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.48">
                    <path
                        d="m7.72295 5.95585v4.52275h6.96575c0 3.8463-3.1194 6.9658-6.96575 6.9658-3.84632 0-6.97588-3.1195-6.97588-6.9658 0-3.84636 3.11946-6.97591 6.97588-6.97591"
                    />
                    <path d="m14.8099 7.91436h-4.5328v-6.975883c3.8463 0 6.9658 3.119463 6.9658 6.965783" />
                </g>
            </svg>
        </template>
        <template #active>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <clipPath id="a"><path d="m0 .191406h18v18h-18z" /></clipPath>
                <g clip-path="url(#a)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                    <path
                        d="m7.71855 5.96266v4.52014h6.96185c0 3.8442-3.1177 6.9619-6.96185 6.9619-3.84417 0-6.971968-3.1177-6.971968-6.972 0-3.85421 3.117708-6.96184 6.971968-6.96184"
                        stroke-width="1.48"
                    />
                    <path d="m17.2434 7.91h-6.9619v-6.96188c3.8442 0 6.9619 3.11771 6.9619 6.96188z" fill="currentColor" stroke-width="1.5" />
                    <path d="m11.351 12.7228c-.7466 1.2108-2.08856 2.0079-3.6121 2.0079-1.52355 0-2.87556-.8072-3.6222-2.0281" stroke-width="1.48" />
                </g>
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
