<template>
    <div id="navbar-app">
        <HeaderMenu />
        <AsideMenu v-if="!isDisplayModeFunnel" data-navbar-aside />
    </div>
</template>

<script setup lang="ts">
    import {pushVJoySnackbar} from '@maltjoy/core-vue';
    import {callOnce, useCookie, useLocale, useTranslation} from '#imports';
    import {storeToRefs} from 'pinia';
    import {useFeatureFlagsStoreLogged} from './registry';
    import {useUser} from './features/user';
    import {onBeforeUnmount, onMounted} from 'vue';
    import {setCssVariable, useDisplayMobile} from '#malt/nuxt-utils-module';
    import {AsideMenu, useDisplayMode, useExpandedTabs, useMobileNavBar} from './features/aside-menu';
    import {HeaderMenu} from './features/header-menu';
    import {navbarEventBus} from '../events';
    import {NavbarStateResourceNavbarDisplayModeEnum} from '@navbar-api';
    import {useABTestStore} from '../logged/store/abtest-logged.store';

    const displayModeStore = useDisplayMode();
    const {expandTab} = useExpandedTabs();
    const {setMobileNavBarOpened} = useMobileNavBar();

    const {fetchCampaignsVariations} = useABTestStore();

    await callOnce('navbar-campaigns', async () => {
        await fetchCampaignsVariations();
    });

    const userStore = useUser();

    const {isDisplayModeFunnel} = storeToRefs(displayModeStore);
    const {setNavbarDisplayMode} = displayModeStore;
    const {isDisplayMobile} = useDisplayMobile();

    const {features} = useFeatureFlagsStoreLogged();
    const {t} = useTranslation();
    const {locale} = useLocale();

    onMounted(async () => {
        // Client navbar onboarding was removed but we still need to acknowledge it because other features are dependent of it - the net promoter score (nps) doesn't show up until onboarding is acknowledge for example
        // To be removed once backend work is done.
        if (userStore.hasSelectedClientIdentity) {
            await userStore.acknowledgeOnboarding();
        }

        document.documentElement.classList.add('navbar-logged');
        document.body.classList.add('navbar-logged');

        setTimeout(() => {
            document.body.style.transition = 'padding 0.3s ease';
        }, 50);

        navbarEventBus.on('request:navbar-desktop-reduced', overrideNavbarDisplay);

        navbarEventBus.on('request:navbar-desktop-full-with-tab-expanded', overrideNavbarDisplayFullWithTabExpanded);

        navbarEventBus.emit('navbar-logged:mounted');

        setTimeout(() => {
            // It would be great to get this date from an API call. But we're restoring this feature in a quick & dirty
            // way for now.
            // /!\ date is defined at UTC
            // /!\ Date takes a month's *index*, i.e. 0-11
            const shutdownDate = new Date(Date.UTC(2025, 3, 9, 20, 0));

            // use a different cookie for each shutdown event
            const shutdownAcknowledgedCookie = useCookie<boolean>(`platform-shutdown-acknowledged-${shutdownDate.toISOString()}`);

            const shouldDisplayShutdownAlert = features['platform-shutdown-alert'];
            if (!(shutdownAcknowledgedCookie.value ?? false) && shouldDisplayShutdownAlert) {
                // only the time will be printed
                const formattedTime = new Intl.DateTimeFormat(locale.value, {
                    hour: 'numeric',
                    minute: 'numeric',
                    // using the time zone that is used by most of our users
                    timeZone: 'Europe/Paris',
                    timeZoneName: 'short',
                }).format(shutdownDate);

                const {snackbarEventBus} = pushVJoySnackbar({
                    props: {
                        message: t('navbar.temporary.malt.shutdown.alert', [formattedTime]),
                        level: 'warning',
                        duration: 'forever',
                    },
                });
                // not cleaned on destroy, but it will be fine
                snackbarEventBus.on('snackbar:hide', () => {
                    shutdownAcknowledgedCookie.value = true;
                });
            }
        }, 200);
    });

    // Method added for client onboarding.
    // When client starts "find freelancer" job, navbar and tab Manage freelancers needs to be expanded for product tour to be shown
    async function overrideNavbarDisplayFullWithTabExpanded({tab}: {tab: string}) {
        if (isDisplayMobile.value) {
            setMobileNavBarOpened(true);
        }
        await setNavbarDisplayMode(NavbarStateResourceNavbarDisplayModeEnum.Full);
        expandTab(tab);
    }

    async function overrideNavbarDisplay() {
        await setNavbarDisplayMode(NavbarStateResourceNavbarDisplayModeEnum.Reduced);
    }

    onBeforeUnmount(() => {
        document.documentElement.classList.remove('navbar-logged');
        document.body.classList.remove('navbar-logged');

        setCssVariable('--layout-padding-left', '0px');
        setCssVariable('--layout-padding-top', '0px');

        navbarEventBus.off('request:navbar-desktop-reduced', overrideNavbarDisplay);

        navbarEventBus.off('request:navbar-desktop-full-with-tab-expanded', overrideNavbarDisplayFullWithTabExpanded);
    });
</script>
