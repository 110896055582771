import {computed} from '#imports';
import type {MaybeRef} from '@malt/types-utils';
import type {Ref} from 'vue';
import {ref, unref} from 'vue';
import {FetchError} from 'ofetch';

type Result = Record<string, any> | string;

interface UseLocationAutocompleteOptions<T> {
    onSelect: (value: T | null) => string;
    defaultValue?: T;
    defaultSuggestions?: MaybeRef<T[]>;
    languageCode?: string;
    maxResults?: MaybeRef<number>;
    getSuggestions: (query: string | null) => Promise<T[]>;
}

export function useAutocompleteEngine<T extends Result>(inputValue: Ref<string>, options: UseLocationAutocompleteOptions<T>) {
    const lastQueryTimestamp = ref(new Date());
    const lastResponse = ref<T[]>();
    const loading = ref(false);

    // - Suggestions

    // - Current value

    const suggestionsResults = ref<Result[]>([]);
    const lastSelected = ref<Result | null>(null);

    const formatedSuggestions = computed((): T[] => {
        if (suggestionsResults.value.length && inputValue.value.length) {
            return suggestionsResults.value as T[];
        } else if (inputValue.value.length) {
            return [];
        } else {
            return unref(options.defaultSuggestions) ?? [];
        }
    });

    function setSelectedValue(value: T | null) {
        const formattedValue = options.onSelect(value as T);
        inputValue.value = formattedValue;
        lastSelected.value = value;
    }

    // - Fetch suggestion

    async function getSuggestions(query: string | null) {
        const timestamp = new Date();
        loading.value = false;
        if (query?.length) {
            try {
                loading.value = true;
                const data = await options.getSuggestions(query);

                if (timestamp.getTime() < lastQueryTimestamp.value.getTime()) {
                    suggestionsResults.value = lastResponse.value ?? data;
                } else {
                    lastQueryTimestamp.value = timestamp;
                    lastResponse.value = data;
                    suggestionsResults.value = data;
                }
            } catch (e: any) {
                if (e instanceof FetchError && (e.statusCode === 400 || e.statusCode === 403)) {
                    // Don't report 400s [MALT-2Z07] nor 403s [MALT-2V8G] to sentry for autocomplete
                    const data: T[] = [];
                    lastQueryTimestamp.value = timestamp;
                    lastResponse.value = data;
                    suggestionsResults.value = data;
                } else {
                    throw new Error(`useAutoCompleteEngine failed to fetch suggestions for query ${query}`, {cause: e});
                }
            } finally {
                loading.value = false;
            }
        } else {
            suggestionsResults.value = [];
            lastQueryTimestamp.value = new Date();
            lastResponse.value = undefined;
        }
    }

    // - Lifecycle

    if (inputValue.value.length) {
        getSuggestions(inputValue.value);
    }

    // - Cleanup

    return {
        loading,
        formatedSuggestions,
        getSuggestions,
        setSelectedValue,
    };
}
