<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1582_56396)">
                    <path
                        d="M4.6665 1.33333H3.99984V2.66667H4.6665V1.33333ZM15.3332 2.66667C15.7014 2.66667 15.9998 2.36819 15.9998 2C15.9998 1.63181 15.7014 1.33333 15.3332 1.33333V2.66667ZM4.6665 2.66667H15.3332V1.33333H4.6665V2.66667Z"
                        fill="#333330"
                    />
                    <path
                        d="M4.6665 5.33333H3.99984V6.66667H4.6665V5.33333ZM15.3332 6.66667C15.7014 6.66667 15.9998 6.36819 15.9998 6C15.9998 5.63181 15.7014 5.33333 15.3332 5.33333V6.66667ZM4.6665 6.66667H15.3332V5.33333H4.6665V6.66667Z"
                        fill="#4B4B4B"
                    />
                    <path
                        d="M4.6665 9.33333H3.99984V10.6667H4.6665V9.33333ZM15.3332 10.6667C15.7014 10.6667 15.9998 10.3682 15.9998 10C15.9998 9.63181 15.7014 9.33333 15.3332 9.33333V10.6667ZM4.6665 10.6667H15.3332V9.33333H4.6665V10.6667Z"
                        fill="#4B4B4B"
                    />
                    <path
                        d="M4.6665 13.3333H3.99984V14.6667H4.6665V13.3333ZM15.3332 14.6667C15.7014 14.6667 15.9998 14.3682 15.9998 14C15.9998 13.6318 15.7014 13.3333 15.3332 13.3333V14.6667ZM4.6665 14.6667H15.3332V13.3333H4.6665V14.6667Z"
                        fill="#4B4B4B"
                    />
                    <path d="M0.333496 2H1.6135" stroke="#4B4B4B" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="square" />
                    <path d="M0.333496 6H1.6135" stroke="#4B4B4B" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="square" />
                    <path d="M0.333496 10H1.6135" stroke="#4B4B4B" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="square" />
                    <path d="M0.333496 14H1.6135" stroke="#4B4B4B" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="square" />
                </g>
                <defs>
                    <clipPath id="clip0_1582_56396">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
