export function clickOutside(event, rootNode) {
    let parent = event.target;

    while (parent && parent !== rootNode) {
        parent = parent.parentNode;
    }

    return !(parent === rootNode);
}

export function clickOutsideSelector(event, selector) {
    return !event.target.closest(selector);
}
