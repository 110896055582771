<template>
    <Component
        :is="getComponentType"
        :class="{isActive, isFull: isDisplayModeFull, isReduced: isDisplayModeReduced, isShownInADropdown, isAnchoredBottom}"
        class="navigation-tab-child flex"
        :data-testid="tab.id"
        v-bind="getComputedAttributes"
        :aria-label="tab.title"
        @click="handleClick"
    >
        <div class="navigation-tab-child_title-wrapper flex">
            <div class="navigation-tab-child_title-wrapper_notifications-container">
                <span>{{ tab.title }}</span>
                <SvgIcon v-if="tab.icons?.altIcon && isDisplayModeReduced" class="alt-icon" :src="tab.icons.altIcon" name="misc" :size="14" />
                <NotificationBadge v-if="tab.notificationsCount" :count="getAllNotificationsCount" :offset="0" />
            </div>
        </div>
    </Component>
</template>

<script setup lang="ts">
    import {SvgIcon} from '@navbar-logged/common/ui';
    import {NotificationBadge} from '@navbar-logged/features/notifications';
    import {storeToRefs} from 'pinia';
    import type {AnchorHTMLAttributes, PropType} from 'vue';
    import {computed, toRefs} from 'vue';
    import {useAsideMenu} from '../aside-menu.store';
    import type {NavigationTab} from '../aside-menu.types';
    import {useDisplayMode} from '../stores';

    const props = defineProps({
        /** Main tab object */
        tab: {
            type: Object as PropType<NavigationTab>,
            required: true,
        },
        isAnchoredBottom: {type: Boolean},
        isShownInADropdown: {type: Boolean},
    });

    const {tab, isAnchoredBottom} = toRefs(props);
    const asideMenuStore = useAsideMenu();
    const displayModeStore = useDisplayMode();
    const {currentActiveRoute} = storeToRefs(asideMenuStore);
    const {isDisplayModeFull, isDisplayModeReduced} = storeToRefs(displayModeStore);

    const hoverColor = computed(() => tab.value.customHoverColor ?? `var(--joy-color-neutral-20)`);

    const getNotificationsCount = computed(() => tab.value?.notificationsCount ?? 0);
    const getAllNotificationsCount = computed(() => getNotificationsCount.value);

    /** Dynamic component type */
    const getComponentType = computed<'div' | 'a'>(() => (tab.value.url ? 'a' : 'div'));
    const getComputedAttributes = computed<Partial<AnchorHTMLAttributes>>(() => {
        if (tab.value.url) {
            return {
                href: tab.value.url.href,
                ...(tab.value.url.blank && {
                    target: '_blank',
                    rel: 'noopener noreferer',
                }),
                ...tab.value.customAttrs,
            };
        } else {
            return {};
        }
    });

    // Status
    const isActive = computed<boolean>(() => currentActiveRoute.value === tab.value.id);

    // - Events
    function handleClick() {
        if (tab.value.action && typeof tab.value.action === 'function') {
            tab.value.action();
        }
    }
</script>

<style lang="scss" scoped>
    .navigation-tab-child {
        flex-flow: row nowrap;
        align-items: center;
        padding: 0 var(--joy-core-spacing-4);
        text-decoration: none;
        background-color: white;
        color: var(--joy-color-neutral-50);
        transition: background-color 0.15s ease-out, padding 0.3s ease;
        flex: 0 0 auto;
        padding: 2px 0;
        overflow: hidden;
        cursor: default;

        $r: &;

        &_title-wrapper {
            height: 48px;
            flex: 1 1 auto;

            border-radius: var(--joy-core-radius-3);
            align-items: center;
            padding: 0 var(--joy-core-spacing-4);
            padding-left: var(--joy-core-spacing-9);
            line-height: 1;
            cursor: pointer;

            &_notifications-container {
                position: relative;
                display: flex;
                max-width: 100%;
                flex-flow: row nowrap;
                align-items: center;

                span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 100%;
                    display: inline-block;
                    line-height: 1.3;
                }

                .alt-icon {
                    margin-left: 4px;
                }
            }
        }

        &.isReduced {
            color: var(--joy-color-neutral-50);

            #{$r}_title-wrapper {
                padding-left: var(--joy-core-spacing-6);
                padding-right: var(--joy-core-spacing-6);
            }
        }

        &.isShownInADropdown {
            &:not(.isReduced) {
                color: var(--joy-color-neutral-50);

                #{$r}_title-wrapper {
                    padding-left: var(--joy-core-spacing-6);
                    padding-right: var(--joy-core-spacing-6);
                }
            }
        }

        &.isActive {
            #{$r}_title-wrapper {
                background-color: var(--joy-color-secondary-10);
                font-weight: bold;
            }
        }

        &.isFull {
            #{$r}_title-wrapper {
                font-size: var(--joy-font-size-primary-300);
            }
        }

        &:not(.isReduced) {
            border-radius: 0px !important;
        }

        &:not(.isActive) {
            #{$r}_title-wrapper:hover {
                background-color: v-bind(hoverColor);
            }
        }
    }
</style>
