<template>
    <FieldAutocomplete
        ref="autocompleteRef"
        v-model:focused="isLocationFocused"
        v-model:search-value="locationQuery"
        :suggestions="formatedSuggestions"
        name="location-search"
        :placeholder="t('navbar.header.search.form.location.label')"
        data-testid="header_search-form__location-input"
        :ghost-value="remoteCountrySuggestion.formattedAddress"
        :formatter="(value: LocationItem) => value.formattedAddress ?? ''"
        @input="handleInput"
        @select="handleSelect"
    >
        <template #suggestion="{remoteAllowed, formattedAddress}: any">
            <VJoyIcon v-if="remoteAllowed" name="home" style="margin-right: 10px" />
            <span>{{ formattedAddress }}</span>
        </template>
    </FieldAutocomplete>
</template>

<script setup lang="ts">
    import {onBeforeUnmount, onMounted, ref, useTranslation, navbarEventBus} from '#imports';
    import {storeToRefs} from 'pinia';
    import {useLocationAutocomplete} from '../../composables';
    import {useSearchForm} from '../../search.store';
    import FieldAutocomplete from './FieldAutocomplete.vue';
    import {useHeaderMenu} from '@navbar-logged/features/header-menu';
    import type {LocationItem} from '../../search.types';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const {t} = useTranslation();

    const autocompleteRef = ref<InstanceType<typeof FieldAutocomplete>>();

    const headerMenuStore = useHeaderMenu();
    const searchFormStore = useSearchForm();
    const {locationQuery, isLocationFocused, fullLocationSuggestion} = storeToRefs(searchFormStore);

    const {formatedSuggestions, getSuggestions, setSelectedValue, remoteCountrySuggestion} = useLocationAutocomplete();

    function handleSelect(value: LocationItem | null) {
        setSelectedValue(value);
        fullLocationSuggestion.value = value;
    }

    function handleInput(value: string | null) {
        if (!value) {
            fullLocationSuggestion.value = null;
        } else {
            fullLocationSuggestion.value = {formattedAddress: value};
        }

        getSuggestions(value);
    }

    function focusField() {
        autocompleteRef.value?.focus();
    }

    onMounted(() => {
        if (headerMenuStore.searchPreviewTarget === 'LOCATION') {
            focusField();
        }
        navbarEventBus.on('focus-search-location-input', focusField);
    });

    onBeforeUnmount(() => {
        navbarEventBus.off('focus-search-location-input', focusField);
    });
</script>
