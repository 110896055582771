<template>
    <div
        v-if="parentDropdownActive"
        class="menu-item__children joy-core-elevation-4"
        @mouseleave="setActiveParent(false)"
        @mouseenter="setActiveParent(true)"
    >
        <div
            v-for="(child, index) in tab?.children"
            :key="index"
            :class="{
                'menu-item__child': true,
                'joy-font-primary-300': true,
                'menu-item__child--isDisabled': child.isDisabled?.(),
            }"
            v-bind="child.customAttrs"
            @mouseenter="setActiveChild(index, true)"
            @mouseleave="setActiveChild(index, false)"
            @click="child.url ? redirectTo(child.url) : child.action?.()"
        >
            <IconRenderer :icon="child.icons?.mainIcon" :name="child.title" :size="18" :active="getActiveChild(index)" />
            <span>
                {{ child.title }}
            </span>
        </div>
    </div>
    <Component
        :is="getComponentRoot"
        class="menu-item flex"
        v-bind="getComputedAttrs"
        :alt="getComponentRoot === 'a' ? getTitle : undefined"
        :aria-label="getComponentRoot === 'a' ? getTitle : undefined"
        :aria-description="getComponentRoot !== 'a' ? getTitle : undefined"
        :data-testid="tab?.id"
        :class="{isActive}"
        @click="emit('click')"
        @mouseenter="tab?.children && setActiveParent(true)"
        @mouseleave="tab?.children && setActiveParent(false)"
    >
        <div ref="menuItemIcon" class="menu-item_icon-wrapper" :class="{isActive}">
            <IconRenderer :icon="getIcon" :active="isActive || isHovering || parentDropdownActive" :name="tab?.id ?? getTitle" :size="size" />
            <div v-if="getNotificationCount" class="notifications-container">
                <NotificationBadge :count="getNotificationCount" :offset="0" />
            </div>
        </div>
        <span v-if="showTitle && !isDisplayMobile" class="menu-item_title">{{ getTitle }}</span>
    </Component>
    <GetStartedOnMaltProductTour v-if="showGetStartedOnMaltProductTour" @close="parentDropdownActive = false" />
</template>

<script setup lang="ts">
    import {useMouseHover, useDisplayMobile} from '#malt/nuxt-utils-module';
    import {IconRenderer} from '@navbar-logged/common/ui';
    import type {NavigationTab, NavigationTabAction} from '@navbar-logged/features/aside-menu';
    import {useAsideMenu} from '@navbar-logged/features/aside-menu';
    import {NotificationBadge} from '@navbar-logged/features/notifications';
    import type {AnchorHTMLAttributes, PropType} from 'vue';
    import {computed, ref, onMounted, onUnmounted, watch} from 'vue';
    import {navbarEventBus} from '@navbar/events';
    import GetStartedOnMaltProductTour from './GetStartedOnMaltProductTour.vue';

    const props = defineProps({
        tab: {type: Object as PropType<NavigationTab>},
        icon: String,
        title: String,
        showTitle: {type: Boolean, default: false},
        size: {type: Number, default: 20},
    });

    const emit = defineEmits<{
        (e: 'click'): void;
    }>();

    const showGetStartedOnMaltProductTour = ref<boolean>(false);

    onMounted(() => {
        navbarEventBus.on('request:navbar-header-get-started-on-malt-onboarding', () => {
            if (localStorage.getItem('GetStartedOnMaltOnboardingAlreadySeenOnce')) {
                return;
            }
            parentDropdownActive.value = true;
            showGetStartedOnMaltProductTour.value = true;
            setTimeout(() => localStorage.setItem('GetStartedOnMaltOnboardingAlreadySeenOnce', 'true'));
        });
    });

    const asideMenuStore = useAsideMenu();
    const {isDisplayMobile} = useDisplayMobile();
    const getIcon = computed(() => (props.tab?.icons?.mainIcon ?? props.icon)!);
    const getTitle = computed(() => (props.tab?.title ?? props.title)!);

    const menuItemIcon = ref<HTMLElement | null>(null);
    const {isHovering} = useMouseHover({customRef: menuItemIcon});

    const getComponentRoot = computed<'div' | 'a'>(() => {
        if (props.tab?.url?.href) {
            return 'a';
        }
        return 'div';
    });

    const isActive = computed(() => !!props.tab?.id && asideMenuStore.currentActiveRoute === props.tab.id);

    const getNotificationCount = computed(() => props.tab?.notificationsCount);

    const getComputedAttrs = computed<Partial<AnchorHTMLAttributes>>(() => {
        if (props.tab?.url?.href) {
            return {
                href: props.tab.url.href,
                ...(props.tab.url.blank && {target: '_blank'}),
            };
        }
        return {};
    });

    const parentDropdownActive = ref<boolean>(false);
    const childDropdownActive = ref<Array<boolean>>([]);

    function setActiveParent(value: boolean) {
        parentDropdownActive.value = value;
    }
    function setActiveChild(index: number, value: boolean) {
        childDropdownActive.value[index] = value;
    }
    function getActiveChild(index: number) {
        return childDropdownActive.value[index];
    }
    function redirectTo(url: NavigationTabAction) {
        if (url.blank) {
            window.open(url.href);
        } else {
            window.location.href = url.href;
        }
    }

    onUnmounted(() => navbarEventBus.off('request:navbar-header-get-started-on-malt-onboarding'));
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .menu-item {
        flex-flow: column nowrap;
        margin-top: var(--header-menu-item-margin-top);
        position: relative;
        padding: 0 var(--joy-core-spacing-2);
        align-items: center;
        justify-content: center;
        text-decoration: none;
        cursor: pointer;
        $p: &;

        &_icon-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            min-width: 40px;
            height: 40px;
            flex: 0 0 auto;

            joy-icon {
                color: var(--joy-color-neutral-60);
                cursor: pointer;

                &.isActive {
                    color: var(--joy-color-secondary-30);
                }
            }

            .notifications-container {
                position: absolute;
                width: 100%;
                height: 100%;
                inset: 0;
            }
        }

        &:hover {
            #{$p}_icon-wrapper:not(.isActive) {
                background-color: var(--joy-color-neutral-20);
            }
        }

        &.isActive {
            #{$p}_icon-wrapper {
                background-color: var(--joy-color-secondary-10);
            }
            font-weight: bold;
        }

        &_title {
            font-size: var(--joy-font-size-primary-100);
            color: var(--joy-color-neutral-60);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            letter-spacing: -0.2px;
        }

        &__children {
            overflow: hidden;
            display: flex;
            flex-direction: column;
            position: absolute;
            right: var(--joy-core-spacing-2);
            top: var(--joy-core-spacing-9);
            width: 200px;
            height: auto;
            max-width: unset;
            z-index: var(--header-z-index);
            background: white;
            border-radius: var(--joy-core-radius-3, 8px);

            &::before {
                content: '';
                position: absolute;
                top: -15px;
                left: 0;
                width: 100%;
                height: 15px;
            }

            @include mq.screen_xs {
                top: var(--joy-core-spacing-9);
            }
        }

        &__child {
            padding: var(--joy-core-spacing-4);
            display: flex;
            gap: var(--joy-core-spacing-2);
            color: var(--joy-color-neutral-60);
            cursor: pointer;

            &:hover {
                background-color: var(--joy-color-neutral-20);
            }

            &--isDisabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
</style>
