/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { UserIdentity } from './';
import {
    UserIdentityFromJSON,
    UserIdentityFromJSONTyped,
    UserIdentityToJSON,
} from './';
import type { UserOptions } from './';
import {
    UserOptionsFromJSON,
    UserOptionsFromJSONTyped,
    UserOptionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserResource
 */
export interface UserResource {
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    selectedIdentityId: string;
    /**
     * 
     * @type {Array<UserIdentity>}
     * @memberof UserResource
     */
    identities: Array<UserIdentity>;
    /**
     * 
     * @type {UserOptions}
     * @memberof UserResource
     */
    options: UserOptions;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof UserResource
     */
    featureFlags: { [key: string]: boolean; };
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    currentCountry: string;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    currentLanguage: string;
    /**
     * 
     * @type {string}
     * @memberof UserResource
     */
    insightsBaseUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserResource
     */
    punchoutSessionActive?: boolean;
}

/**
 * Check if a given object implements the UserResource interface.
 */
export function instanceOfUserResource(value: object): value is UserResource {
    if (!('selectedIdentityId' in value) || value['selectedIdentityId'] === undefined) return false;
    if (!('identities' in value) || value['identities'] === undefined) return false;
    if (!('options' in value) || value['options'] === undefined) return false;
    if (!('featureFlags' in value) || value['featureFlags'] === undefined) return false;
    if (!('currentCountry' in value) || value['currentCountry'] === undefined) return false;
    if (!('currentLanguage' in value) || value['currentLanguage'] === undefined) return false;
    if (!('insightsBaseUrl' in value) || value['insightsBaseUrl'] === undefined) return false;
    return true;
}

export function UserResourceFromJSON(json: any): UserResource {
    return UserResourceFromJSONTyped(json, false);
}

export function UserResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResource {
    if (json == null) {
        return json;
    }
    return {
        
        'selectedIdentityId': json['selectedIdentityId'],
        'identities': ((json['identities'] as Array<any>).map(UserIdentityFromJSON)),
        'options': UserOptionsFromJSON(json['options']),
        'featureFlags': json['featureFlags'],
        'currentCountry': json['currentCountry'],
        'currentLanguage': json['currentLanguage'],
        'insightsBaseUrl': json['insightsBaseUrl'],
        'punchoutSessionActive': json['punchoutSessionActive'] == null ? undefined : json['punchoutSessionActive'],
    };
}

export function UserResourceToJSON(value?: UserResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'selectedIdentityId': value['selectedIdentityId'],
        'identities': ((value['identities'] as Array<any>).map((v) => UserIdentityToJSON(v))),
        'options': UserOptionsToJSON(value['options']),
        'featureFlags': value['featureFlags'],
        'currentCountry': value['currentCountry'],
        'currentLanguage': value['currentLanguage'],
        'insightsBaseUrl': value['insightsBaseUrl'],
        'punchoutSessionActive': value['punchoutSessionActive'],
    };
}

