<template>
    <div class="language language-choice / flex" :class="{isSelected}" :data-testid="getCode" @click="handleLanguageSelect">
        <span class="language-choice_label">{{ getLabel }}</span>
    </div>
</template>

<script setup lang="ts">
    import type {Language} from '../language-selector.types';
    import {useLanguageSelector} from '../language-selector.store';
    import {storeToRefs} from 'pinia';
    import type {PropType} from 'vue';
    import {computed} from 'vue';
    import capitalize from 'lodash-es/capitalize';

    const languageSelectorStore = useLanguageSelector();
    const {selectedLanguage, temporarySelectedLanguage} = storeToRefs(languageSelectorStore);

    const props = defineProps({
        language: {type: Object as PropType<Language>, required: true},
    });

    const getCode = computed(() => `language-${props.language.locale}`);

    const isSelected = computed(() => selectedLanguage.value?.locale === props.language.locale);

    const getLabel = computed(() => `${capitalize(props.language.label)} (${props.language.language.toUpperCase()})`);

    function handleLanguageSelect() {
        temporarySelectedLanguage.value = props.language;
    }
</script>

<style lang="scss" scoped>
    @import './language-common.scss';
</style>
