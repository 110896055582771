import {
    Configuration,
    FreelancerAvailabilityApi,
    FreelancerCompanyApi,
    FreelancerProfilePreferencesApi,
    NavbarApi,
    ClientSalesManagerContactApi,
} from '@navbar-api';

const configuration = new Configuration({basePath: ''});

// - APIs

export const NavbarContext = new NavbarApi(configuration);
export const FreelanceContext = new FreelancerAvailabilityApi(configuration);
export const FreelanceProfilePreferencesContext = new FreelancerProfilePreferencesApi(configuration);
export const FreelanceCompanyContext = new FreelancerCompanyApi(configuration);
export const ClientSalesManagerContact = new ClientSalesManagerContactApi(configuration);
