import type {ComponentInternalInstance} from 'vue';
import {getCurrentInstance} from 'vue';

/**
 * Shortcut for accessing vue internals properties using `getCurrentInstance`
 * ### ⚠️ Only use inside `setup` function or in a composable
 *
 * @usage
 *
 *  ```vue
 *  <script setup lang='ts'>
 *     const {proxy, parent} = useVueInternals();
 *  </script>
 *  ```
 */
export function useVueInternals(): ComponentInternalInstance {
    const app = getCurrentInstance();
    if (app) {
        return app;
    }
    throw new Error('Only use "useVueInternals" inside setup function or in a composable');
}
