<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                    <path
                        d="m12.5707 14.368 2.7398 2.7367c.4432.4427 1.1584.4427 1.6016 0s.4432-1.157 0-1.5998l-3.9385-3.9642-.3828-.3924c.1108-.161.2116-.3119.3022-.4829 1.4304-2.48522 1.0778-5.70489-1.0375-7.82787-2.53829-2.535491-6.65805-2.535491-9.19638 0-2.538332 2.5355-2.538332 6.65065 0 9.18617 2.22608 2.2236 5.65081 2.4952 8.17908.8351"
                    />
                    <path
                        d="m7.25237 11.4804c2.23634 0 4.04923-1.81086 4.04923-4.04469 0-2.23384-1.81289-4.04472-4.04923-4.04472s-4.04925 1.81088-4.04925 4.04472c0 2.23383 1.81291 4.04469 4.04925 4.04469z"
                    />
                </g>
            </svg>
        </template>
        <template #active>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m12.5707 14.368 2.7398 2.7367c.4432.4427 1.1584.4427 1.6016 0s.4432-1.157 0-1.5998l-3.9385-3.9642-.3828-.3924c.1108-.161.2116-.3119.3022-.4829 1.4304-2.48522 1.0778-5.70489-1.0375-7.82787-2.53829-2.535491-6.65805-2.535491-9.19638 0-2.538332 2.5355-2.538332 6.65065 0 9.18617 2.22608 2.2236 5.65081 2.4952 8.17908.8351"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path
                    d="m7.25231 2.73694c-2.59877 0-4.70397 2.11291-4.70397 4.69871 0 2.58585 2.11528 4.69875 4.70397 4.69875 2.5887 0 4.70399-2.1029 4.70399-4.69875 0-2.59586-2.10522-4.69871-4.70399-4.69871zm2.96139 5.28228c-.0302.10061-.74538 2.39468-2.96139 2.39468-1.83324 0-2.74986-1.54952-2.97146-2.3645-.07051-.27166.08058-.54332.35255-.61375.26189-.07043.54393.0805.61444.35216.02014.07043.48349 1.61989 1.9944 1.61989s1.98433-1.60983 2.00448-1.6702c.08058-.2616.36262-.41252.62451-.33203.26187.08049.41297.36221.33237.62381z"
                    fill="currentColor"
                />
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
