import {createEventBus} from '@malt/vue-event-bus/create-event-bus';
import {} from '../logged/features/aside-menu/aside-menu.types';

export type FocusSearchJobInputParams = {enableZoom?: boolean};

type Events = {
    'focus-search-location-input': undefined;
    'focus-search-job-input': FocusSearchJobInputParams | undefined;
    'navbar-search-form-submit': undefined;
    'open-availibility-dialog': {source: string};
    'expand-client-onboarding-walkthrough': undefined;
    /**
     * From any application, use this event to programmatically reduce the desktop navigation
     */
    'request:navbar-desktop-reduced': undefined;
    'request:navbar-desktop-full': undefined;
    'request:navbar-desktop-full-with-tab-expanded': {tab: string};
    'request:navbar-header-get-started-on-malt-onboarding': undefined;

    'availability-navbar:update': undefined;
    'navbar-logged:mounted': undefined;
};

export const navbarEventBus = createEventBus<Events>();
