<template>
    <div class="skeleton-container flex" :class="[displayMode]">
        <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <div class="separator"></div>
            <Skeleton />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {Skeleton} from '@navbar-logged/common/ui';
    import {storeToRefs} from 'pinia';
    import {useDisplayMode} from '../stores';

    const displayModeStore = useDisplayMode();

    const {displayMode} = storeToRefs(displayModeStore);
</script>

<style lang="scss" scoped>
    .skeleton-container {
        width: 100%;
        padding: 0 20px;
        padding-bottom: 16px;
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;

        .separator {
            height: 1px;
            background-color: #e0ded9;
            margin-top: 28px;
            margin-bottom: 28px;
        }

        :deep(.skeleton) {
            &:last-of-type {
                margin-top: auto;
            }
        }

        &.FULL {
            &:deep(.skeleton) {
                height: 44px;
                margin: 10px auto;

                &:first-of-type {
                    margin-top: 16px;
                }
            }
            .separator {
                margin-top: 20px;
            }
        }

        &.REDUCED {
            :deep(.skeleton) {
                height: 24px;
                width: 24px;
                margin-bottom: 24px;
            }
        }
    }
</style>
