<template>
    <VJoyButton v-if="searchFormVisible && userStore.hasSelectedClientIdentity" variant="ghost" style="margin-left: 32px" @click="goToPostAProject">
        {{ $t('navbar.aside.tabs.title.post.job.offer') }}
    </VJoyButton>
</template>
<script setup lang="ts">
    import {computed} from 'vue';
    import {storeToRefs} from 'pinia';
    import {useDisplayMobile} from '#malt/nuxt-utils-module';
    import {SearchDisplayMode} from '@navbar/features/search/search.types';
    import {useUser} from '@navbar/logged/features/user';
    import {useHeaderMenu} from '@navbar/logged/features/header-menu';
    import {VJoyDropdown, VJoyIcon, VJoyButton} from '@maltjoy/core-vue';

    const userStore = useUser();
    const headerMenuStore = useHeaderMenu();
    const {searchDisplayMode} = storeToRefs(headerMenuStore);

    const {isDisplayMobile} = useDisplayMobile();
    const searchFormVisible = computed(() => searchDisplayMode.value === SearchDisplayMode.FORM && !isDisplayMobile.value);

    function goToPostAProject() {
        window.location.href = '/s/advanced?brief_origin=top_nav_global';
    }
</script>
<style lang="scss" scoped>
    .header-search-brief-entries {
        margin-left: var(--joy-core-spacing-8);
        &__link {
            display: flex;
            text-decoration: none !important;
            align-items: baseline;
            gap: var(--joy-core-spacing-2);
        }
    }
</style>
