/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeStateRequest
 */
export interface ChangeStateRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeStateRequest
     */
    state: ChangeStateRequestStateEnum;
}


/**
 * @export
 */
export const ChangeStateRequestStateEnum = {
    Full: 'FULL',
    Reduced: 'REDUCED',
    Funnel: 'FUNNEL'
} as const;
export type ChangeStateRequestStateEnum = typeof ChangeStateRequestStateEnum[keyof typeof ChangeStateRequestStateEnum];


/**
 * Check if a given object implements the ChangeStateRequest interface.
 */
export function instanceOfChangeStateRequest(value: object): value is ChangeStateRequest {
    if (!('state' in value) || value['state'] === undefined) return false;
    return true;
}

export function ChangeStateRequestFromJSON(json: any): ChangeStateRequest {
    return ChangeStateRequestFromJSONTyped(json, false);
}

export function ChangeStateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeStateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'state': json['state'],
    };
}

export function ChangeStateRequestToJSON(value?: ChangeStateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'state': value['state'],
    };
}

