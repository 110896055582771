/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ServiceLevelType = {
    Standard: 'STANDARD',
    Advanced: 'ADVANCED',
    Corporate: 'CORPORATE'
} as const;
export type ServiceLevelType = typeof ServiceLevelType[keyof typeof ServiceLevelType];


export function instanceOfServiceLevelType(value: any): boolean {
    for (const key in ServiceLevelType) {
        if (Object.prototype.hasOwnProperty.call(ServiceLevelType, key)) {
            // @ts-ignore
            if (ServiceLevelType[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ServiceLevelTypeFromJSON(json: any): ServiceLevelType {
    return ServiceLevelTypeFromJSONTyped(json, false);
}

export function ServiceLevelTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLevelType {
    return json as ServiceLevelType;
}

export function ServiceLevelTypeToJSON(value?: ServiceLevelType | null): any {
    return value as any;
}

