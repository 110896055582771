<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                    <path
                        d="m.811279.985107h6.230771c.22716 0 .42187.179893.42187.412703v12.12699c0 .2222-.18389.4127-.42187.4127h-5.8089c-.22716 0-.421871-.1799-.421871-.4127v-9.98943"
                    />
                    <path
                        d="m17.1887 17.3978h-6.4687c-.2272 0-.4219-.1799-.4219-.4127v-9.72487c0-.22222.1839-.4127.4219-.4127h6.0468c.2272 0 .4219.1799.4219.4127v7.64017"
                    />
                    <path d="m10.3088.985107h6.8798" />
                    <path d="m10.3088 3.75757h6.8798" />
                    <path d="m.811279 17.3977h6.652641" />
                </g>
            </svg>
        </template>
        <template #active>
            <svg fill="none" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                    <path
                        d="m.811279.985107h6.230771c.22716 0 .42187.179893.42187.412703v10.51849c0 .2222-.18389.4127-.42187.4127h-5.8089c-.22716 0-.421871-.1799-.421871-.4127v-8.38093"
                    />
                    <path d="m10.3088.985107h6.8798" />
                    <path d="m10.3088 3.75757h6.8798" />
                </g>
                <path
                    d="m16.7776 6.05383h-6.0469c-.6815 0-1.23314.53969-1.23314 1.20635v9.72492c0 .6666.55164 1.2063 1.23314 1.2063h6.0469c.6815 0 1.2332-.5397 1.2332-1.2063v-9.72492c0-.66666-.5517-1.20635-1.2332-1.20635z"
                    fill="currentColor"
                />
                <path
                    d="m.811279 15.1438s.681491 2.2434 3.353361 2.2434c2.67188 0 3.29928-2.2434 3.29928-2.2434"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
