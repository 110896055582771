<template>
    <div class="punchout-dialog" data-testid="punchout-dialog">
        <VJoyPanel padding="large">
            <template #panel-title>
                {{ content.title }}
            </template>
            <template v-if="content.subtitle" #panel-subtitle>
                <span class="punchout-dialog__subtitle">
                    {{ content.subtitle }}
                </span>
            </template>
            <template #panel-body>
                <VJoyHighlight :display-icon="true" :level="isSessionActive ? 'success' : 'warning'">
                    <template #default>
                        {{ content.description }}
                    </template>
                </VJoyHighlight>
                <VJoyButton
                    v-if="content.cta"
                    class="punchout-dialog__cta"
                    :label="content.cta"
                    size="small"
                    variant="main"
                    :href="`//${punchoutDetails?.procurementAppUrl}`"
                    target="_blank"
                    data-testid="punchout-dialog-cta"
                />
            </template>
        </VJoyPanel>
    </div>
</template>

<script setup lang="ts">
    import {clickOutsideSelector} from '@malt/dom';
    import {useTranslation} from '#imports';
    import type {PropType} from 'vue';
    import {computed, onBeforeUnmount, onMounted, ref} from 'vue';
    import type {PunchoutDetails} from '@navbar-api';
    import {VJoyButton, VJoyHighlight, VJoyPanel} from '@maltjoy/core-vue';

    interface DialogContent {
        title: string;
        subtitle?: string;
        description: string;
        cta?: string;
    }

    const props = defineProps({
        punchoutDetails: {
            type: Object as PropType<PunchoutDetails>,
        },
        isSessionActive: {type: Boolean},
    });

    const {t} = useTranslation();
    const emit = defineEmits<{(e: 'dialogClosed'): void}>();

    const baseKey = `navbar.punchout.badge` as const;
    const appName = computed(() => props.punchoutDetails?.procurementAppName);

    const content = ref<DialogContent>(
        props.isSessionActive
            ? {
                  title: t(`${baseKey}.connected.title`, {0: appName.value}),
                  description: t(`${baseKey}.connected.content`, {0: appName.value, 1: appName.value}),
              }
            : {
                  title: t(`${baseKey}.disconnected.title`, {0: appName.value}),
                  subtitle: t(`${baseKey}.disconnected.subtitle`, {0: appName.value}),
                  description: t(`${baseKey}.disconnected.content`),
                  cta: t(`${baseKey}.disconnected.cta`, {0: appName.value}),
              },
    );

    function handleClick(e: Event) {
        if (clickOutsideSelector(e, `.punchout-dialog, .punchout-header`)) {
            emit('dialogClosed');
        }
    }

    onMounted(() => {
        document.addEventListener('click', handleClick);
    });

    onBeforeUnmount(() => {
        document.removeEventListener('click', handleClick);
    });
</script>

<style lang="scss" scoped>
    .punchout-dialog {
        --panel-bottom-margin: 0px;
        position: absolute;
        min-width: 530px;
        right: var(--joy-core-spacing-4);
        top: calc(var(--header-menu-height) - var(--joy-core-spacing-2));

        &__cta.joy-button {
            margin-top: var(--joy-core-spacing-8);
        }

        &__subtitle {
            font-size: var(--joy-font-size-primary-400);
        }

        .joy-panel {
            border: none;
            box-shadow: var(--joy-core-elevation-4);
        }
    }
</style>
