import type {AtLeastOne} from '@malt/types-utils';
import type {Component, StyleValue} from 'vue';
import type {RouteResolversFn} from '@navbar/common/composables';

export enum NavBarWidth {
    Full = 264,
    Reduced = 72,
    Mobile = 340,
}

export type NavigationTabIcons = {
    /**
     *  icon path for the tab
     *  Path is already prefixed with `https://dam.malt.com/navbar/icons/`
     *  Do not put the `.svg` file extension
     *  Don't forget to change svg color to currentColor
     * ex:
     * ```js
     * icon: 'aside/analytics'
     * ```
     *
     * The active variant must be named `${iconName}_active.svg`
     */
    mainIcon?: Component | string;
    /**
     *  Secondary icon for the tab that will display at the right of the title
     *
     * ex:
     * ```js
     * icon: 'misc/insight'
     * ```
     * Used for Insight icon in admin tabs for now
     */
    altIcon?: Component | string;
};

export type NavigationTab<T = string> = {
    /** Title dislayed on the NavBar */
    title: string;
    /** Identifier of the tab */
    id: T;
    /** Filter for displaying the tab */
    constraint?: boolean;
    icons?: NavigationTabIcons;
    customAttrs?: Record<string, string>;
    isDisabled?: () => boolean;
    /**
     * Provide a custom background-color for the tab
     * Ex: disconnect button
     *
     * @default 'var(--joy-color-neutral-20)'
     * @warn only for aside tabs
     */
    customHoverColor?: string;
    /**
     * Show title for tabs that have children on hover
     * @default false
     * @warn only for aside tabs
     */
    showTitleOnHover?: boolean;
    /**
     * Show children in a dropdown
     * @default false
     * @warn only for aside tabs
     */
    showChildrenInADropdown?: boolean;
    /**
     * If empty, the tab is not collapsable, if not, it can be collapsed and show it's children
     * @warn only for aside tabs
     */
    children?: NavigationTab<T>[];
    /**
     * Allow to regroup 2 tabs in the same container without displaying children
     * Used for product tour
     */
    ghostChildren?: NavigationTab<T>[];
    /* Provide each route with a strategy to know if the route is active */
    isActiveWhen?: (resolvers: RouteResolversFn) => boolean;
    /** Add custom style to the tab */
    customStyle?: StyleValue;
    /**
     * Add a separator below tab
     * @warn only for aside tabs
     */
    separator?: 'top' | 'bottom';
    /**
     * Add a border around tab
     * @warn only for aside tabs
     */
    border?: boolean;
    /** Notifications count for this tab */
    notificationsCount?: number;

    /**
     * Display tooltip on hover
     * @warn only for header tabs
     * */
    tooltip?: string;
} & AtLeastOne<{
    /** Redirect url action for the tab, can be relative to domain or absolute */
    url?: NavigationTabAction;
    /** Action on click */
    action?: () => void;
}>;

export interface NavigationTabAction {
    href: string;
    /** @default false */
    blank?: boolean;
}
