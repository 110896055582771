import {useNuxtApp} from '#imports';

function getCurrentHost(): string {
    const nuxtApp = useNuxtApp();

    if (import.meta.server) {
        return nuxtApp.ssrContext!.event.node.req.headers.host!;
    }

    return window.location.hostname;
}

function getCurrentDomainExtension(): string {
    const currentHost = getCurrentHost();
    return currentHost?.split('.').pop()!.toLowerCase() || 'frontend';
}

function isCurrentDomainExtensionOneOf(domainExtensions: string[]) {
    return domainExtensions.includes(getCurrentDomainExtension());
}

export default function useCurrentHost() {
    return {
        getCurrentHost,
        getCurrentDomainExtension,
        isCurrentDomainExtensionOneOf,
    };
}
