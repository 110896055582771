<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <clipPath id="a"><path d="m0 .191406h18v18h-18z" /></clipPath>
                <g clip-path="url(#a)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                    <path
                        d="m13.5937 16.0039c-1.3125.8854-2.8958 1.4063-4.5937 1.4063-4.54167 0-8.21875-3.6771-8.21875-8.21879 0-4.54167 3.67708-8.218754 8.21875-8.218754 4.5417 0 8.2188 3.677084 8.2188 8.218754 0 1.86459-.6146 3.57289-1.6563 4.94789"
                    />
                    <path
                        d="m6.052 7.02482c0-1.625 1.32292-2.94792 2.94792-2.94792 1.62498 0 2.94788 1.32292 2.94788 2.94792 0 1.39583-.9583 2.59375-2.2708 2.86458-.72916.1563-.67708.5208-.67708.9375v.8125"
                    />
                    <path d="m9 14.1499v.1563" />
                </g>
            </svg>
        </template>
        <template #active>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <clipPath id="a"><path d="m0 .191406h18v18h-18z" /></clipPath>
                <g clip-path="url(#a)">
                    <path
                        d="m9 .191406c-4.95833 0-9 4.041664-9 9.000004 0 4.95829 4.04167 8.99999 9 8.99999 4.9583 0 9-4.0417 9-8.99999 0-4.95834-4.0312-9.000004-9-9.000004zm.78125 14.114594c0 .4271-.35417.7812-.78125.7812s-.78125-.3541-.78125-.7812v-.1563c0-.427.35417-.7812.78125-.7812s.78125.3542.78125.7812zm.05208-3.6458s-.04166 0-.0625.0104v.0729.8958c0 .4271-.35416.7813-.78125.7813-.42708 0-.78125-.3542-.78125-.7813v-.8125c0-.4791 0-1.42706 1.29167-1.69789.9583-.19792 1.6562-1.08334 1.6562-2.10417 0-1.19792-.9687-2.16667-2.16662-2.16667-1.19791 0-2.16666.96875-2.16666 2.16667 0 .42708-.35417.78125-.78125.78125-.42709 0-.78125-.35417-.78125-.78125 0-2.05208 1.67708-3.72917 3.72916-3.72917 2.05212 0 3.72922 1.67709 3.72922 3.72917 0 1.75-1.2188 3.28126-2.89588 3.63546z"
                        fill="currentColor"
                    />
                </g>
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
