export function getMonths(format = 'long', locale = {}, month) {
    // Create month names
    const monthNames = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((mon) => {
        return new Date(2000, mon).toLocaleString(locale, {month: format});
    });

    // Return month name (or all of them)
    if (month) {
        return monthNames[month - 1];
    }
    return monthNames;
}
