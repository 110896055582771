<template>
    <NavbarLoggedRoot />

    <ClientOnly>
        <NpsBanner />
    </ClientOnly>
</template>

<script setup lang="ts">
    import {nextTick, onMounted, useHead, useNuxtApp} from '#imports';
    import {storeToRefs} from 'pinia';
    import {useDisplayMobile} from '#malt/nuxt-utils-module';
    import {useAppContext} from '@navbar-registry';
    import {useDisplayMode} from './features/aside-menu';
    import {useSearchDisplayMode} from './features/search';
    import {useUser} from './features/user';
    import {useLanguageSelector} from './features/language-selector';
    import NpsBanner from './features/nps/ui/NpsBanner.vue';
    import NavbarLoggedRoot from './NavbarLoggedRoot.vue';
    import {useHeaderMenu} from '@navbar-logged/features/header-menu';

    const {loadAppContext, loadNotifications} = useAppContext();

    const nuxtApp = useNuxtApp();
    // Server-side or client-side for CSR app
    if (import.meta.server || (import.meta.client && !nuxtApp.payload.serverRendered)) {
        await loadAppContext();
    }

    // Client-side for SSR app
    if (import.meta.client && nuxtApp.payload.serverRendered) {
        await loadNotifications();
    }

    const displayModeStore = useDisplayMode();
    const user = useUser();
    const {displayMode, isDisplayModeFunnel} = storeToRefs(displayModeStore);
    const {isDisplayMobile} = useDisplayMobile();
    const languageSelectorStore = useLanguageSelector();
    const {searchDisplayMode} = useSearchDisplayMode();

    useHead({
        htmlAttrs: () => ({
            'display-mode': displayMode.value,
            'display-funnel': isDisplayModeFunnel.value,
            'display-mobile': isDisplayMobile.value,
            'search-mode': searchDisplayMode.value,
            'user-type': user.selectedIdentity?.type,
            lang: languageSelectorStore.htmlLangAttribute,
            'data-nuxt-app': '',
        }),
    });

    const {onClientOnboardingIsPresent} = useHeaderMenu();

    onMounted(() => {
        // Handle useHead bug where html attrs are not set if redirected from another page
        if (!document.documentElement.getAttributeNames().includes('data-nuxt-app')) {
            document.documentElement.setAttribute('display-mode', displayMode.value);
            if (isDisplayModeFunnel.value) {
                document.documentElement.setAttribute('display-funnel', '');
            }
            if (isDisplayMobile.value) {
                document.documentElement.setAttribute('display-mobile', '');
            }
            document.documentElement.setAttribute('search-mode', searchDisplayMode.value);
            document.documentElement.setAttribute('user-type', String(user.selectedIdentity?.type));
            document.documentElement.setAttribute('lang', languageSelectorStore.htmlLangAttribute);
            document.documentElement.setAttribute('data-nuxt-app', '');
        }

        if (import.meta.client) {
            nextTick().then(() => {
                if (document.getElementById('client-onboarding-wrapper')) {
                    onClientOnboardingIsPresent();
                }
            });
        }
    });
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    @include mq.screen_sm(min) {
        :global(html[display-mode='REDUCED']) {
            :root {
                --aside-menu-width: 72px;
                --aside-menu-left: 0px;
                --header-menu-height: 70px;
                --header-menu-item-margin-top: 0px;
                --layout-padding-left: 72px;
                --layout-padding-top: 70px;
                --header-height: 70px !important;
            }
        }

        :global(html[display-mode='FULL']) {
            :root {
                --aside-menu-width: 264px;
                --aside-menu-left: 0px;
                --header-menu-height: 70px;
                --header-menu-item-margin-top: 0px;
                --layout-padding-left: 264px;
                --layout-padding-top: 70px;
                --header-height: 70px !important;
            }
        }
    }

    @include mq.screen_sm {
        :global(html[user-type='CLIENT']) {
            :root {
                --aside-menu-width: 264px;
                --aside-menu-left: -100%;
                --header-menu-item-margin-top: 0px;
                --layout-padding-left: 0px;
            }
        }
        :global(html[user-type='FREELANCE']) {
            :root {
                --aside-menu-width: 264px;
                --aside-menu-left: -100%;
                --header-menu-height: 70px;
                --header-menu-item-margin-top: 0px;
                --layout-padding-left: 0px;
                --layout-padding-top: 70px;
                --header-height: 70px !important;
            }
        }

        :global(html[search-mode='FORM'][user-type='CLIENT']) {
            --header-menu-height: 145px;
            --layout-padding-top: 145px;
            --header-height: 145px !important;
        }
    }

    :global(body) {
        padding-left: var(--layout-padding-left);
        padding-top: var(--layout-padding-top);

        max-width: none !important;

        @include mq.screen_sm {
            padding-left: 0;
        }
    }

    :global(html[display-funnel]) {
        :root {
            --layout-padding-left: 0px !important;
            --header-menu-height: 70px !important;
            --layout-padding-top: 70px !important;
        }
    }

    :global(#navbar-app) {
        font-size: var(--joy-font-size-primary-400);
        font-family: var(--joy-font-family);
    }
    :global(*.flex) {
        display: flex;
        flex-flow: row wrap;
    }

    :global(*.grid) {
        display: grid;
    }

    :global(*.flex),
    :global(*.grid) {
        max-width: 100%;
        min-width: 0;
        position: relative;

        & > * {
            max-width: 100%;
            min-width: 0;
            position: relative;
        }
    }

    :global(*) {
        box-sizing: border-box;
    }

    :global(button) {
        border: none;
        appearance: none;
        background: none;
        margin: 0;
        padding: 0;
    }
</style>
