<template>
    <div class="header-search-preview">
        <button class="header-search-preview_btn header-search-preview_btn-job" @click.stop="openSearchForm('JOB')">
            <span class="header-search-preview_label">
                {{ jobLabel }}
            </span>
            <span class="header-search-preview_icon">
                <VJoyIcon name="search" color="white" role="button" />
            </span>
        </button>
        <div class="header-search-preview_spacer"></div>
        <button type="button" class="header-search-preview_btn header-search-preview_btn-loc" @click.stop="openSearchForm('LOCATION')">
            <span class="header-search-preview_label">{{ locationLabel }}</span>
            <span class="header-search-preview_icon">
                <VJoyIcon name="search" color="white"></VJoyIcon>
            </span>
        </button>
    </div>
</template>

<script setup lang="ts">
    import {onMounted} from 'vue';
    import {computed, useTranslation, navbarEventBus} from '#imports';
    import type {SearchPreviewTarget} from '../search.types';
    import {useSearchForm} from '../search.store';
    import {storeToRefs} from 'pinia';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const emit = defineEmits<{(e: 'openSearchForm', target: SearchPreviewTarget): void}>();

    const {t} = useTranslation();

    const searchFormStore = useSearchForm();
    const {jobQuery, locationQuery} = storeToRefs(searchFormStore);

    const jobLabel = computed(() => jobQuery.value || t('navbar.header.search.form.job.label'));
    const locationLabel = computed(() => locationQuery.value || t('navbar.header.search.form.location.label'));

    function openSearchForm(target: SearchPreviewTarget) {
        emit('openSearchForm', target);
    }
    onMounted(() => {
        navbarEventBus.on('focus-search-job-input', () => openSearchForm('JOB'));
    });
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .header-search-preview {
        font-size: var(--joy-font-size-primary-300);
        display: flex;
        align-items: center;
        border: 1px solid var(--joy-color-neutral-30);
        height: 48px;
        border-radius: 48px;
        box-shadow: var(--joy-core-elevation-1);

        &:hover {
            box-shadow: var(--joy-core-elevation-2);
        }

        &_btn {
            appearance: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 0 1 auto;
            border: 0;
            text-decoration: none;
            outline: none;
            height: 100%;
            padding-left: var(--joy-core-spacing-5);
            overflow: hidden;
            color: var(--joy-color-neutral-40);

            &-job {
                border-top-left-radius: inherit;
                border-bottom-left-radius: inherit;
                padding-right: var(--joy-core-spacing-5);

                .header-search-preview_icon {
                    display: none;
                }

                @include mq.screen_sm {
                    width: 100%;
                    border-top-right-radius: inherit;
                    border-bottom-right-radius: inherit;
                    padding-right: 0;

                    .header-search-preview_icon {
                        display: flex;
                    }
                }
            }

            &-loc {
                border-top-right-radius: inherit;
                border-bottom-right-radius: inherit;

                @include mq.screen_sm {
                    display: none;
                }
            }
        }

        &_spacer {
            width: 1px;
            height: 28px;
            background-color: var(--joy-color-neutral-30);

            @include mq.screen_sm {
                display: none;
            }
        }

        &_label {
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex: 1;
            overflow: hidden;
        }

        &_icon {
            background-color: var(--joy-color-primary-50);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 41px;
            height: 37px;
            border-radius: 80px;
            margin-right: var(--joy-core-spacing-1);
            margin-left: var(--joy-core-spacing-5);
        }

        @include mq.screen_sm {
            flex: 0 1 342px;
        }
    }
</style>
