import {ref} from 'vue';
import {defineStore} from 'pinia';

export interface ProfilePreferences {
    searchVisibilityChoice: boolean;
}

export const useProfilePreferences = defineStore('navbar-profile-preferences', () => {
    const preferences = ref<ProfilePreferences>({
        searchVisibilityChoice: false,
    });

    return {
        preferences,
    };
});
