<template>
    <div id="punchoutHeader" class="punchout-header">
        <Transition name="fade-scale">
            <PunchoutDialog
                v-if="punchoutDetails && punchoutDialogOpen"
                :punchout-details="punchoutDetails"
                :is-session-active="isSessionActive"
                @dialog-closed="handleDialogClosed"
            />
        </Transition>
        <VJoyAvailability
            class="punchout-badge"
            data-testid="navbar-punchout"
            :status="status"
            :label="punchoutDetails?.procurementAppName"
            @click="onClick"
        ></VJoyAvailability>
    </div>
</template>

<script setup lang="ts">
    import {computed, ref} from 'vue';
    import PunchoutDialog from './PunchoutDialog.vue';
    import {useUser} from '@navbar-logged/features/user';
    import {AvailabilityResourceValueEnum} from '@navbar-api';
    import {VJoyAvailability} from '@maltjoy/core-vue';

    const userStore = useUser();

    const punchoutDetails = computed(() => userStore.selectedIdentity?.punchoutDetails);
    const isSessionActive = computed(() => userStore.user?.punchoutSessionActive);

    const status = ref<AvailabilityResourceValueEnum>(
        isSessionActive.value ? AvailabilityResourceValueEnum.AvailableAndVerified : AvailabilityResourceValueEnum.NotAvailable,
    );

    const punchoutDialogOpen = ref<Boolean>(false);

    function onClick() {
        punchoutDialogOpen.value = !punchoutDialogOpen.value;
    }

    function handleDialogClosed() {
        punchoutDialogOpen.value = false;
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .punchout-header {
        position: relative;
        padding: 0 var(--joy-core-spacing-4);

        .punchout-badge {
            margin-left: auto;
            cursor: pointer;
        }

        @include mq.screen_sm {
            display: none;
        }

        &:hover {
            .joy-availability {
                background-color: var(--joy-color-neutral-20);
            }
        }
    }
</style>
