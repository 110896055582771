import {reactive, ref} from 'vue';
import {defineStore} from 'pinia';
import type {NavbarStateResource} from '@navbar-api';
import {NavbarStateResourceNavbarDisplayModeEnum} from '@navbar-api';

export const useNavbarState = defineStore('navbar-state', () => {
    const pending = ref(false);

    const isEnforcedIdentity = ref(false);
    const state = reactive<NavbarStateResource>({
        navbarDisplayMode: NavbarStateResourceNavbarDisplayModeEnum.Full,
        isBusinessReferralOnboardingAcknowledged: false,
    });

    function updateNavbarDisplayMode(newNavbarDisplayMode: NavbarStateResourceNavbarDisplayModeEnum): void {
        state.navbarDisplayMode = newNavbarDisplayMode;
    }

    return {
        pending,
        state,
        isEnforcedIdentity,
        updateNavbarDisplayMode,
    };
});
