import {defineStore, storeToRefs} from 'pinia';
import {computed, ref} from 'vue';
import type {Language, LanguageConfig} from './language-selector.types';
import {CountryCode, LanguageName} from './language-selector.types';
import {useUser} from '@navbar-logged/features/user';
import {createUniversalFetch, useLogger, useRoute} from '#imports';

const universalFetch = createUniversalFetch({baseURL: '/navbar'});

export const useLanguageSelector = defineStore('navbar-language-selector', () => {
    const logger = useLogger();
    const userStore = useUser();
    const route = useRoute();
    const {user} = storeToRefs(userStore);

    const availableLanguages = ref<LanguageConfig[]>([]);
    const showLanguageSelectorModal = ref(false);

    const temporarySelectedCountry = ref<LanguageConfig>();
    const temporarySelectedLanguage = ref<Language>();

    const userCountry = computed(() => (user.value?.currentCountry ? (user.value?.currentCountry as CountryCode) : CountryCode.EN));
    const userLanguage = computed(() => (user.value?.currentLanguage ? (user.value?.currentLanguage as LanguageName) : LanguageName.EN));

    const htmlLangAttribute = computed(() => `${userLanguage.value}-${userCountry.value}`);

    const defaultLanguage = computed<Language>(() => ({
        host: '',
        label: '',
        language: userLanguage.value ?? LanguageName.EN,
        locale: userLanguage.value ?? LanguageName.EN,
    }));

    const countryFlagsIcons = ref<Record<CountryCode, string>>({
        [CountryCode.EN]: 'languages/ic_flag_uk',
        [CountryCode.GB]: 'languages/ic_flag_uk',
        [CountryCode.FR]: 'languages/ic_flag_fr',
        [CountryCode.BE]: 'languages/ic_flag_be',
        [CountryCode.DE]: 'languages/ic_flag_de',
        [CountryCode.ES]: 'languages/ic_flag_es',
        [CountryCode.IT]: 'languages/ic_flag_it',
        [CountryCode.LUX]: 'languages/ic_flag_lux',
        [CountryCode.PL]: 'languages/ic_flag_pl',
        [CountryCode.NL]: 'languages/ic_flag_nl',
        [CountryCode.CH]: 'languages/ic_flag_ch',
        [CountryCode.AT]: 'languages/ic_flag_at',
        [CountryCode.DK]: 'languages/ic_flag_eu',
        [CountryCode.AE]: 'languages/ic_flag_ae',
    });

    async function fetchAllLanguages() {
        try {
            availableLanguages.value = await universalFetch<LanguageConfig[]>('/api/supported-host/l10n/hosts');
        } catch (e) {
            logger.error("Can't fetch languages", e);
        }
    }

    const selectedCountry = computed<LanguageConfig | undefined>(() => {
        if (temporarySelectedCountry.value) {
            return temporarySelectedCountry.value;
        } else {
            return availableLanguages.value.find((lang) => lang.countryCode === user.value?.currentCountry);
        }
    });

    const selectedLanguage = computed<Language>(() => {
        if (temporarySelectedLanguage.value) {
            return temporarySelectedLanguage.value;
        } else {
            return selectedCountry.value?.languages.find((lang) => lang.language === user.value?.currentLanguage) ?? defaultLanguage.value;
        }
    });

    const selectedCountryFlag = computed<string>(() => {
        return selectedCountry.value ? countryFlagsIcons.value[selectedCountry.value.countryCode] : countryFlagsIcons.value[userCountry.value];
    });

    const loadingChangeLanguage = ref(false);

    // @XAuthTours(step = tour_send_redirect, includeCode = true)
    async function handleChangeLanguage() {
        if (temporarySelectedLanguage.value) {
            try {
                loadingChangeLanguage.value = true;
                const redirectUrl = new URL(window.location.toString());
                redirectUrl.searchParams.delete('lg');
                redirectUrl.host = temporarySelectedLanguage.value.host;
                const userToken = await universalFetch<string>('/api/user/x-auth-token');
                await universalFetch('/api/user/unsudosu', {method: 'POST'});
                const xAuthUrl = new URL(`${window.location.protocol}//${temporarySelectedLanguage.value.host}/navbar/api/xauth`);
                xAuthUrl.searchParams.append('redirectUrl', redirectUrl.toString());
                xAuthUrl.searchParams.append('x-auth', userToken);
                window.location.href = xAuthUrl.toString();
            } catch (e) {
                logger.error("Can't change language", e);
            } finally {
                loadingChangeLanguage.value = false;
            }
        }
    }

    async function redirectIfLgQueryPresent() {
        if (route.query.lg) {
            const localePattern = /^([a-zA-Z]{2})[-_]([a-zA-Z]{2})$/;
            const fallbackLocale = 'en_FR';
            let locale = route.query.lg.toString();

            locale = localePattern.test(locale)
                ? locale.replace(localePattern, (_, part1, part2) => {
                      return part1.toLowerCase() + '_' + part2.toUpperCase();
                  })
                : fallbackLocale;

            for (const countrySettings of availableLanguages.value) {
                const language = countrySettings.languages.find((lang: Language) => lang.locale === locale);

                if (language) {
                    temporarySelectedLanguage.value = language;
                    await handleChangeLanguage();
                    return;
                }
            }
        }
    }

    return {
        availableLanguages,
        countryFlagsIcons,
        fetchAllLanguages,
        selectedCountry,
        selectedLanguage,
        selectedCountryFlag,
        showLanguageSelectorModal,
        temporarySelectedCountry,
        temporarySelectedLanguage,
        handleChangeLanguage,
        loadingChangeLanguage,
        htmlLangAttribute,
        redirectIfLgQueryPresent,
    };
});
