import {defineStore} from 'pinia';
import {useDisplayMobile} from '#malt/nuxt-utils-module';
import {useActiveRoute, useNavigationTabs, useMobileNavBar} from './composables';
import {useExpandedTabs} from './stores';

export const useAsideMenu = defineStore('navbar-aside-menu', () => {
    // - Display modes and mobile mode
    const {isDisplayMobile} = useDisplayMobile();
    const {isMobileNavBarOpened, setMobileNavBarOpened, toggleMobileNavBarOpened} = useMobileNavBar();
    // - Tab list
    const {logoNavigationRoute, allNavigationRoutes, filteredNavigationRoutes} = useNavigationTabs();
    // - Active tab
    const {currentActiveRoute, pathname} = useActiveRoute();

    const expandedTabsStore = useExpandedTabs();

    if (currentActiveRoute.value) {
        expandedTabsStore.expandTab(currentActiveRoute.value);
    }

    return {
        currentActiveRoute,
        logoNavigationRoute,
        allNavigationRoutes,
        isDisplayMobile,
        isMobileNavBarOpened,
        filteredNavigationRoutes,
        setMobileNavBarOpened,
        toggleMobileNavBarOpened,
        pathname,
    };
});
