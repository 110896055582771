<template>
    <div class="header-menu-single-logout">
        <VJoyButton variant="ghost" @click="handleLogout">{{ $t('navbar.aside.tabs.title.freelancer.logout') }}</VJoyButton>
    </div>
</template>

<script setup lang="ts">
    import {useUser} from '../user';
    import {VJoyButton} from '@maltjoy/core-vue';

    const userStore = useUser();

    async function handleLogout() {
        await userStore.logoutUser();
    }
</script>

<style lang="scss" scoped>
    .header-menu-single-logout {
        display: flex;
        justify-content: right;
        padding-top: var(--joy-core-spacing-3);
    }
</style>
