import type {MaybeRef} from '@malt/types-utils';
import type {Ref} from 'vue';
import {computed, unref} from 'vue';
import type {SharedBreakpointsOptions} from './types';
import {BreakPointsTypes, BreakPointsValues} from './types';

interface useBreakPointOperationsOptions {
    container?: MaybeRef<HTMLElement | undefined>;
    containerWidth: Ref<number>;
    containerHeight: Ref<number>;
    options?: SharedBreakpointsOptions;
    layoutPaddingLeft?: Ref<number | null>;
    type: 'window' | 'container';
}

/** Only to reuse logic between useMediaQuery and useContainerQuery */
export function useBreakpointOperations({
    container,
    containerHeight,
    containerWidth,
    options,
    layoutPaddingLeft,
    type,
}: useBreakPointOperationsOptions) {
    const unwrappedContainer = computed(() => unref(container));

    const currentBreakpoint = computed<BreakPointsTypes>(() => {
        const activeBreakpoint = (Object.entries(BreakPointsValues) as Array<[BreakPointsTypes, BreakPointsValues]>).find(
            ([_, value]) => containerWidth.value <= (value as number),
        );
        if (activeBreakpoint) {
            return BreakPointsTypes[activeBreakpoint[0]];
        } else {
            return BreakPointsTypes.Xl;
        }
    });

    const matchMedia = computed(() => {
        return {
            max: Object.fromEntries(
                Object.entries(BreakPointsValues).map(([key, value]) => {
                    return [key, containerWidth.value <= (value as number)];
                }),
            ) as Record<BreakPointsTypes, boolean>,
            min: Object.fromEntries(
                Object.entries(BreakPointsValues).map(([key, value]) => {
                    return [key, containerWidth.value >= (value as number)];
                }),
            ) as Record<BreakPointsTypes, boolean>,
        };
    });

    const matchNavbarMedia = computed(() => {
        return {
            max: Object.fromEntries(
                Object.entries(BreakPointsValues).map(([key, value]) => {
                    return [key, containerWidth.value - (layoutPaddingLeft?.value ?? 0) <= (value as number)];
                }),
            ) as Record<BreakPointsTypes, boolean>,
            min: Object.fromEntries(
                Object.entries(BreakPointsValues).map(([key, value]) => {
                    return [key, containerWidth.value - (layoutPaddingLeft?.value ?? 0) >= (value as number)];
                }),
            ) as Record<BreakPointsTypes, boolean>,
        };
    });

    function handleResize() {
        window.requestAnimationFrame(() => {
            if (unwrappedContainer.value) {
                if (type === 'window') {
                    containerWidth.value = unwrappedContainer.value.clientWidth || window.innerWidth;
                    containerHeight.value = unwrappedContainer.value.clientHeight || window.innerHeight;
                } else {
                    const {width, height} = unwrappedContainer.value.getBoundingClientRect();
                    containerWidth.value = width;
                    containerHeight.value = height;
                }
                if (options?.onResize) {
                    options.onResize({width: containerWidth.value, height: containerHeight.value});
                }
            }
        });
    }

    return {
        currentBreakpoint,
        handleResize,
        matchMedia,
        matchNavbarMedia,
    };
}
