export enum CountryCode {
    AE = 'AE',
    AT = 'AT',
    BE = 'BE',
    CH = 'CH',
    DE = 'DE',
    DK = 'DK',
    EN = 'EN',
    ES = 'ES',
    FR = 'FR',
    GB = 'GB',
    IT = 'IT',
    LUX = 'LUX',
    NL = 'NL',
    PL = 'PL',
}

export enum LanguageName {
    FR = 'fr',
    AT = 'at',
    BE = 'be',
    CH = 'ch',
    DE = 'de',
    DK = 'dk',
    ES = 'es',
    IT = 'it',
    LUX = 'lux',
    NL = 'nl',
    PL = 'pl',
    EN = 'en',
}

export interface LanguageConfig {
    countryName: string;
    countryCode: CountryCode;
    languages: Language[];
    settings: LanguageSetting[];
}

export interface Language {
    host: string;
    language: LanguageName;
    locale: string;
    label: string;
}

export interface LanguageSetting {
    host: string;
    language: string;
    locale: string;
    label: string;
    currencyCode: string;
}
