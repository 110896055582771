<template>
    <VJoyProductTour
        ref="productTourRef"
        open
        spotlight-selector="[data-client-onboarding-get-started-on-malt]"
        position="left"
        data-testid="client-onboarding-get-started-on-malt-product-tour"
        :close-button="false"
        @product-tour:hide="emit('close')"
    >
        <template #product-tour-content>{{ t('navbar.client.onboarding.product.tour.get.started.on.malt.content') }}</template>
        <template #product-tour-next>
            <VJoyButton
                :label="t('navbar.client.onboarding.product.tour.get.started.on.malt.cta')"
                data-testid="client-onboarding-get-started-on-malt-product-tour-cta"
                size="small"
                variant="main"
                @click="close"
            />
        </template>
    </VJoyProductTour>
</template>
<script setup lang="ts">
    import {ref} from 'vue';
    import {VJoyButton, VJoyProductTour} from '@maltjoy/core-vue';
    import {useTranslation} from '#imports';

    const {t} = useTranslation();
    const productTourRef = ref<InstanceType<typeof VJoyProductTour>>();

    const emit = defineEmits(['close']);

    function close() {
        productTourRef.value?.hide();
    }
</script>
