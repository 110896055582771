/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectIdentityRequest
 */
export interface SelectIdentityRequest {
    /**
     * 
     * @type {string}
     * @memberof SelectIdentityRequest
     */
    identityId: string;
}

/**
 * Check if a given object implements the SelectIdentityRequest interface.
 */
export function instanceOfSelectIdentityRequest(value: object): value is SelectIdentityRequest {
    if (!('identityId' in value) || value['identityId'] === undefined) return false;
    return true;
}

export function SelectIdentityRequestFromJSON(json: any): SelectIdentityRequest {
    return SelectIdentityRequestFromJSONTyped(json, false);
}

export function SelectIdentityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectIdentityRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'identityId': json['identityId'],
    };
}

export function SelectIdentityRequestToJSON(value?: SelectIdentityRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'identityId': value['identityId'],
    };
}

