/// <reference path="global-defs.d.ts"/>

import type {MaybeRef} from '@malt/types-utils';
import {unref} from 'vue';

type CSSVariable = keyof GlobalCSSCustomProperties | (string & {});

// - Implementations
/**
 * Returns the raw value of a native css variable
 *
 * @param name - The name of the css variable
 * @param [element=document.documentElement] - The root target from where to get the variable
 *
 * @usage
 *
 * ```ts
 * const headerHeight = getCssVariable('--header-height'); // 70px;
 * // Or
 * const myDiv = ref<HtmlDivElement>();
 * const customVar = getCssVariable('--scoped-variable', myDiv);
 * ```
 *
 *  # How to augment types
 *
 * Create a shims.d.ts file with
 *
 * ```ts
 * declare global {
 *  interface GlobalCSSCustomProperties {
 *     '--my-variable': true;
 *  }
 * }
 * ```
 */
export function getCssVariable(name: CSSVariable, element?: MaybeRef<HTMLElement>): string {
    const target = element ? unref(element) : document.documentElement;
    return getComputedStyle(target).getPropertyValue(name);
}

/**
 * Set the value of a css variable
 *
 * @param name - The name of the css variable
 * @param value - The value of the css-variable
 * @param [element=document.documentElement] - The root target from where to set the variable
 *
 * @usage
 *
 * ```ts
 * setCssVariable('--header-height', '50px');
 * // Or
 * const myDiv = ref<HtmlDivElement>();
 * setCssVariable('--scoped-variable', '1px 2px', myDiv);
 * ```
 *
 * # How to augment types
 *
 * Create a shims.d.ts file with
 *
 * ```ts
 * declare global {
 *  interface GlobalCSSCustomProperties {
 *     '--my-variable': true;
 *  }
 * }
 * ```
 */
export function setCssVariable(name: CSSVariable, value: string, element?: MaybeRef<HTMLElement>): void {
    const target = element ? unref(element) : document.documentElement;
    target.style.setProperty(name, value);
}
