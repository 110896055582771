import type {Ref} from 'vue';
import {computed} from 'vue';
import type {NavigationTab} from '../aside-menu.types';

export type CachedFilteredTab = {
    id: string;
    notificationCount?: number;
    children?: Omit<CachedFilteredTab, 'children'>[];
};

/** Take persited tabs and reconstruct the tabs tree with constraint checking */
export function useFilteredTabs(allTabs: Ref<NavigationTab[]>) {
    /** Custom reducer for filtering the tabs */
    function filterTabByConstraint(acc: NavigationTab[], tab: NavigationTab): NavigationTab[] {
        const tabChildren = tab.children ?? tab.ghostChildren;
        const _tab: NavigationTab = {
            ...tab,
            ...(tabChildren && {
                children: tabChildren.filter((f) => f.constraint == null || !!f.constraint),
            }),
        };
        if (tab.constraint != null) {
            if (tab.constraint) {
                return acc.concat([_tab]);
            } else {
                return acc;
            }
        } else {
            return acc.concat([_tab]);
        }
    }

    const filteredNavigationRoutes = computed<NavigationTab[]>(() => {
        // Restore tabs tree based on cached state and all tabs array
        return allTabs.value.reduce(filterTabByConstraint, []);
    });

    return {
        filteredNavigationRoutes,
    };
}
