import {useUniversalFetch, useLocale} from '#imports';
import {useAutocompleteEngine} from '@navbar-logged/common/composables';
import {computed} from 'vue';
import {useRemoteLabels} from './useRemoteLabels';
import {useSearchForm} from '../search.store';
import {storeToRefs} from 'pinia';
import {getPageLanguageCode} from '@malt/dom';
import {getDefaultCitiesSuggestionsForLocale} from '@malt/location-autocomplete/get-default-cities-suggestions';
import type {LocationItem} from '../search.types';

export function useLocationAutocomplete() {
    const searchFormStore = useSearchForm();
    const {locationQuery} = storeToRefs(searchFormStore);

    const {locale, languageCode} = useLocale();
    const {remoteCountryLabel} = useRemoteLabels();

    const defaultCitySuggestion: LocationItem[] = getDefaultCitiesSuggestionsForLocale(locale.value);

    const remoteCountrySuggestion = computed(() => ({
        remoteAllowed: true,
        formattedAddress: remoteCountryLabel.value,
    }));

    const defaultSuggestions = computed<LocationItem[]>(() => {
        const remoteSuggestions = [remoteCountrySuggestion.value, ...defaultCitySuggestion].filter((x): x is NonNullable<typeof x> => !!x);
        return remoteSuggestions;
    });

    async function getSuggestionsLocationAutoComplete(value: string | null) {
        // FIXME - not sure whether this is the right fix. buy getPageLanguageCode cannot work in SSR
        // should we always use the languageCode from useLocale or prevent the autocomplete
        // from running server side ?
        const languageTag = import.meta.client ? getPageLanguageCode() : languageCode.value;
        const body = {
            query: value,
            layer: 'LOCALITY',
            languageTag,
            size: 5,
            useBackendfocusPoint: true,
        };
        const data = await useUniversalFetch<LocationItem[]>('/api/location/autocomplete', {baseURL: '/search', body, method: 'POST'});

        return data;
    }

    const {formatedSuggestions, getSuggestions, loading, setSelectedValue} = useAutocompleteEngine<LocationItem>(locationQuery, {
        defaultSuggestions,
        getSuggestions: async (value: string | null) => {
            return await getSuggestionsLocationAutoComplete(value);
        },
        onSelect(value) {
            if (value && !value.remoteAllowed) {
                return value.formattedAddress ?? '';
            } else if (value && value.remoteAllowed) {
                if (remoteCountrySuggestion.value?.formattedAddress) {
                    return remoteCountrySuggestion.value.formattedAddress;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
    });

    return {
        formatedSuggestions,
        getSuggestions,
        setSelectedValue,
        loading,
        remoteCountrySuggestion,
    };
}
