/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProfilePreferencesResource
 */
export interface ProfilePreferencesResource {
    /**
     * 
     * @type {boolean}
     * @memberof ProfilePreferencesResource
     */
    searchVisibilityChoice: boolean;
}

/**
 * Check if a given object implements the ProfilePreferencesResource interface.
 */
export function instanceOfProfilePreferencesResource(value: object): value is ProfilePreferencesResource {
    if (!('searchVisibilityChoice' in value) || value['searchVisibilityChoice'] === undefined) return false;
    return true;
}

export function ProfilePreferencesResourceFromJSON(json: any): ProfilePreferencesResource {
    return ProfilePreferencesResourceFromJSONTyped(json, false);
}

export function ProfilePreferencesResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfilePreferencesResource {
    if (json == null) {
        return json;
    }
    return {
        
        'searchVisibilityChoice': json['searchVisibilityChoice'],
    };
}

export function ProfilePreferencesResourceToJSON(value?: ProfilePreferencesResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'searchVisibilityChoice': value['searchVisibilityChoice'],
    };
}

