<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                    <path d="m6.07335 6.84733c.33962.9273.33962 1.81339.33962 1.81339h-5.663702s0-2.79221 1.917872-3.62678" />
                    <path
                        d="m3.58601 5.22971c1.14196 0 2.06771-.95489 2.06771-2.1328s-.92575-2.132799-2.06771-2.132799-2.0677.954889-2.0677 2.132799.92574 2.1328 2.0677 2.1328z"
                    />
                    <path d="m9.88898 5.4771-1.48835-1.53521 1.47836-1.5249" />
                    <path d="m9.58936 3.94189c2.66704 0 4.82464 2.22553 4.82464 4.97654" />
                    <path d="m8.10107 12.813 1.48835 1.5352-1.47836 1.5249" />
                    <path d="m8.41058 14.3482c-2.66704 0-4.82464-2.2255-4.82464-4.9765" />
                    <path d="m16.9012 15.6053c.3397.9273.3397 1.8134.3397 1.8134h-5.6638s0-2.7923 1.9179-3.6268" />
                    <path
                        d="m14.4139 13.9876c1.142 0 2.0677-.9548 2.0677-2.1328 0-1.1779-.9257-2.13275-2.0677-2.13275s-2.0677.95485-2.0677 2.13275c0 1.178.9257 2.1328 2.0677 2.1328z"
                    />
                </g>
            </svg>
        </template>
        <template #active>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg">
                <g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                    <path d="m9.86896 5.4771-1.48834-1.53521 1.47835-1.5249" />
                    <path d="m9.55933 3.94189c2.66707 0 4.82467 2.22553 4.82467 4.97654" />
                    <path d="m8.08105 12.813 1.48835 1.5352-1.47836 1.5249" />
                    <path d="m8.38079 14.3482c-2.66703 0-4.82464-2.2255-4.82464-4.9765" />
                </g>
                <path
                    d="m4.49511 5.02368c.7192.3091 1.1687.8964 1.44839 1.5146.46947 1.02003.46947 2.12249.46947 2.12249h-5.663702s0-2.79221 1.917872-3.62679"
                    fill="currentColor"
                />
                <path
                    d="m4.49511 5.02368c.7192.3091 1.1687.8964 1.44839 1.5146.46947 1.02003.46947 2.12249.46947 2.12249h-5.663702s0-2.79221 1.917872-3.62679"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path
                    d="m3.58601 5.22971c1.14196 0 2.06771-.95489 2.06771-2.1328s-.92575-2.132799-2.06771-2.132799-2.0677.954889-2.0677 2.132799.92574 2.1328 2.0677 2.1328z"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path d="m15.333 13.7919c1.9179.8345 1.9179 3.6268 1.9179 3.6268h-5.6637s0-2.7923 1.9178-3.6268" fill="currentColor" />
                <path
                    d="m15.333 13.7919c1.9179.8345 1.9179 3.6268 1.9179 3.6268h-5.6637s0-2.7923 1.9178-3.6268"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
                <path
                    d="m14.4141 13.9876c1.142 0 2.0677-.9548 2.0677-2.1328 0-1.1779-.9257-2.13275-2.0677-2.13275-1.1419 0-2.0677.95485-2.0677 2.13275 0 1.178.9258 2.1328 2.0677 2.1328z"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                />
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
