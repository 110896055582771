<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <clipPath id="a"><path d="m0 .191406h18v18h-18z" /></clipPath>
                <g clip-path="url(#a)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                    <path
                        d="m7.22 14.0014c-.34.23-2.94 2.23-2.94 2.23v-2.05c0-.47-.38-.85-.85-.85h-1.83c-.47 0-.85-.38-.85-.85v-10.68999c0-.47.38-.850004.85-.850004h14.55c.47 0 .85.380004.85.850004v10.68999c0 .47-.38.85-.85.85h-6.49"
                    />
                    <path d="m4.37012 9.24146h9.00998" />
                    <path d="m4.37012 5.3313h9.00998" />
                </g>
            </svg>
        </template>
        <template #active>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <clipPath id="a"><path d="m0 .191406h18v18h-18z" /></clipPath>
                <g clip-path="url(#a)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                    <path d="m4.41992 5.3313s.92 3.91 4.54 3.91c3.61998 0 4.46998-3.91 4.46998-3.91z" fill="currentColor" />
                    <path
                        d="m7.22 14.2514c-.34.23-2.94 2.23-2.94 2.23v-2.05c0-.47-.38-.85-.85-.85h-1.83c-.47 0-.85-.38-.85-.85v-10.68999c0-.47.38-.85.85-.85h14.55c.47 0 .85.38.85.85v10.68999c0 .47-.38.85-.85.85h-6.49"
                    />
                </g>
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
