<template>
    <NavbarUnlogged v-if="!loggedIn" :show-search="props.showSearchUnlogged" />
    <NavbarLogged v-else />
</template>

<script setup lang="ts">
    import {storeToRefs} from 'pinia';
    import {NavbarLogged, NavbarUnlogged} from '#components';
    import {useAuth} from '#imports';
    import {useNavbarState} from './logged/registry';
    import {NavbarStateResourceNavbarDisplayModeEnum} from '@navbar-api';

    const props = defineProps({
        showSearchUnlogged: {type: Boolean, default: false},
        isFunnelMode: {type: Boolean, default: false},
    });

    const {loggedIn} = storeToRefs(useAuth());

    const navbarStateStore = useNavbarState();
    if (props.isFunnelMode) {
        navbarStateStore.updateNavbarDisplayMode(NavbarStateResourceNavbarDisplayModeEnum.Funnel);
    }
</script>
