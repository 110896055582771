<template>
    <div ref="fieldAutocompleteRef" class="field-autocomplete / flex">
        <Component
            :is="getComponentType"
            :id="name"
            ref="inputRef"
            :value="props.searchValue"
            :model-value="props.searchValue"
            autocomplete="off"
            type="text"
            :maxlength="SEARCH_QUERY_MAX_LENGTH"
            class="field-autocomplete_input"
            :name="props.name"
            :placeholder="getPlaceholder"
            :class="{isFocused}"
            aria-controls="autoComplete_list_1"
            aria-autocomplete="both"
            role="combobox"
            aria-owns="field-autocomplete_suggestions"
            aria-haspopup="true"
            :aria-expanded="showSuggestions"
            v-bind="$attrs"
            @input="handleInput"
            @focus="handleFocus"
            @blur="handleBlur"
        />
        <div v-if="loading" class="field-autocomplete_spinner">
            <VJoySpinner />
        </div>
        <Transition name="fade-scale" mode="out-in">
            <div v-if="showSuggestions" class="field-autocomplete_suggestions / flex" @mouseover="handleMouseEnter" @click.stop>
                <div
                    v-for="(suggestion, index) of props.suggestions"
                    :key="index"
                    ref="suggestionItemsRefs"
                    class="field-autocomplete_suggestions_item / flex"
                    :class="{isSelected: index === selectedElementIndex}"
                    @mousedown.stop="handleSelect(suggestion)"
                >
                    <span :title="props.formatter(suggestion)" class="field-autocomplete_suggestions_item_label / flex">
                        <!-- prettier-ignore -->
                        <slot v-if="$slots.suggestion && (typeof suggestion !== 'string')" name="suggestion" v-bind="suggestion" />
                        <span v-else>{{ props.formatter(suggestion) }}</span>
                    </span>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup lang="ts">
    import {useDetectOutsideClick, useDisplayMobile} from '#malt/nuxt-utils-module';
    import {onKeyStroke} from '@vueuse/core';
    import debounce from 'lodash-es/debounce';
    import throttle from 'lodash-es/throttle';
    import type {PropType} from 'vue';
    import {nextTick, computed, ref, watch, useTemplateRef} from 'vue';
    import {VJoySpinner, VJoyInput} from '@maltjoy/core-vue';

    const SEARCH_QUERY_MAX_LENGTH = 128;

    type Result = Record<string, any> | string;

    const props = defineProps({
        suggestions: {
            type: Array as PropType<Result[]>,
            default: () => {
                return [];
            },
        },
        searchValue: {type: String as PropType<string | null>, default: ''},
        focused: {type: Boolean, default: false},
        name: {type: String, required: true},
        placeholder: {type: String, required: true},
        loading: Boolean,
        formatter: {
            type: Function as PropType<(value: any) => string>,
            default: (value: any) => value,
        },
        preventTabDefault: {type: Boolean, default: false},
    });

    const emit = defineEmits<{
        (e: 'update:searchValue', value: string | null): void;
        (e: 'update:focused', value: boolean): void;
        (e: 'select', item: any | null): void;
        (e: 'input', value: string | null): void;
    }>();

    const {isDisplayMobile} = useDisplayMobile();
    const keepFieldFocused = ref(false);

    const fieldAutocompleteRef = ref<HTMLDivElement>();
    const inputRef = ref<HTMLInputElement>();
    const suggestionItemsRefs = useTemplateRef('suggestionItemsRefs');

    useDetectOutsideClick(fieldAutocompleteRef, () => {
        keepFieldFocused.value = false;
    });

    const getPlaceholder = computed(() => {
        return props.placeholder;
    });

    // - Keyboard

    const showSuggestions = computed(() => props.suggestions?.length > 0 && (isFocused.value || keepFieldFocused.value));

    const selectedElementIndex = ref(0);

    watch(
        () => props.suggestions,
        () => {
            selectedElementIndex.value = 0;
        },
    );

    watch(selectedElementIndex, async () => {
        await nextTick();
        if (!suggestionItemsRefs.value) {
            return;
        }
        const selectedItem = suggestionItemsRefs.value[selectedElementIndex.value];
        selectedItem?.scrollIntoView({block: 'nearest', behavior: 'smooth'});
    });

    const selectedElement = computed(() => props.suggestions[selectedElementIndex.value]);
    const getComponentType = computed(() => (isDisplayMobile.value ? VJoyInput : 'input'));

    onKeyStroke('ArrowUp', () => {
        if (document.activeElement !== inputRef.value) {
            return;
        }
        if (selectedElementIndex.value > 0) {
            selectedElementIndex.value--;
        }
    });

    onKeyStroke('ArrowDown', () => {
        if (document.activeElement !== inputRef.value) {
            return;
        }
        if (selectedElementIndex.value < props.suggestions?.length - 1) {
            selectedElementIndex.value++;
        }
    });

    onKeyStroke('Enter', (e: KeyboardEvent) => {
        if (selectedElement.value && showSuggestions.value) {
            handleSelect(selectedElement.value);
            focus();
            e.preventDefault();
        }
    });

    onKeyStroke('Escape', () => {
        if (props.focused) {
            blur();
            handleBlur();
            keepFieldFocused.value = false;
        }
    });

    onKeyStroke('Tab', (e: KeyboardEvent) => {
        if (selectedElement.value && showSuggestions.value) {
            handleSelect(selectedElement.value);
            if (props.preventTabDefault) {
                e.preventDefault();
            }
        }
    });

    const isFocused = computed({
        get: () => props.focused,
        set(value) {
            emit('update:focused', value);
        },
    });

    const getSearchValue = computed({
        get: () => props.searchValue,
        set(value) {
            emit('update:searchValue', value);
        },
    });

    // - Select

    async function handleSelect(suggestion: Result) {
        emit('select', suggestion);
        await nextTick();
        keepFieldFocused.value = false;
    }

    // - Input

    const handleInputDebounce = debounce((value: string | null) => {
        emit('input', value);
    }, 200);

    function handleInput(event: Event) {
        const value: string = (event.target as any).value;
        getSearchValue.value = value;
        handleInputDebounce(value);
    }

    // - Focus

    function handleFocus() {
        isFocused.value = true;
        keepFieldFocused.value = true;
        if (!props.searchValue) {
            selectedElementIndex.value = -1;
        }
    }

    function handleBlur() {
        isFocused.value = false;
        keepFieldFocused.value = false;
    }

    function focus() {
        inputRef.value?.focus();
    }

    function blur() {
        inputRef.value?.blur();
    }

    // - Mouse

    const handleMouseEnter = throttle(() => {
        selectedElementIndex.value = -1;
    }, 10);

    defineExpose({
        focus,
        blur,
    });
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .field-autocomplete {
        flex: 1;

        &_input {
            flex: 1;
            border: none;
            background: none;
            padding: 0;
            height: var(--input-height);
            padding-right: var(--joy-core-spacing-4);
        }

        &_spinner {
            flex: 0 0 auto;
            width: 30px;
            transform: translateY(calc(50% - 10px));

            :deep(.joy-spinner) {
                --spinner-size: 20px;
            }
        }

        &_label {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition: top 0.2s ease, font-size 0.2s ease, transform 0.2s ease;
            cursor: text;

            &.isFilled,
            &.isFocused {
                font-size: 12px;
                top: 12px;
                transform: translateY(0);
            }
        }
        &_suggestions {
            display: flex;
            flex-flow: column nowrap;
            position: absolute;
            top: calc(100% + 8px);
            left: -20px;
            width: calc(100% + 40px);
            max-width: none;
            background-color: white;
            border-radius: var(--joy-core-radius-3);
            box-shadow: var(--joy-core-elevation-3);
            max-height: 360px;
            overflow-y: auto;
            z-index: 10;

            &_item {
                height: 60px;
                flex: 0 0 auto;
                align-items: center;
                padding: var(--joy-core-spacing-2) var(--joy-core-spacing-4);
                cursor: pointer;
                transition: background-color 0.2s ease;
                color: var(--joy-color-neutral-6);

                &_label {
                    align-items: center;
                    flex-wrap: nowrap;

                    :deep(joy-icon) {
                        flex: 0 0 auto;
                    }
                    :deep(> *) {
                        white-space: nowrap;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                    }
                }

                &:first-child {
                    border-top-left-radius: var(--joy-core-radius-3);
                    border-top-right-radius: var(--joy-core-radius-3);
                }
                &:last-child {
                    border-bottom-left-radius: var(--joy-core-radius-3);
                    border-bottom-right-radius: var(--joy-core-radius-3);
                }

                &:hover,
                &.isSelected {
                    background-color: var(--joy-color-secondary-10);
                }
            }

            @include mq.screen_xs {
                width: calc(100%);
                left: 0;
            }
        }
    }
</style>
