import type {UserIdentity, UserResource} from '@navbar-api';
import {UserIdentityTypeEnum} from '@navbar-api';
import cookie from 'js-cookie';
import {defineStore} from 'pinia';
import {computed, ref, watch} from 'vue';
import {NavbarContext, useNavbarState} from '@navbar-registry';
import {useLogger, navigateTo, guestHost, useAuth} from '#imports';

export const useUser = defineStore('navbar-logged-user', () => {
    const user = ref<UserResource>();
    const navbarState = useNavbarState();
    const logger = useLogger();
    const host = guestHost();

    const authStore = useAuth();

    // - Identities
    const userIdentities = computed<UserIdentity[]>(() => user.value?.identities ?? []);
    const selectedIdentity = computed<UserIdentity | undefined>(() => userIdentities.value.find(({id}) => user.value?.selectedIdentityId === id));
    const hasSelectedClientIdentity = computed<boolean>(() => selectedIdentity.value?.type === UserIdentityTypeEnum.Client);
    const hasSelectedFreelanceIdentity = computed<boolean>(() => selectedIdentity.value?.type === UserIdentityTypeEnum.Freelance);

    const cachedIdentityRole = ref<UserIdentityTypeEnum>();

    watch(
        selectedIdentity,
        (newIdentity) => {
            if (import.meta.client) {
                const currentIdentityRole = sessionStorage.getItem('cachedIdentityRole');
                if (newIdentity) {
                    cachedIdentityRole.value = newIdentity.type;
                    sessionStorage.setItem('cachedIdentityRole', newIdentity.type);
                } else {
                    cachedIdentityRole.value = currentIdentityRole as UserIdentityTypeEnum;
                }
            }
        },
        {immediate: true},
    );

    async function setUserIdentity(identityId: string) {
        try {
            if (selectedIdentity.value?.id !== identityId) {
                cookie.set('hopsi', identityId);
                await NavbarContext.selectIdentity({selectIdentityRequest: {identityId}});
                sessionStorage.removeItem('cachedIdentityRole');
                if (navbarState.state.navbarDisplayMode) {
                    navigateTo(`${host}/dashboard`, {external: true});
                } else {
                    navigateTo(host, {external: true});
                }
            }
        } catch (e) {
            logger.error(e);
        }
    }

    function logoutUser() {
        authStore.logout();
    }

    // - Onboarding

    async function acknowledgeOnboarding(closeOnboarding = true) {
        if (closeOnboarding) {
            user.value!.options.onboarded = true;
        }
        await NavbarContext.acknowledgeOnboarding();
    }

    if (import.meta.client) {
        window.addEventListener('user-identity-enforced', (evt: Event) => {
            const e = evt as CustomEvent;
            if (user.value && e.detail && e.detail.enforcedIdentityId && e.detail.enforcedIdentityId !== user.value.selectedIdentityId) {
                // because the user value is watched, the navbar will re-render if needed
                user.value = {...user.value, selectedIdentityId: e.detail.enforcedIdentityId};
            }
        });
    }

    return {
        user,
        userIdentities,
        selectedIdentity,
        hasSelectedClientIdentity,
        hasSelectedFreelanceIdentity,
        cachedIdentityRole,
        setUserIdentity,
        logoutUser,
        acknowledgeOnboarding,
    };
});
