import {useUniversalFetch} from '#imports';
import {useAutocompleteEngine} from '@navbar-logged/common/composables';
import {storeToRefs} from 'pinia';
import {useSearchForm} from '../search.store';

export function useJobAutocomplete() {
    const searchFormStore = useSearchForm();
    const {jobQuery} = storeToRefs(searchFormStore);
    async function getSuggestionsForJobAutocomplete(value: string | null) {
        const data = await useUniversalFetch<{
            suggestions: string[];
        }>('/api/search/autocomplete', {
            baseURL: '/search',
            params: {
                q: value,
            },
            mode: 'no-cors', // MAT-1623: attempt to fix recurring safari sentry
        });
        return data.suggestions;
    }
    const {formatedSuggestions, getSuggestions, loading, setSelectedValue} = useAutocompleteEngine<string>(jobQuery, {
        getSuggestions: async (value: string | null) => {
            // Frontend uses ofetch which rely on ufo to encode query params. Ufo adopts a 'user centric experience'
            // and it replaces some encoded characters with their actual value. For instance, %60 is restored as a
            // backquote `.
            // On the other hand, Tomcat implements strict special characters handling in the urls and produces 400 bad
            // request errors when it detects unencoded backquotes `, pipes | or caret ^.
            // As a workaround we remove characters causing trouble.
            const termsWithoutUnsafeChars = value?.replace(/[|`^]/g, ' ').trim();
            return termsWithoutUnsafeChars ? getSuggestionsForJobAutocomplete(termsWithoutUnsafeChars) : [];
        },
        onSelect(value) {
            return value ?? '';
        },
    });

    return {
        formatedSuggestions,
        getSuggestions,
        setSelectedValue,
        loading,
    };
}
