/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationsResource
 */
export interface NotificationsResource {
    /**
     * 
     * @type {number}
     * @memberof NotificationsResource
     */
    freelancerCompliance?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsResource
     */
    messages: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsResource
     */
    sourcingActivities?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsResource
     */
    projectActions?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsResource
     */
    companySettings?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationsResource
     */
    profileActions?: number;
}

/**
 * Check if a given object implements the NotificationsResource interface.
 */
export function instanceOfNotificationsResource(value: object): value is NotificationsResource {
    if (!('messages' in value) || value['messages'] === undefined) return false;
    return true;
}

export function NotificationsResourceFromJSON(json: any): NotificationsResource {
    return NotificationsResourceFromJSONTyped(json, false);
}

export function NotificationsResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsResource {
    if (json == null) {
        return json;
    }
    return {
        
        'freelancerCompliance': json['freelancerCompliance'] == null ? undefined : json['freelancerCompliance'],
        'messages': json['messages'],
        'sourcingActivities': json['sourcingActivities'] == null ? undefined : json['sourcingActivities'],
        'projectActions': json['projectActions'] == null ? undefined : json['projectActions'],
        'companySettings': json['companySettings'] == null ? undefined : json['companySettings'],
        'profileActions': json['profileActions'] == null ? undefined : json['profileActions'],
    };
}

export function NotificationsResourceToJSON(value?: NotificationsResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'freelancerCompliance': value['freelancerCompliance'],
        'messages': value['messages'],
        'sourcingActivities': value['sourcingActivities'],
        'projectActions': value['projectActions'],
        'companySettings': value['companySettings'],
        'profileActions': value['profileActions'],
    };
}

