
/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  AvailabilityResource,
  AvailabilityUpdateRequestResource,
} from '../models';
import {
    AvailabilityResourceFromJSON,
    AvailabilityResourceToJSON,
    AvailabilityUpdateRequestResourceFromJSON,
    AvailabilityUpdateRequestResourceToJSON,
} from '../models';

export interface UpdateAvailabilityRequest {
    availabilityUpdateRequestResource: AvailabilityUpdateRequestResource;
}

/**
 * 
 */
export class FreelancerAvailabilityApi extends runtime.BaseAPI {

    /**
     */
    async getAvailabilityRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<AvailabilityResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<AvailabilityResource>({
            path: `/navbar/api/profile-availability`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=AvailabilityResourceFromJSON(response._data); }
        return response;
    }

    /**
     */
    async getAvailability(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<AvailabilityResource> {
        const response = await this.getAvailabilityRaw(initOverrides);
        return response._data;
    }

    /**
     */
    async updateAvailabilityRaw(requestParameters: UpdateAvailabilityRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<AvailabilityResource>> {
        if (requestParameters['availabilityUpdateRequestResource'] == null) {
            throw new runtime.RequiredError(
                'availabilityUpdateRequestResource',
                'Required parameter "availabilityUpdateRequestResource" was null or undefined when calling updateAvailability().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<AvailabilityResource>({
            path: `/navbar/api/profile-availability`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AvailabilityUpdateRequestResourceToJSON(requestParameters['availabilityUpdateRequestResource']),
        }, initOverrides);

        if (response._data) { response._data=AvailabilityResourceFromJSON(response._data); }
        return response;
    }

    /**
     */
    async updateAvailability(requestParameters: UpdateAvailabilityRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<AvailabilityResource> {
        const response = await this.updateAvailabilityRaw(requestParameters, initOverrides);
        return response._data;
    }

}
