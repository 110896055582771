
/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  FreelancerCompanyResource,
} from '../models';
import {
    FreelancerCompanyResourceFromJSON,
    FreelancerCompanyResourceToJSON,
} from '../models';

/**
 * 
 */
export class FreelancerCompanyApi extends runtime.BaseAPI {

    /**
     */
    async getFreelancerCompanyRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<FreelancerCompanyResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<FreelancerCompanyResource>({
            path: `/navbar/api/freelancer-company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=FreelancerCompanyResourceFromJSON(response._data); }
        return response;
    }

    /**
     */
    async getFreelancerCompany(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<FreelancerCompanyResource> {
        const response = await this.getFreelancerCompanyRaw(initOverrides);
        return response._data;
    }

}
