import {setCssVariable, useDisplayMobile} from '#malt/nuxt-utils-module';
import {defineStore} from 'pinia';
import {watch} from 'vue';
import {useHeaderNavigationTabs} from './composables';
import {useSearchDisplayMode} from '@navbar-logged/features/search';
import {useUser} from '../user';
import {useSearchRouteDefinitions} from '@navbar/features/search';
import {SearchDisplayMode} from '@navbar/features/search/search.types';

export const useHeaderMenu = defineStore('navbar-header-menu', () => {
    const {isDisplayMobile} = useDisplayMobile();
    const userStore = useUser();
    const {searchDisplayMode, searchPreviewTarget, setSearchDisplayModeToForm} = useSearchDisplayMode();

    const {currentPage} = useSearchRouteDefinitions();

    const {headerNavigationTabs, onClientOnboardingIsPresent} = useHeaderNavigationTabs();

    function setTopNavPreviewDesktop() {
        setCssVariable('--header-menu-height', '70px');
        setCssVariable('--header-height', '70px');
        setCssVariable('--header-menu-item-margin-top', '0');
        setCssVariable('--layout-padding-top', '70px');
    }

    function setTopNavPreviewMobile() {
        setCssVariable('--header-menu-height', '145px');
        setCssVariable('--header-height', '145px');
        setCssVariable('--header-menu-item-margin-top', '0');
        setCssVariable('--layout-padding-top', '145px');
    }

    function setTopNavFormDesktop() {
        setCssVariable('--header-menu-height', '100px');
        setCssVariable('--header-height', '100px');
        setCssVariable('--header-menu-item-margin-top', '-30px');
        if (currentPage.value?.pushContent) {
            setCssVariable('--layout-padding-top', '100px');
        } else {
            setCssVariable('--layout-padding-top', '70px');
        }
    }

    function onDisplayModeChange() {
        if (import.meta.client && userStore.hasSelectedClientIdentity) {
            if (searchDisplayMode.value === SearchDisplayMode.PREVIEW) {
                if (isDisplayMobile.value) {
                    setTopNavPreviewMobile();
                } else {
                    setTopNavPreviewDesktop();
                }
            } else if (searchDisplayMode.value === SearchDisplayMode.FORM) {
                if (isDisplayMobile.value) {
                    setTopNavPreviewMobile();
                } else {
                    setTopNavPreviewDesktop();
                }
            } else {
                setTopNavPreviewDesktop();
            }
        }
    }

    function onDisplayMobileChange() {
        onDisplayModeChange();
    }

    watch(searchDisplayMode, onDisplayModeChange, {immediate: true});
    watch(isDisplayMobile, onDisplayMobileChange, {immediate: true});

    return {
        searchDisplayMode,
        searchPreviewTarget,
        setSearchDisplayModeToForm,
        headerNavigationTabs,
        onClientOnboardingIsPresent,
    };
});
