import {computed} from 'vue';
import {useMediaQuery} from './useMediaQuery';
import type {BreakPointsTypes} from './types';

type BreakPointsAsUnionType = `${BreakPointsTypes}`;
type MobileBreakpoints = Extract<BreakPointsAsUnionType, 'Tiny' | 'Xxs' | 'Xs' | 'Sm'>;

export function useDisplayMobile(max: MobileBreakpoints = 'Sm') {
    const {matchMedia} = useMediaQuery();

    const isDisplayMobile = computed<boolean>(() => {
        if (max === 'Tiny') {
            return matchMedia.value.max.Tiny;
        } else if (max === 'Xxs') {
            return matchMedia.value.max.Xxs;
        } else if (max === 'Xs') {
            return matchMedia.value.max.Xs;
        } else {
            return matchMedia.value.max.Sm;
        }
    });

    return {
        isDisplayMobile,
    };
}
