/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PunchoutDetails
 */
export interface PunchoutDetails {
    /**
     * 
     * @type {boolean}
     * @memberof PunchoutDetails
     */
    punchoutEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PunchoutDetails
     */
    procurementAppName?: string;
    /**
     * 
     * @type {string}
     * @memberof PunchoutDetails
     */
    procurementAppUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PunchoutDetails
     */
    showPunchoutBadge?: boolean;
}

/**
 * Check if a given object implements the PunchoutDetails interface.
 */
export function instanceOfPunchoutDetails(value: object): value is PunchoutDetails {
    return true;
}

export function PunchoutDetailsFromJSON(json: any): PunchoutDetails {
    return PunchoutDetailsFromJSONTyped(json, false);
}

export function PunchoutDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PunchoutDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'punchoutEnabled': json['punchoutEnabled'] == null ? undefined : json['punchoutEnabled'],
        'procurementAppName': json['procurementAppName'] == null ? undefined : json['procurementAppName'],
        'procurementAppUrl': json['procurementAppUrl'] == null ? undefined : json['procurementAppUrl'],
        'showPunchoutBadge': json['showPunchoutBadge'] == null ? undefined : json['showPunchoutBadge'],
    };
}

export function PunchoutDetailsToJSON(value?: PunchoutDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'punchoutEnabled': value['punchoutEnabled'],
        'procurementAppName': value['procurementAppName'],
        'procurementAppUrl': value['procurementAppUrl'],
        'showPunchoutBadge': value['showPunchoutBadge'],
    };
}

