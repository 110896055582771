/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { Photo } from './';
import {
    PhotoFromJSON,
    PhotoFromJSONTyped,
    PhotoToJSON,
} from './';
import type { PunchoutDetails } from './';
import {
    PunchoutDetailsFromJSON,
    PunchoutDetailsFromJSONTyped,
    PunchoutDetailsToJSON,
} from './';
import type { ServiceLevelType } from './';
import {
    ServiceLevelTypeFromJSON,
    ServiceLevelTypeFromJSONTyped,
    ServiceLevelTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserIdentity
 */
export interface UserIdentity {
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    type: UserIdentityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    organizationId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    organizationName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserIdentity
     */
    whitelabellingEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    organizationLogoUrl?: string;
    /**
     * 
     * @type {Photo}
     * @memberof UserIdentity
     */
    photo?: Photo;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    fullName?: string;
    /**
     * 
     * @type {PunchoutDetails}
     * @memberof UserIdentity
     */
    punchoutDetails?: PunchoutDetails;
    /**
     * 
     * @type {ServiceLevelType}
     * @memberof UserIdentity
     */
    serviceLevel?: ServiceLevelType;
}


/**
 * @export
 */
export const UserIdentityTypeEnum = {
    Client: 'CLIENT',
    Freelance: 'FREELANCE',
    Undefined: 'UNDEFINED'
} as const;
export type UserIdentityTypeEnum = typeof UserIdentityTypeEnum[keyof typeof UserIdentityTypeEnum];


/**
 * Check if a given object implements the UserIdentity interface.
 */
export function instanceOfUserIdentity(value: object): value is UserIdentity {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('label' in value) || value['label'] === undefined) return false;
    return true;
}

export function UserIdentityFromJSON(json: any): UserIdentity {
    return UserIdentityFromJSONTyped(json, false);
}

export function UserIdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserIdentity {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'type': json['type'],
        'label': json['label'],
        'organizationId': json['organizationId'] == null ? undefined : json['organizationId'],
        'organizationName': json['organizationName'] == null ? undefined : json['organizationName'],
        'whitelabellingEnabled': json['whitelabellingEnabled'] == null ? undefined : json['whitelabellingEnabled'],
        'organizationLogoUrl': json['organizationLogoUrl'] == null ? undefined : json['organizationLogoUrl'],
        'photo': json['photo'] == null ? undefined : PhotoFromJSON(json['photo']),
        'fullName': json['fullName'] == null ? undefined : json['fullName'],
        'punchoutDetails': json['punchoutDetails'] == null ? undefined : PunchoutDetailsFromJSON(json['punchoutDetails']),
        'serviceLevel': json['serviceLevel'] == null ? undefined : ServiceLevelTypeFromJSON(json['serviceLevel']),
    };
}

export function UserIdentityToJSON(value?: UserIdentity | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'type': value['type'],
        'label': value['label'],
        'organizationId': value['organizationId'],
        'organizationName': value['organizationName'],
        'whitelabellingEnabled': value['whitelabellingEnabled'],
        'organizationLogoUrl': value['organizationLogoUrl'],
        'photo': PhotoToJSON(value['photo']),
        'fullName': value['fullName'],
        'punchoutDetails': PunchoutDetailsToJSON(value['punchoutDetails']),
        'serviceLevel': ServiceLevelTypeToJSON(value['serviceLevel']),
    };
}

