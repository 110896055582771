export function getDays(format = 'long', startSunday = true, day) {
    // Create month names
    const dayNames = [0, 1, 2, 3, 4, 5, 6].map((d) => {
        return new Date(2000, 1, d).toLocaleString({}, {weekday: format});
    });

    if (startSunday) {
        dayNames.unshift(dayNames.pop());
    }
    // Return day name (or all of them)
    if (day) {
        return dayNames[day - 1];
    }
    return dayNames;
}
