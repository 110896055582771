import {useNuxtApp} from '#imports';
import {computed} from 'vue';
import {useNotifications} from '../../../notifications/notifications.store';
import {useUser} from '../../../user/user.store';
import {useFilteredTabs} from '../useFilteredTabs';
import type {NavigationTabsRecord} from './navigation.types';
import {
    AnalyticsIcon,
    FindFreelancerIcon,
    InboxIcon,
    InvoiceIcon,
    ManageProjectsIcon,
    SettingsIcon,
    FreelancerPool,
    CompanyIcon,
    InviteFreelancerIcon,
    InviteTeamMemberIcon,
} from '@navbar-logged/common/icons';
import type {NavigationTab} from '../../aside-menu.types';
import {useFeatureFlagsStoreLogged} from '@navbar-registry';
import {useABTestStore} from '@navbar-logged/store/abtest-logged.store';

export function useClientNavigationTabs(): NavigationTabsRecord {
    const {$i18next} = useNuxtApp();
    const {features} = useFeatureFlagsStoreLogged();
    const newRepatriationEntrypointEnabled = features['repatriation-opportunity-entrypoint-v2'];
    const isAdminConsoleEnabled = features['organization-settings-admin-console'];

    const userStore = useUser();
    const notificationsStore = useNotifications();
    const {getVariation} = useABTestStore();

    // - Identities
    const isAdmin = computed<boolean>(() => !!userStore.user?.options.admin || !!userStore.user?.options.platformAdmin);
    const maySeeMembers = computed<boolean>(() => !!userStore.user?.options.maySeeMembers);
    const organizationId = computed(() => userStore.selectedIdentity?.organizationId);

    // - Reduced tabs

    const logoNavigationRoute = computed(() => '/');

    const newAbTestTabOrganization = computed<NavigationTab[]>(() => {
        return getVariation('invite-team-member') === 'treatment'
            ? [
                  {
                      id: 'Company',
                      title: $i18next.t('navbar.aside.tabs.title.company'),
                      icons: {
                          mainIcon: CompanyIcon,
                      },
                      separator: 'top',
                      children: [
                          {
                              id: 'CompanySettings',
                              title: $i18next.t('navbar.aside.tabs.title.company-settings'),
                              url: {
                                  href: `/client-settings`,
                              },
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              isActiveWhen: ({startWith, matchRegXp}) => matchRegXp(/\/client-settings\/(?!team\/?$).+$/),
                              notificationsCount: notificationsStore.notificationsRecord?.companySettings,
                          },
                          {
                              id: 'OrganizationSettings',
                              title: $i18next.t('navbar.aside.tabs.title.organization-settings'),
                              url: {
                                  href: `/client-settings/organization/${organizationId.value}`,
                              },
                              constraint: isAdmin.value && !!organizationId.value && isAdminConsoleEnabled,
                              isActiveWhen: ({startWith}) => startWith('/client-settings/organization'),
                          },
                      ],
                  },
                  {
                      id: 'MyTeam',
                      icons: {
                          mainIcon: InviteTeamMemberIcon,
                      },
                      title: $i18next.t('navbar.aside.tabs.title.invite.team.members'),
                      url: {href: `/client-settings/team?loggedNavbar=true`},
                      constraint: maySeeMembers.value,
                      isActiveWhen: ({startWith}: any) => startWith('/client-settings/team'),
                  },
              ]
            : [
                  {
                      id: 'Company',
                      title: $i18next.t('navbar.aside.tabs.title.company'),
                      icons: {
                          mainIcon: CompanyIcon,
                      },
                      separator: 'top',
                      children: [
                          {
                              id: 'MyTeam',
                              title: $i18next.t('navbar.aside.tabs.title.my.team'),
                              url: {href: `/client-settings/team`},
                              constraint: maySeeMembers.value,
                              isActiveWhen: ({startWith}) => startWith('/client-settings/team'),
                          },
                          {
                              id: 'CompanySettings',
                              title: $i18next.t('navbar.aside.tabs.title.company-settings'),
                              url: {
                                  href: `/client-settings`,
                              },
                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              isActiveWhen: ({startWith, matchRegXp}) => matchRegXp(/\/client-settings\/(?!team\/?$).+$/),
                              notificationsCount: notificationsStore.notificationsRecord?.companySettings,
                          },
                          {
                              id: 'OrganizationSettings',
                              title: $i18next.t('navbar.aside.tabs.title.organization-settings'),
                              url: {
                                  href: `/client-settings/organization/${organizationId.value}`,
                              },
                              constraint: isAdmin.value && !!organizationId.value && isAdminConsoleEnabled,
                              isActiveWhen: ({startWith}) => startWith('/client-settings/organization'),
                          },
                      ],
                  },
              ];
    });

    const allNavigationRoutes = computed((): NavigationTab[] => {
        // Error 500 bug randomly appearing https://maltcommunity.slack.com/archives/C03PWC9DPGE/p1683204841095379
        if ($i18next) {
            const messagesNavigationTab: NavigationTab = {
                id: 'Messages',
                title: $i18next.t('navbar.aside.tabs.title.messages'),
                url: {href: '/messages'},
                icons: {
                    mainIcon: InboxIcon,
                },
                notificationsCount: notificationsStore.notificationsRecord?.messages,
                isActiveWhen: ({startWith}) => startWith('/messages'),
            };

            const manageJobsNavigationItem: NavigationTab = {
                id: 'ManageJobs',
                title: $i18next.t('navbar.aside.tabs.title.manage.jobs'),
                icons: {
                    mainIcon: ManageProjectsIcon,
                },
                children: [
                    {
                        id: 'FollowUpApplications',
                        title: $i18next.t('navbar.aside.tabs.title.follow.up.applications'),
                        url: {href: '/project-proposal-summaries'},
                        notificationsCount: notificationsStore.notificationsRecord?.sourcingActivities,
                        customAttrs: {'data-client-educational-pattern-applications-tour': 'true'},
                        isActiveWhen: ({startWith, matchRegXp}) =>
                            startWith('/customer/searches') ||
                            startWith('/client/sourcing-projects') ||
                            startWith('/project-proposal-summaries') ||
                            matchRegXp(/\/invite-freelancer\/.+$/),
                    },
                    {
                        id: 'Applications',
                        title: $i18next.t('navbar.aside.tabs.title.ongoing.jobs'),
                        url: {
                            href: '/project-client/?filter=owner',
                        },
                        notificationsCount: notificationsStore.notificationsRecord?.projectActions,
                        isActiveWhen: ({startWith}) =>
                            startWith('/client/missions/') || startWith('/proposal/') || startWith('/mission/') || startWith('/project-client'),
                    },
                ],
            };
            return [
                messagesNavigationTab,
                {
                    id: 'FindFreelancer',
                    title: $i18next.t('navbar.aside.tabs.title.find.freelancer'),
                    url: {href: '/project-client/find-freelancers'},
                    icons: {
                        mainIcon: FindFreelancerIcon,
                    },
                    isActiveWhen: ({startWith}) => startWith('/project-client/find-freelancers'),
                },
                {
                    id: 'InviteFreelancer',
                    title: $i18next.t('navbar.aside.tabs.title.invite.freelancer'),
                    customAttrs: {'data-client-onboarding-invite-freelancer': 'true'},
                    icons: {
                        mainIcon: InviteFreelancerIcon,
                    },
                    url: {
                        href: '/invite-freelancer/?pageSource=lateral_navigation',
                    },
                    constraint: newRepatriationEntrypointEnabled,
                    isActiveWhen: ({exact}) => exact('/invite-freelancer/'),
                },
                isAdmin.value || !newRepatriationEntrypointEnabled
                    ? {
                          id: 'HireFreelancers',
                          title: $i18next.t('navbar.aside.tabs.title.manage.freelancers'),
                          icons: {
                              mainIcon: FreelancerPool,
                          },
                          children: [
                              {
                                  id: 'FreelancerPool',
                                  title: $i18next.t('navbar.aside.tabs.title.freelancer.pool'),
                                  customAttrs: {'data-client-onboarding-freelancer-pool': 'true'},
                                  url: {
                                      href: '/client/freelancers',
                                  },
                                  isActiveWhen: ({startWith, matchRegXp}) =>
                                      startWith('/customer/freelancers') || matchRegXp(/^\/client\/freelancers(?!\/workforce).*$/),
                              },
                              {
                                  id: 'InviteFreelancer',
                                  title: $i18next.t('navbar.aside.tabs.title.invite.freelancer'),
                                  customAttrs: {'data-client-onboarding-invite-freelancer': 'true'},
                                  url: {
                                      href: '/invite-freelancer/?pageSource=lateral_navigation',
                                  },
                                  constraint: !newRepatriationEntrypointEnabled,
                                  isActiveWhen: ({exact}) => exact('/invite-freelancer/'),
                              },
                              {
                                  id: 'LegalDocuments',
                                  title: $i18next.t('navbar.aside.tabs.title.legal.documents'),
                                  constraint: isAdmin.value,
                                  url: {
                                      href: '/client/freelancers/workforce/',
                                  },

                                  isActiveWhen: ({startWith}) => startWith('/client/freelancers/workforce'),
                              },
                          ],
                      }
                    : {
                          id: 'HireFreelancers',
                          title: $i18next.t('navbar.aside.tabs.title.freelancer.pool'),
                          customAttrs: {'data-client-onboarding-freelancer-pool': 'true'},
                          url: {
                              href: '/client/freelancers',
                          },
                          icons: {
                              mainIcon: FreelancerPool,
                          },
                          isActiveWhen: ({startWith}) => startWith('/customer/freelancers') || startWith('/client/freelancers'),
                      },
                manageJobsNavigationItem,
                {
                    id: 'Monitoring',
                    title: '--',
                    constraint: isAdmin.value,
                    ghostChildren: [
                        {
                            id: 'Accounting',
                            title: $i18next.t('navbar.aside.tabs.title.accounting'),
                            url: {
                                href: `/invoicing/company/${userStore.selectedIdentity?.companyId}`,
                            },
                            icons: {
                                mainIcon: InvoiceIcon,
                            },
                            isActiveWhen: ({matchRegXp}) => matchRegXp(/^\/client\/.+\/invoices/) || matchRegXp(/^\/invoicing\/company\/.+/),
                        },
                        {
                            id: 'Reporting',
                            title: $i18next.t('navbar.aside.tabs.title.reporting'),
                            constraint: isAdmin.value,
                            url: {
                                href: `/dashboard-client/analytics`,
                            },
                            icons: {
                                mainIcon: AnalyticsIcon,
                            },
                            isActiveWhen: ({startWith}) => startWith('/dashboard-client/analytics'),
                        },
                    ],
                },
                ...newAbTestTabOrganization.value,
                {
                    id: 'AccountSettings',
                    title: $i18next.t('navbar.aside.tabs.title.account.settings'),
                    url: {href: '/account'},
                    icons: {
                        mainIcon: SettingsIcon,
                    },
                    isActiveWhen: ({startWith}) => startWith('/account'),
                },
            ];
        }
        return [];
    });

    const {filteredNavigationRoutes} = useFilteredTabs(allNavigationRoutes);

    return {
        logoNavigationRoute,
        allNavigationRoutes,
        filteredNavigationRoutes,
    };
}
