import {defineStore} from 'pinia';
import {ref, watch} from 'vue';
import {useActiveRoute, useNavigationTabs} from '../composables';

export const useExpandedTabs = defineStore('navbar-expanded-tabs', () => {
    /** Store ids of expanded tabs, and keep it in storage  */
    const expandedTabs = ref<string[]>([]);
    const {allNavigationRoutes, filteredNavigationRoutes} = useNavigationTabs();

    function toggleExpandTab(id: string) {
        if (allNavigationRoutes.value.find((tab) => !!tab.children && tab.id === id)) {
            if (expandedTabs.value.includes(id)) {
                expandedTabs.value = expandedTabs.value.filter((tabId) => tabId !== id);
            } else {
                expandedTabs.value = [id];
                // Replace with this line you want to have the bahviour to not close all other tabs
                // expandedTabs.value.push(id);
            }
        }
    }

    function expandTab(id: string) {
        if (allNavigationRoutes.value.find((tab) => !!tab.children && tab.id === id)) {
            expandedTabs.value = [id];
        }
    }

    function closeAll() {
        expandedTabs.value = [];
    }

    const {currentActiveRoute} = useActiveRoute();

    function handleActiveRouteChange() {
        if (currentActiveRoute.value) {
            const getParentTab = filteredNavigationRoutes.value.find(
                (tab) => !!tab.children && !!tab.children.find((child) => child.id === currentActiveRoute.value),
            );
            if (getParentTab && !expandedTabs.value.includes(getParentTab.id)) {
                toggleExpandTab(getParentTab.id);
            }
        }
    }

    watch([currentActiveRoute], handleActiveRouteChange);
    handleActiveRouteChange();

    return {
        expandedTabs,
        expandTab,
        closeAll,
        toggleExpandTab,
    };
});
