<template>
    <MaltImg v-if="typeof props.icon === 'string'" :alt="props.icon" :src="`/navbar/icons/${props.icon}.svg`" :height="size" :width="size" />
    <Component :is="props.icon" v-else :active="active" :name="name" :size="size" />
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {commonIconProps} from '../icons/common-props';

    const props = defineProps({
        ...commonIconProps,
        // any, dirty sorry
        icon: {},
    });
</script>
