import type {LocationItem} from '../search.types';

function hasProperty<T extends string>(element: unknown, property: T): element is Record<T, unknown> {
    if (element === undefined || element === null) {
        return false;
    }
    return Object.prototype.hasOwnProperty.call(element, property);
}

function isFilledKeyText(field: any): field is string {
    return typeof field === 'string' && field !== '';
}

type GetSearchURLParams = {
    baseURLLink: string;
    query: string;
    location: LocationItem | null;
    sourceComponent?: string; // to track the search_started Amplitude event
};

export function redirectToSearch({baseURLLink, location, query, sourceComponent}: GetSearchURLParams) {
    if (baseURLLink == null || !isFilledKeyText(baseURLLink)) {
        return window.location.href;
    }

    const searchParams = new URLSearchParams();
    searchParams.set('q', query);
    if (location) {
        searchParams.set('as', 't');
        for (const key in location) {
            const value: LocationItem[keyof LocationItem] | undefined = location[key as keyof LocationItem];
            if (value !== null && value !== undefined) {
                if (key === 'formattedAddress' && typeof value === 'string') {
                    searchParams.set('location', value);
                } else if (
                    key === 'loc' &&
                    hasProperty(value, 'lat') &&
                    typeof value.lat === 'number' &&
                    hasProperty(value, 'lon') &&
                    typeof value.lon === 'number'
                ) {
                    searchParams.set('lat', value.lat.toString());
                    searchParams.set('lon', value.lon.toString());
                } else if (typeof value === 'string' || typeof value === 'boolean') {
                    searchParams.set(key, value.toString());
                }
            }
        }
    }

    if (sourceComponent) {
        searchParams.set('sourceComponent', sourceComponent);
    }

    return `${baseURLLink}${baseURLLink.includes('?') ? '&' : '?'}${searchParams.toString()}`;
}
