import {parse} from 'date-fns';
import {fromZonedTime} from 'date-fns-tz';
export class WrongFormattedDateError extends Error {
    constructor(msg: string) {
        super(msg);
        Object.setPrototypeOf(this, WrongFormattedDateError.prototype);
    }
}

export function stringToDate(stringValue: string, iso8601: boolean, datePattern: string, datePatternRegex: string): Date | undefined {
    if (!stringValue) {
        return;
    }

    if (iso8601) {
        return fromZonedTime(new Date(stringValue), 'UTC');
    }

    if (typeof stringValue == 'string' && !stringValue.match(datePatternRegex)) {
        throw new WrongFormattedDateError(`Unable to parse: ${stringValue}\nPattern used: ${datePatternRegex}`);
    }

    const dateValue = parse(stringValue, datePattern, new Date());

    if (isNaN(dateValue.valueOf())) {
        throw new WrongFormattedDateError(`Unable to parse: ${stringValue}\nPattern used: ${datePattern}`);
    }

    return fromZonedTime(dateValue, 'UTC');
}
