<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <clipPath id="a"><path d="m0 .191406h18v18h-18z" /></clipPath>
                <g clip-path="url(#a)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                    <path
                        d="m3 5.12138v11.41002c0 .46.37.83.83.83h11.04c.46 0 .83-.37.83-.83v-12.91002c0-.46-.37-.83-.83-.83h-11.87"
                        stroke-width="1.5"
                    />
                    <path d="m5.82007 3.05139.26 1.04c.15.48.06.87.97.87h3.03003 1.65c.68 0 .77-.38.92-.87l.26-1.04" stroke-width="1.5" />
                    <path d="m8.28003 2.44141v-.41c0-.6.49-1.090004 1.09-1.090004s1.08997.490004 1.08997 1.090004v.41" stroke-width="1.5" />
                    <g stroke-width="1.2">
                        <path d="m5.6001 10.9214h7.46" />
                        <path d="m5.64014 13.9014h7.45996" />
                        <path d="m10.3801 7.49135 1.17 1.06 1.55-1.92" />
                        <path d="m5.64014 7.9314h2.98" />
                    </g>
                </g>
            </svg>
        </template>
        <template #active>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <clipPath id="a"><path d="m0 .191406h18v18h-18z" /></clipPath>
                <g clip-path="url(#a)">
                    <path d="m5.80005 3.30139.26 1.04c.15.48.06.87.97.87h3.02995 1.65c.77 0 .77-.38.92-.87l.26-1.04" fill="currentColor" />
                    <path
                        d="m5.80005 3.30139.26 1.04c.15.48.06.87.97.87h3.02995 1.65c.77 0 .77-.38.92-.87l.26-1.04"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                    />
                    <path d="m8.26001 2.69141v-.41c0-.6.49-1.09 1.09-1.09s1.08999.49 1.08999 1.09v.41" fill="currentColor" />
                    <g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                        <path d="m8.26001 2.69141v-.41c0-.6.49-1.09 1.09-1.09s1.08999.49 1.08999 1.09v.41" stroke-width="1.5" />
                        <path d="m10.3601 7.74135 1.16 1.06 1.56-1.92" stroke-width="1.2" />
                        <path d="m5.62012 8.1814h2.98" stroke-width="1.2" />
                        <path d="m5.62012 11.1714s.77 2.98 3.76 2.98c2.98998 0 3.69998-2.98 3.69998-2.98z" stroke-width="1.2" />
                        <path
                            d="m3 5.37138v11.41002c0 .46.37.83.83.83h11.04c.46 0 .83-.37.83-.83v-12.91002c0-.46-.37-.83-.83-.83h-11.87"
                            stroke-width="1.5"
                        />
                    </g>
                </g>
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
