import {getPageCountryCode} from '@malt/dom';
import {computed, onMounted, ref, useTranslation} from '#imports';

export function useRemoteLabels() {
    const {t} = useTranslation();
    const countryCode = ref<string | undefined>('');

    onMounted(() => {
        countryCode.value = getPageCountryCode();
    });

    const remoteCountryLabel = computed(() => t(`search.location.remote.${countryCode.value}` as any));

    return {
        remoteCountryLabel,
    };
}
