import {FetchError, type NotificationsResource} from '@navbar-api';
import type {Ref} from 'vue';
import {getCurrentInstance, onBeforeUnmount, ref, onMounted} from 'vue';
import {defineStore, storeToRefs} from 'pinia';
import {createWorker} from './notifications.workerfactory';
import type {MessageFromBrowserInstance, MessageFromWorker} from './notifications.worker';
import {NavbarContext, useFeatureFlagsStoreLogged} from '@navbar-registry';
import {useLogger} from '#imports';

const EVERY_MINUTE = 60000;

function setupSharedWorker(notificationsRecord: Ref<NotificationsResource | undefined>) {
    const worker = createWorker();
    const logger = useLogger();

    function postMessage(message: MessageFromBrowserInstance) {
        if (typeof message === 'object' && !message.timestamp) {
            // useful for debugging
            message.timestamp = new Date().getTime();
        }
        worker.port.postMessage(message);
    }

    worker.port.addEventListener('message', (e: MessageEvent<MessageFromWorker>) => {
        // apparently MessageEvent.origin is empty is used when the origin is the same as the current script
        if (e.origin !== '' && e.origin !== window.location.origin) {
            // There's no reason why we would receive a message from another origin than that of the worker,
            // which we spawned ourselves from the same origin.
            logger.error(`Message received from unexpected origin: ${e.origin}`);
            return;
        }

        // uncomment for debugging:
        // console.log(`Received from worker: ${JSON.stringify(e.data)}`);

        if (e.data.type === 'notifications-update') {
            notificationsRecord.value = e.data.notifications;
        } else {
            logger.error(`Unexpected message: ${JSON.stringify(e.data)}`);
        }
    });

    function publishCloseMessage() {
        postMessage({type: 'before-port-close'});
    }

    window.addEventListener('beforeunload', publishCloseMessage);
    worker.port.start();

    return {
        close() {
            publishCloseMessage();
            worker.port.close();
        },
        requestNotificationsUpdate() {
            postMessage({type: 'notifications-update-request'});
        },
        updateSubscription(active: boolean) {
            postMessage({type: 'subscription', active});
        },
    };
}

function setupSharedWorkerSse(notificationsRecord: Ref<NotificationsResource | undefined>) {
    const worker = new SharedWorker(new URL('./shared-worker-sse.ts', import.meta.url), {type: 'module', name: 'sse-notifications-worker'});
    const logger = useLogger();

    function postMessage(message: MessageFromBrowserInstance) {
        if (typeof message === 'object' && !message.timestamp) {
            // useful for debugging
            message.timestamp = new Date().getTime();
        }
        worker.port.postMessage(message);
    }

    worker.port.addEventListener('message', (e: MessageEvent<MessageFromWorker>) => {
        // apparently MessageEvent.origin is empty is used when the origin is the same as the current script
        if (e.origin !== '' && e.origin !== window.location.origin) {
            // There's no reason why we would receive a message from another origin than that of the worker,
            // which we spawned ourselves from the same origin.
            logger.error(`Message received from unexpected origin: ${e.origin}`);
            return;
        }

        // uncomment for debugging:
        // console.log(`Received from worker: ${JSON.stringify(e.data)}`);

        if (e.data.type === 'notifications-update') {
            notificationsRecord.value = e.data.notifications;
        } else {
            logger.error(`Unexpected message: ${JSON.stringify(e.data)}`);
        }
    });

    function publishCloseMessage() {
        postMessage({type: 'before-port-close'});
    }

    window.addEventListener('beforeunload', publishCloseMessage);
    worker.port.start();

    return {
        close() {
            publishCloseMessage();
            worker.port.close();
        },
        requestNotificationsUpdate() {
            postMessage({type: 'notifications-update-request'});
        },
        updateSubscription(active: boolean) {
            postMessage({type: 'subscription', active});
        },
    };
}

function useSharedWorker(notificationsRecord: Ref<NotificationsResource | undefined>) {
    const worker = setupSharedWorker(notificationsRecord);

    function requestNotificationsUpdate() {
        worker.requestNotificationsUpdate();
    }

    function updateSubscriptionDependingOnDocumentVisibility() {
        const documentVisible = document.visibilityState === 'visible';
        worker.updateSubscription(documentVisible);
    }

    if (getCurrentInstance()) {
        onMounted(() => {
            document.addEventListener('visibilitychange', updateSubscriptionDependingOnDocumentVisibility);
        });
        onBeforeUnmount(() => {
            document.removeEventListener('visibilitychange', updateSubscriptionDependingOnDocumentVisibility);
            worker.close();
        });
    }

    return {
        startUpdating: updateSubscriptionDependingOnDocumentVisibility,
        updateNotifications: requestNotificationsUpdate,
    };
}

function useSseSharedWorker(notificationsRecord: Ref<NotificationsResource | undefined>) {
    const worker = setupSharedWorkerSse(notificationsRecord);

    function requestNotificationsUpdate() {
        worker.requestNotificationsUpdate();
    }

    function updateSubscriptionDependingOnDocumentVisibility() {
        const documentVisible = document.visibilityState === 'visible';
        worker.updateSubscription(documentVisible);
    }

    if (getCurrentInstance()) {
        onMounted(() => {
            updateSubscriptionDependingOnDocumentVisibility();
            document.addEventListener('visibilitychange', updateSubscriptionDependingOnDocumentVisibility);
        });
        onBeforeUnmount(() => {
            document.removeEventListener('visibilitychange', updateSubscriptionDependingOnDocumentVisibility);
            worker.close();
        });
    }

    return {
        startUpdating: updateSubscriptionDependingOnDocumentVisibility,
        updateNotifications: requestNotificationsUpdate,
    };
}

function usePolling(notificationsRecord: Ref<NotificationsResource | undefined>) {
    async function fetchNotifications() {
        if (import.meta.server) {
            notificationsRecord.value = await NavbarContext.getNotifications();
        } else {
            try {
                notificationsRecord.value = await NavbarContext.getNotifications();
            } catch (e) {
                if (!(e instanceof FetchError && e.statusCode && [401, 403].includes(e.statusCode))) {
                    /* eslint-disable no-console */
                    console.error('Failed to fetch notifications with error: ', e);
                }
            }
        }
    }

    let fetchInterval: ReturnType<typeof setInterval> | null;

    function setFetchIntervalIfNotAlreadyDone() {
        if (!fetchInterval) {
            fetchInterval = setInterval(fetchNotifications, EVERY_MINUTE);
        }
    }

    function clearFetchIntervalIfNeeded() {
        if (fetchInterval) {
            clearInterval(fetchInterval);
            fetchInterval = null;
        }
    }

    function setOrClearFetchIntervalDependingOnDocumentVisibility() {
        const documentVisible = document.visibilityState === 'visible';
        if (documentVisible) {
            // noinspection JSIgnoredPromiseFromCall
            fetchNotifications();
            setFetchIntervalIfNotAlreadyDone();
        } else {
            clearFetchIntervalIfNeeded();
        }
    }

    if (getCurrentInstance()) {
        onMounted(() => {
            document.addEventListener('visibilitychange', setOrClearFetchIntervalDependingOnDocumentVisibility);
        });
        onBeforeUnmount(() => {
            document.removeEventListener('visibilitychange', setOrClearFetchIntervalDependingOnDocumentVisibility);
            clearFetchIntervalIfNeeded();
        });
    }

    return {
        startUpdating: setOrClearFetchIntervalDependingOnDocumentVisibility,
        updateNotifications: fetchNotifications,
    };
}

export const useNotifications = defineStore('navbar-notifications-store', () => {
    const notificationsRecord = ref<NotificationsResource>();
    const {features} = storeToRefs(useFeatureFlagsStoreLogged());

    const isClientSide = import.meta.client;
    const userAgent = isClientSide ? navigator.userAgent : undefined;
    // Even though the latest Safari versions (16.x.y at the time of writing) now officially supports
    // Shared Workers, we observed many cases where the Shared Worker is restarted (apparently, still under
    // investigation). It may be linked to the OS used also.
    const isSafari = userAgent && userAgent.includes('Safari/') && !userAgent.includes('Chrome/') && !userAgent.includes('Chromium/');
    const shouldUseSharedWorker = isClientSide && typeof window.SharedWorker !== 'undefined' && !isSafari;
    const sseWorker = shouldUseSharedWorker && useSseSharedWorker(notificationsRecord);

    function internalStartUpdating() {
        if (features.value['sse-navbar'] && sseWorker) {
            return sseWorker.startUpdating;
        }

        const {startUpdating} = shouldUseSharedWorker ? useSharedWorker(notificationsRecord) : usePolling(notificationsRecord);
        return startUpdating();
    }

    function internalUpdateNotifications() {
        if (features.value['sse-navbar'] && sseWorker) {
            return sseWorker.updateNotifications;
        }

        const {updateNotifications} = shouldUseSharedWorker ? useSharedWorker(notificationsRecord) : usePolling(notificationsRecord);

        if (getCurrentInstance()) {
            onMounted(() => {
                window.addEventListener('notification-task-completed', updateNotifications);
            });

            onBeforeUnmount(() => {
                if (updateNotifications) {
                    window.removeEventListener('notification-task-completed', updateNotifications);
                }
            });
        }
    }

    return {
        notificationsRecord,
        startUpdating: internalStartUpdating,
        updateNotifications: internalUpdateNotifications,
    };
});
