<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <clipPath id="a"><path d="m0 .191406h18v18h-18z" /></clipPath>
                <g clip-path="url(#a)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                    <g stroke-width="1.2">
                        <path d="m11.8156 8.35136c-.45 1.21-1.45 2.06004-2.60001 2.06004-1.3 0-2.4-1.06004-2.74-2.52004" />
                        <path d="m6.46558 5.98141c.38-1.4 1.45-2.41 2.71-2.41 1.26002 0 2.29002.96 2.69002 2.31" />
                        <path d="m5.53564 7.64136h4.05" />
                        <path d="m5.53564 5.98145h4.05" />
                    </g>
                    <path
                        d="m8.92553 14.4415c1.22997 0 2.24997-.87 2.24997-1.94h5.53s.19.1.15.39c-.1.74-.31 2.35-.39 3.06-.11.94-.55 1.44-1.72 1.44s-5.30997 0-5.30997 0-5.15 0-6.32 0-1.61-.5-1.72-1.44c-.08-.71-.29-2.32-.39-3.06-.039997-.29.15-.39.15-.39h5.52c0 1.07 1.02 1.94 2.25 1.94z"
                        stroke-width="1.48"
                    />
                    <path d="m3.07544 9.58141v-7.26c0-.75.38-1.13.83-1.13h10.03996c.46 0 .83.38.83.83v10.33999" stroke-width="1.5" />
                </g>
            </svg>
        </template>
        <template #active>
            <svg fill="none" height="19" viewBox="0 0 18 19" width="18" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <clipPath id="a"><path d="m0 .191406h18v18h-18z" /></clipPath>
                <g clip-path="url(#a)" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
                    <path d="m3.06543 9.33141v-7.26c0-.75.38-1.130004.83-1.130004h10.04997c.46 0 .83.380004.83.830004v10.33999" stroke-width="1.5" />
                    <path
                        d="m8.92553 14.0514c1.22997 0 2.24997-.8699 2.24997-1.9399h5.53s.19.1.15.39c-.1.74-.31 2.3499-.39 3.06-.11.94-.55 1.4399-1.72 1.4399s-5.30997 0-5.30997 0-5.15 0-6.32 0-1.61-.4999-1.72-1.4399c-.08-.7101-.29-2.32-.39-3.06-.039997-.29.15-.39.15-.39h5.52c0 1.07 1.02 1.9399 2.25 1.9399z"
                        fill="currentColor"
                        stroke-width="1.5"
                    />
                    <path d="m11.5254 7.96142c-.45 1.21-1.45 2.05998-2.59997 2.05998-1.15 0-2.16-.84998-2.61-2.06998" stroke-width="1.2" />
                </g>
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
