<template>
    <NavigationTabItem :tab="navigationTabProps" :hide-collapse-on-full="true" />
    <LanguageSelectorModal v-if="showLanguageSelectorModal" />
</template>

<script setup lang="ts">
    import {computed} from 'vue';
    import {storeToRefs} from 'pinia';
    import type {NavigationTab} from '@navbar-logged/features/aside-menu';
    import {NavigationTabItem} from '@navbar-logged/features/aside-menu';
    import {useLanguageSelector} from '../language-selector.store';
    import LanguageSelectorModal from './LanguageSelectorModal.vue';

    const languageSelectorStore = useLanguageSelector();
    const {selectedCountryFlag, selectedLanguage, showLanguageSelectorModal} = storeToRefs(languageSelectorStore);

    const navigationTabProps = computed<NavigationTab>(() => ({
        id: 'Language',
        title: selectedLanguage.value.language.toUpperCase(),
        icons: {
            mainIcon: selectedCountryFlag.value,
        },
        action: () => {
            showLanguageSelectorModal.value = true;
        },
    }));
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
</style>
