<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.09961 17C2.09961 13.1708 5.18884 10.0667 8.99961 10.0667C12.8104 10.0667 15.8996 13.1708 15.8996 17M12.7152 4.73333C12.7152 6.7952 11.0518 8.46667 8.9998 8.46667C6.94785 8.46667 5.28442 6.7952 5.28442 4.73333C5.28442 2.67147 6.94785 1 8.9998 1C11.0518 1 12.7152 2.67147 12.7152 4.73333Z"
                    stroke="#333330"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </template>
        <template #active>
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_4260_61077)">
                    <path
                        d="M1.09961 17C1.09961 13.2 4.19961 10.1 7.99961 10.1C11.7996 10.1 14.8996 13.2 14.8996 17M11.6996 4.7C11.6996 6.8 9.99961 8.4 7.99961 8.4C5.99961 8.5 4.29961 6.8 4.29961 4.7C4.29961 2.7 5.99961 1 7.99961 1C10.0996 1 11.6996 2.7 11.6996 4.7Z"
                        stroke="#263035"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M8.00078 8.4C10.0442 8.4 11.7008 6.74345 11.7008 4.7C11.7008 2.65655 10.0442 1 8.00078 1C5.95733 1 4.30078 2.65655 4.30078 4.7C4.30078 6.74345 5.95733 8.4 8.00078 8.4Z"
                        fill="#263035"
                        stroke="#263035"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M10 5.59961C9.8 6.49961 9 7.09961 8 7.09961C7 7.09961 6.2 6.49961 6 5.59961"
                        stroke="white"
                        stroke-width="0.75"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_4260_61077">
                        <rect width="16" height="18" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
