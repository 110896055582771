<template>
    <form
        id="headerSearchV3"
        ref="headerSearchFormRef"
        class="header-search-form / flex"
        novalidate
        :class="{isDisplayMobile, zoomEffect: shouldAddZoomEffect}"
        @submit.prevent="handleSubmit"
    >
        <div class="header-search-form_inputs-container / flex" :class="{isFocused: isLocationFocused || isJobFocused}">
            <div class="search-input_wrapper search-input_wrapper_job / flex" :class="{isFocused: isJobFocused, isShadowed: isLocationFocused}">
                <JobAutocomplete />
            </div>
            <div v-if="!isDisplayMobile" class="search-input_wrapper_separation"></div>
            <div class="search-input_wrapper search-input_wrapper_location / flex" :class="{isFocused: isLocationFocused, isShadowed: isJobFocused}">
                <LocationAutocomplete />
                <div style="padding: 3px; height: 100%">
                    <VJoyButton
                        v-if="!isDisplayMobile"
                        class="header-search-form_submit_desktop"
                        type="submit"
                        variant="main"
                        size="small"
                        data-testid="header_search-form__submit"
                        title="Search"
                        icon="search"
                    />
                </div>
            </div>
        </div>
        <VJoyButton
            v-if="isDisplayMobile"
            class="header-search-form_submit_mobile"
            data-testid="header_search-form__submit"
            variant="main"
            type="submit"
        >
            {{ t('navbar.header.search.form.submit.button') }}
        </VJoyButton>
    </form>
</template>

<script setup lang="ts">
    import {computed, navbarEventBus, useTranslation} from '#imports';
    import {useDisplayMobile} from '#malt/nuxt-utils-module';
    import {storeToRefs} from 'pinia';
    import {onMounted, ref} from 'vue';
    import {useSearchForm} from '../search.store';
    import JobAutocomplete from './Inputs/JobAutocomplete.vue';
    import LocationAutocomplete from './Inputs/LocationAutocomplete.vue';
    import {VJoyButton} from '@maltjoy/core-vue';

    const headerSearchFormRef = ref<HTMLFormElement | null>(null);

    const {t} = useTranslation();

    const {isDisplayMobile} = useDisplayMobile();

    const searchFormStore = useSearchForm();
    const {v$, jobQuery, fullLocationSuggestion, isJobFocused, isLocationFocused, jobZoomEffect} = storeToRefs(searchFormStore);

    const shouldAddZoomEffect = computed(() => {
        return !isDisplayMobile.value && jobZoomEffect.value && isJobFocused.value;
    });

    function handleSubmit() {
        if (v$.value.$invalid) {
            if (v$.value.jobQuery.$invalid) {
                navbarEventBus.emit('focus-search-job-input');
            } else {
                navbarEventBus.emit('focus-search-location-input');
            }
        } else {
            searchFormStore.onSubmit();
            navbarEventBus.emit('navbar-search-form-submit');
        }
    }

    onMounted(() => {
        document.addEventListener('navbar:searchResetRequested', () => {
            document.dispatchEvent(
                new CustomEvent('navbar:searchRequested', {
                    bubbles: true,
                    detail: {query: jobQuery.value, location: fullLocationSuggestion.value},
                }),
            );
        });
    });
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/text';
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .header-search-form {
        $form: &;
        --input-height: 46px;
        display: flex;
        flex-flow: row nowrap;
        margin: 0 auto;
        border: 1px solid var(--joy-color-neutral-30);
        border-radius: 100px;
        background-color: #fff;
        max-width: 100%;
        box-shadow: var(--joy-core-elevation-1);
        height: 48px;

        &.zoomEffect {
            animation: scaleEffect 1s ease 1;
        }

        &_inputs-container {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            border-radius: 100px;
            box-shadow: none;
            width: 100%;
            padding: 0;
            transition: width 0.3s ease;

            &.isFocused {
                &:hover {
                    background-color: rgb(250, 250, 250);
                    .search-input_wrapper_separation {
                        opacity: 0;
                    }
                }
                .search-input_wrapper_separation {
                    opacity: 0;
                }
                @include mq.screen_xs {
                    background-color: unset;
                }
            }
        }

        &_submit {
            height: 100%;
            &_desktop {
                margin: 0;
                margin-left: auto;
                padding-right: 18px;
                padding-left: 18px;
                width: 40px;
                height: 40px;
                min-height: 0;

                :deep(.joy-button_icon) {
                    margin-right: 0 !important;
                }
            }
        }

        .search-input_wrapper {
            border-radius: 100px;
            padding-left: 20px;
            z-index: 1;
            transition: background-color 0.15s ease;
            height: 100%;

            &_separation {
                width: 1px;
                inset: 0;
                height: 60%;
                top: 20%;
                background-color: var(--joy-color-neutral-30);
                transition: opacity 0.3s ease;
            }

            &_job {
                width: 202px;
                padding-right: 20px;
            }

            &_location {
                width: 226px;

                @include mq.screen_xs {
                    padding-right: 20px;
                }
            }

            &:hover:not(.isFocused) {
                background-color: rgb(245, 245, 245);
                &.isShadowed {
                    background-color: rgb(235, 235, 235);
                }
            }

            &.isFocused {
                background-color: white;
                z-index: 2;
                box-shadow: var(--joy-core-elevation-2);
            }

            &:deep(input) {
                color: var(--joy-color-text-body);
            }
        }

        &.isDisplayMobile {
            flex-flow: column nowrap;
            background-color: unset;
            border-radius: 0;
            border: none;
            box-shadow: none;
            gap: var(--joy-core-spacing-3);

            #{$form}_inputs-container {
                flex-flow: column nowrap;

                .search-input_wrapper {
                    width: 100%;
                    padding: 0;

                    .field-autocomplete {
                        display: block;
                        width: 100%;
                    }
                    &.isFocused {
                        box-shadow: none;
                    }
                }

                &.isFocused {
                    background-color: unset;
                }
            }
        }
    }
    @keyframes scaleEffect {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
