<template>
    <div
        class="logo-wrapper flex"
        :class="{isReduced: isDisplayModeReduced, isMobile: isDisplayMobile, isMounted, isSmall: !isDisplayModeReduced && isSmall}"
    >
        <div class="logo-wrapper-mask">
            <a :href="asideMenuStore.logoNavigationRoute">
                <MaltImg
                    key="expanded"
                    class="logo-wrapper-mask_image"
                    src="/navbar/logos/malt-logo-red.svg?vh=9690b2"
                    width="125"
                    height="35"
                    alt="Malt logo"
                />
            </a>
        </div>
        <button v-if="!isDisplayModeFunnel" type="button" :aria-label="t('navbar.header.menu.close.mobile')" @click="handleCloseMobileNavBar">
            <!-- Used v-show here because of a strange Vue bug : 
            When resizing the following error happenned "TypeError: Cannot read properties of null (reading 'insertBefore')"
            Issue here : https://github.com/vuejs/core/issues/5657
            No other fix in mind for now, but take care to test the resize of your screen before changing this file.

            To reproduce you must : 
            - be on a SSR app (?) 
            - be on a small screen when the page is loading
            - resize to bigger screen
            - you'll see the error in the console  -->
            <VJoyIcon v-show="isDisplayMobile" name="cross" size="small" color="neutral" />
        </button>
    </div>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {useAsideMenu} from '@navbar-logged/features/aside-menu/aside-menu.store';
    import {storeToRefs} from 'pinia';
    import {useDebounceMount, useDisplayMobile} from '#malt/nuxt-utils-module';
    import {useDisplayMode} from '@navbar-logged/features/aside-menu/stores/display-mode.store';
    import {useTranslation} from '#imports';
    import {VJoyIcon} from '@maltjoy/core-vue';

    const {t} = useTranslation();
    const asideMenuStore = useAsideMenu();
    const displayModeStore = useDisplayMode();
    const {isDisplayModeReduced, isDisplayModeFunnel} = storeToRefs(displayModeStore);

    const {isDisplayMobile} = useDisplayMobile();

    defineProps({
        isSmall: {type: Boolean, default: false},
    });

    const {isMounted} = useDebounceMount(0, {mode: 'client'});

    function handleCloseMobileNavBar() {
        asideMenuStore.setMobileNavBarOpened(false);
    }
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .logo-wrapper {
        padding: var(--joy-core-spacing-5) var(--joy-core-spacing-5) var(--joy-core-spacing-6) var(--joy-core-spacing-5);
        margin-top: -4px;
        justify-content: space-between;
        align-items: center;

        &.isMounted {
            transition: padding 0.3s ease;
        }

        &-mask {
            width: auto;
            overflow: hidden;
            transition: width 0.3s ease;
            &_image {
                width: 124px;
                max-width: none;
            }
        }

        &.isReduced {
            .logo-wrapper-mask {
                width: 32px;
            }
        }

        &.isSmall {
            .logo-wrapper-mask {
                margin-left: var(--joy-core-spacing-1);

                img {
                    width: 70px;
                    height: 20px;
                }
            }
        }
    }
</style>
