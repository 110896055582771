
/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  ChangeStateRequest,
  ContextResource,
  NotificationsResource,
  SelectIdentityRequest,
  UserResource,
} from '../models';
import {
    ChangeStateRequestFromJSON,
    ChangeStateRequestToJSON,
    ContextResourceFromJSON,
    ContextResourceToJSON,
    NotificationsResourceFromJSON,
    NotificationsResourceToJSON,
    SelectIdentityRequestFromJSON,
    SelectIdentityRequestToJSON,
    UserResourceFromJSON,
    UserResourceToJSON,
} from '../models';

export interface ChangeNavbarStateRequest {
    changeStateRequest?: ChangeStateRequest;
}

export interface CheckRepatriationAllowedRequest {
    clientCompanyId: string;
}

export interface SelectIdentityOperationRequest {
    selectIdentityRequest?: SelectIdentityRequest;
}

/**
 * 
 */
export class NavbarApi extends runtime.BaseAPI {

    /**
     */
    async acknowledgeOnboardingRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<void>({
            path: `/navbar/api/acknowledgeOnboarding`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return response;
    }

    /**
     */
    async acknowledgeOnboarding(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<void> {
        await this.acknowledgeOnboardingRaw(initOverrides);
    }

    /**
     */
    async changeNavbarStateRaw(requestParameters: ChangeNavbarStateRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<void>({
            path: `/navbar/api/state`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeStateRequestToJSON(requestParameters['changeStateRequest']),
        }, initOverrides);

        return response;
    }

    /**
     */
    async changeNavbarState(requestParameters: ChangeNavbarStateRequest = {}, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<void> {
        await this.changeNavbarStateRaw(requestParameters, initOverrides);
    }

    /**
     * Check if company can create repatriation opportunities
     */
    async checkRepatriationAllowedRaw(requestParameters: CheckRepatriationAllowedRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<boolean>> {
        if (requestParameters['clientCompanyId'] == null) {
            throw new runtime.RequiredError(
                'clientCompanyId',
                'Required parameter "clientCompanyId" was null or undefined when calling checkRepatriationAllowed().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<boolean>({
            path: `/navbar/api/company/{clientCompanyId}/check-repatriation-allowed`.replace(`{${"clientCompanyId"}}`, encodeURIComponent(String(requestParameters['clientCompanyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return response;
    }

    /**
     * Check if company can create repatriation opportunities
     */
    async checkRepatriationAllowed(requestParameters: CheckRepatriationAllowedRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.checkRepatriationAllowedRaw(requestParameters, initOverrides);
        return response._data;
    }

    /**
     */
    async getContextRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<ContextResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<ContextResource>({
            path: `/navbar/api/context`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=ContextResourceFromJSON(response._data); }
        return response;
    }

    /**
     */
    async getContext(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<ContextResource> {
        const response = await this.getContextRaw(initOverrides);
        return response._data;
    }

    /**
     */
    async getNotificationsRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<NotificationsResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<NotificationsResource>({
            path: `/navbar/api/notifications`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=NotificationsResourceFromJSON(response._data); }
        return response;
    }

    /**
     */
    async getNotifications(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<NotificationsResource> {
        const response = await this.getNotificationsRaw(initOverrides);
        return response._data;
    }

    /**
     */
    async getUserRaw(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<UserResource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<UserResource>({
            path: `/navbar/api/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=UserResourceFromJSON(response._data); }
        return response;
    }

    /**
     */
    async getUser(initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<UserResource> {
        const response = await this.getUserRaw(initOverrides);
        return response._data;
    }

    /**
     */
    async selectIdentityRaw(requestParameters: SelectIdentityOperationRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<void>({
            path: `/navbar/api/identity`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectIdentityRequestToJSON(requestParameters['selectIdentityRequest']),
        }, initOverrides);

        return response;
    }

    /**
     */
    async selectIdentity(requestParameters: SelectIdentityOperationRequest = {}, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<void> {
        await this.selectIdentityRaw(requestParameters, initOverrides);
    }

}
