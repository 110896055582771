import {setCssVariable, useDisplayMobile} from '#malt/nuxt-utils-module';
import {NavbarContext, useNavbarState} from '@navbar-registry';
import {defineStore} from 'pinia';
import {computed} from 'vue';
import {NavbarStateResourceNavbarDisplayModeEnum} from '@navbar-api';
import {NavBarWidth} from '../aside-menu.types';
import {useLogger} from '#imports';

/** Pinia store, dot not destructure */
export const useDisplayMode = defineStore('navbar-display-mode', () => {
    const navbarState = useNavbarState();
    const logger = useLogger();
    const displayMode = computed<NavbarStateResourceNavbarDisplayModeEnum>(() => navbarState.state?.navbarDisplayMode);

    const {isDisplayMobile} = useDisplayMobile();

    const isDisplayModeFunnel = computed<boolean>(() => displayMode.value === NavbarStateResourceNavbarDisplayModeEnum.Funnel);
    const isDisplayModeFull = computed<boolean>(() => displayMode.value === NavbarStateResourceNavbarDisplayModeEnum.Full);
    const isDisplayModeReduced = computed<boolean>(
        () => displayMode.value === NavbarStateResourceNavbarDisplayModeEnum.Reduced && !isDisplayMobile.value,
    );

    /** Used for ssr initial rendering */
    const getCurrentNavbarWidth = computed(() => getDisplayModeNavbarWidth(displayMode.value));

    async function setNavbarDisplayMode(mode?: NavbarStateResourceNavbarDisplayModeEnum): Promise<void> {
        const finalMode = mode ?? displayMode.value ?? NavbarStateResourceNavbarDisplayModeEnum.Full;
        const width = getDisplayModeNavbarWidth(finalMode);
        setCssVariable('--aside-menu-width', width);
        setCssVariable('--layout-padding-left', width);
        navbarState.updateNavbarDisplayMode(finalMode);
        await saveDisplayMode(finalMode);
    }

    function toggleNavbarDisplayMode(): void {
        if (displayMode.value === NavbarStateResourceNavbarDisplayModeEnum.Full) {
            setNavbarDisplayMode(NavbarStateResourceNavbarDisplayModeEnum.Reduced);
        } else {
            setNavbarDisplayMode(NavbarStateResourceNavbarDisplayModeEnum.Full);
        }
    }

    function getDisplayModeNavbarWidth(mode: NavbarStateResourceNavbarDisplayModeEnum) {
        switch (mode) {
            case NavbarStateResourceNavbarDisplayModeEnum.Full:
                return `${NavBarWidth.Full}px`;
            case NavbarStateResourceNavbarDisplayModeEnum.Reduced:
                return `${NavBarWidth.Reduced}px`;
            case NavbarStateResourceNavbarDisplayModeEnum.Funnel:
                return `0px`;
            default:
                return '0px';
        }
    }

    async function saveDisplayMode(mode: NavbarStateResourceNavbarDisplayModeEnum) {
        try {
            if (mode !== NavbarStateResourceNavbarDisplayModeEnum.Funnel) {
                await NavbarContext.changeNavbarState({changeStateRequest: {state: mode}});
            }
        } catch (e) {
            logger.error(e);
        }
    }

    return {
        displayMode,
        isDisplayModeFunnel,
        isDisplayModeFull,
        isDisplayModeReduced,
        getCurrentNavbarWidth,
        setNavbarDisplayMode,
        toggleNavbarDisplayMode,
    };
});
