import {useCurrentHost, useDataTracking, useLocale, useNuxtApp} from '#imports';
import {
    CompanyIcon,
    DashboardIcon,
    InboxIcon,
    InvoiceIcon,
    ManageProjectsIcon,
    PlusIcon,
    ProfileIcon,
    ResourcesIcon,
    SettingsIcon,
} from '@navbar-logged/common/icons';
import {useNotifications} from '@navbar-logged/features/notifications';
import {useProfilePreferences, useUser} from '@navbar-logged/features/user';
import {storeToRefs} from 'pinia';
import {computed} from 'vue';
import type {NavigationTab} from '../../aside-menu.types';
import {useFilteredTabs} from '../useFilteredTabs';
import type {NavigationTabsRecord} from './navigation.types';
import {useFeatureFlagsStoreLogged, useNavbarState} from '@navbar-registry';

export function useFreelancerNavigationTabs(): NavigationTabsRecord {
    const {$i18next} = useNuxtApp();
    const userStore = useUser();
    const {isCurrentDomainExtensionOneOf, getCurrentHost} = useCurrentHost();
    const {locale} = useLocale();
    const isPlatformAdmin = computed<boolean>(() => !!userStore.user?.options.platformAdmin);
    const notificationsStore = useNotifications();
    const currentLanguage = computed(() => userStore.user?.currentLanguage);
    const currentCountry = computed(() => userStore.user?.currentCountry);
    const logoNavigationRoute = computed(() => '/dashboard/freelancer');
    const {track} = useDataTracking();

    const preferenceStore = useProfilePreferences();
    const {preferences} = storeToRefs(preferenceStore);

    const navbarState = useNavbarState();
    const {state} = storeToRefs(navbarState);

    const {features} = useFeatureFlagsStoreLogged();

    const freelancerOnboarded = computed(() => userStore.user?.options.onboarded);

    const resourcesUrls: {[index: string]: string} = {
        FR: '/resources',
        'en-FR': 'https://www.malt.fr/resources',
        GB: '/resources',
        BE: '/resources',
        'en-BE': 'https://fr.malt.be/c/resources',
        DE: '/resources',
        'en-DE': 'https://www.malt.de/resources',
        ES: '/resources',
        'en-ES': 'https://www.malt.es/resources',
        NL: '/resources',
        'en-NL': 'https://www.malt.nl/resources',
        'fr-CH': 'https://www.malt.fr/resources',
    };
    const resourceUrl = computed(() => {
        if (currentLanguage.value && currentCountry && resourcesUrls[currentLanguage.value + '-' + currentCountry.value]) {
            return resourcesUrls[currentLanguage.value + '-' + currentCountry.value];
        }
        if (currentCountry.value && resourcesUrls[currentCountry.value as string]) {
            return resourcesUrls[currentCountry.value as string];
        }
        return 'https://www.malt.uk/resources';
    });
    const partnersUrl = computed(() => ({
        href: `//${getCurrentHost().replace(/^en/, 'www')}/c/freelancers/partners`,
        blank: true,
    }));

    const faqUrl = computed(() => ({
        href: `https://help.malt.com/kb/${currentLanguage.value}/18216`,
        blank: true,
    }));

    const createNavigationTab = computed(
        (): NavigationTab => ({
            id: 'freelancer-create',
            title: $i18next.t('navbar.aside.tabs.title.freelancer.create'),
            icons: {mainIcon: PlusIcon},
            border: true,
            showChildrenInADropdown: true,
            children: [
                {
                    id: 'freelancer-create-a-quote',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.quote'),
                    url: {href: '/proposal/draft'},
                    action: () => track('quote_started', {source: 'navbar'}),
                },
                {
                    id: 'freelancer-create-a-business-referral',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.business.referral'),
                    url: state.value.isBusinessReferralOnboardingAcknowledged
                        ? {
                              href: $i18next.t('business-referral.typeform.link_href', {locale: locale.value.replace('-', '_')}),
                              blank: true,
                          }
                        : {href: '/business-referral'},
                },
            ],
        }),
    );

    const manageProjectsNavigationTab = computed<NavigationTab>(() => {
        return {
            id: 'freelancer-manage-projects',
            title: $i18next.t('navbar.aside.tabs.title.freelancer.manage.projects'),
            icons: {mainIcon: ManageProjectsIcon},
            children: [
                {
                    id: 'freelancer-my-projects',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.my.projects'),
                    url: {href: '/project-freelancer/?filter=freelancer'},
                    isActiveWhen: ({startWith}: any) => startWith('/project-freelancer'),
                },
                {
                    id: 'freelancer-my-referrals',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.my.referrals'),
                    url: {href: '/business-referral/my-referrals'},
                    isActiveWhen: ({startWith}: any) => startWith('/business-referral'),
                },
            ],
        };
    });

    const freelancerComplianceNotificationCounter = computed(() => {
        if (isCurrentDomainExtensionOneOf(['es', 'fr'])) {
            return notificationsStore.notificationsRecord?.freelancerCompliance;
        }

        return 0;
    });

    const navigationRoutes = computed((): NavigationTab[] => {
        // Error 500 bug randomly appearing https://maltcommunity.slack.com/archives/C03PWC9DPGE/p1683204841095379
        if ($i18next) {
            return [
                createNavigationTab.value,
                {
                    id: 'freelancer-dashboard',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.dashboard'),
                    url: {href: '/dashboard/freelancer'},
                    icons: {mainIcon: DashboardIcon},
                    isActiveWhen: ({startWith}) => startWith('/dashboard/freelancer') && !startWith('/dashboard/freelancer/analytics'),
                },
                {
                    id: 'freelancer-inbox',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.inbox'),
                    icons: {mainIcon: InboxIcon},
                    url: {href: '/messages'},
                    isActiveWhen: ({startWith}) => startWith('/messages'),
                    notificationsCount: notificationsStore.notificationsRecord?.messages,
                },
                {
                    id: 'freelancer-enhance-my-profile',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.enhance.my.profile'),
                    icons: {mainIcon: ProfileIcon},
                    children: [
                        {
                            id: 'freelancer-view-profile',
                            title: $i18next.t('navbar.aside.tabs.title.freelancer.view.profile'),
                            url: {href: '/profile'},
                            isActiveWhen: ({startWith}) => {
                                return startWith('/profile') && !startWith('/profile/mission-preferences');
                            },
                            notificationsCount: notificationsStore.notificationsRecord?.profileActions,
                        },
                        {
                            id: 'freelancer-stats',
                            constraint: preferences.value.searchVisibilityChoice,
                            title: $i18next.t('navbar.aside.tabs.title.freelancer.stats'),
                            url: {href: '/dashboard/freelancer/analytics'},
                            isActiveWhen: ({startWith}) => startWith('/dashboard/freelancer/analytics'),
                        },
                        {
                            id: 'freelancer-project-preferences',
                            title: $i18next.t('navbar.aside.tabs.title.freelancer.project.preferences'),
                            url: {href: '/profile/mission-preferences'},
                            isActiveWhen: ({exact}) => exact('/profile/mission-preferences'),
                        },
                    ],
                },
                manageProjectsNavigationTab.value,
                {
                    id: 'freelancer-invoices-payments',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.invoices.and.payments'),
                    url: {href: '/invoicing/freelancer'},
                    icons: {mainIcon: InvoiceIcon},
                    isActiveWhen: ({startWith}) =>
                        startWith('/invoicing/freelancer') || startWith('/company/payment_in') || startWith('/company-wallet'),
                },
                {
                    separator: 'top',
                    id: 'freelancer-company',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.company'),
                    url: {href: '/company'},
                    icons: {mainIcon: CompanyIcon},
                    isActiveWhen: ({startWith}) => startWith('/freelancer/company'),
                    notificationsCount: freelancerComplianceNotificationCounter.value,
                },
                {
                    id: 'freelancer-resources',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.resources'),
                    icons: {mainIcon: ResourcesIcon},
                    children: [
                        {
                            id: 'freelancer-resources-center',
                            title: $i18next.t('navbar.aside.tabs.title.freelancer.resources.center'),
                            url: {href: resourceUrl.value, blank: true},
                        },
                        {
                            id: 'freelancer-daily-rate-tracker',
                            title: $i18next.t('navbar.aside.tabs.title.freelancer.daily.rate.tracker'),
                            constraint: currentCountry.value === 'FR' && currentLanguage.value === 'fr',
                            url: {href: '/t/barometre-tarifs'},
                            isActiveWhen: ({startWith}) => startWith('/t/barometre-tarifs'),
                        },
                        {
                            id: 'freelancer-daily-rate-calculator',
                            title: $i18next.t('navbar.aside.tabs.title.freelancer.daily.rate.calculate'),
                            constraint: currentCountry.value === 'ES' && currentLanguage.value === 'es',
                            url: {href: 'https://calculadora.malt.es/', blank: true},
                        },
                        {
                            id: 'freelancer-partners',
                            title: $i18next.t('navbar.aside.tabs.title.freelancer.partners'),
                            url: partnersUrl.value,
                        },
                        {
                            id: 'freelancer-help-center',
                            title: $i18next.t('navbar.aside.tabs.title.freelancer.help.center'),
                            url: faqUrl.value,
                        },
                    ],
                },
                {
                    id: 'freelancer-settings',
                    title: $i18next.t('navbar.aside.tabs.title.freelancer.settings'),
                    icons: {mainIcon: SettingsIcon},
                    url: {href: '/account'},
                    isActiveWhen: ({startWith}) => startWith('/account'),
                },
            ];
        }
        return [];
    });

    const allNavigationRoutes = computed((): NavigationTab[] => {
        if (import.meta.client && !freelancerOnboarded.value && window.location.pathname === '/dashboard/freelancer/') {
            return navigationRoutes.value.concat([{id: 'navbar-availability', title: ''}]);
        }
        return navigationRoutes.value;
    });

    const {filteredNavigationRoutes} = useFilteredTabs(allNavigationRoutes);

    return {
        logoNavigationRoute,
        allNavigationRoutes,
        filteredNavigationRoutes,
    };
}
