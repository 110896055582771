<template>
    <SvgIcon v-bind="props">
        <template #default>
            <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.1738 2.98341C14.3449 2.98674 13.5299 3.1955 12.8015 3.59099C12.0731 3.98647 11.4542 4.55636 11 5.24966C10.4051 4.35573 9.53882 3.67644 8.52883 3.31177C7.51884 2.94711 6.41846 2.91634 5.38967 3.224C4.36088 3.53165 3.45804 4.16148 2.81407 5.02076C2.17009 5.88004 1.81901 6.92337 1.8125 7.99716C1.8125 15.5134 11 18.0159 11 18.0159C11 18.0159 20.1875 15.5134 20.1875 7.99716C20.1887 7.33842 20.0598 6.68593 19.8082 6.07711C19.5566 5.4683 19.1874 4.91513 18.7216 4.44933C18.2558 3.98353 17.7026 3.61426 17.0938 3.36271C16.485 3.11115 15.8325 2.98225 15.1738 2.98341Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                />
                <path
                    d="M15.1737 6.3262C15.3935 6.32504 15.6113 6.36747 15.8146 6.45105C16.0179 6.53463 16.2026 6.65769 16.358 6.81311C16.5134 6.96853 16.6365 7.15323 16.7201 7.35652C16.8036 7.5598 16.8461 7.77765 16.8449 7.99745C16.8325 8.72454 16.6752 9.44187 16.3823 10.1074C16.0893 10.773 15.6665 11.3735 15.1387 11.8737"
                    stroke="#333330"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </svg>
        </template>
        <template #active>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.5775 2.55762C11.8671 2.56048 11.1685 2.73942 10.5442 3.07841C9.91984 3.4174 9.3893 3.90587 9 4.50012C8.49004 3.73391 7.74756 3.15165 6.88185 2.83908C6.01615 2.52651 5.07296 2.50014 4.19114 2.76385C3.30932 3.02755 2.53546 3.5674 1.98349 4.30393C1.43151 5.04045 1.13058 5.93474 1.125 6.85512C1.125 13.2976 9 15.4426 9 15.4426C9 15.4426 16.875 13.2976 16.875 6.85512C16.876 6.29049 16.7655 5.73122 16.5499 5.20937C16.3343 4.68753 16.0178 4.21339 15.6185 3.81413C15.2192 3.41487 14.7451 3.09836 14.2233 2.88274C13.7014 2.66712 13.1421 2.55664 12.5775 2.55762Z"
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                />
                <path
                    d="M12.5 9.5C12.5 11 11 12.5 9 12.5C7 12.5 5.5 11 5.5 9.5"
                    stroke="white"
                    stroke-width="1.48"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </template>
    </SvgIcon>
</template>

<script lang="ts" setup>
    import SvgIcon from '@navbar-logged/common/ui/SvgIcon.vue';
    import {commonIconProps} from '@navbar-logged/common/icons/common-props';

    const props = defineProps(commonIconProps);
</script>
