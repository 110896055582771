import {usePrimeVue} from '@primevue/core/config';
import {getDays, getMonths} from '@malt/dates';

export function setupCalendarTranslations(): void {
    const primevue = usePrimeVue();

    primevue.config = {
        ...primevue.config,
        locale: {
            ...primevue.config.locale,
            dayNames: getDays('long'),
            dayNamesShort: getDays('short'),
            dayNamesMin: getDays('short'),
            monthNames: getMonths('long'),
            monthNamesShort: getMonths('short'),
            fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        },
    };
}
