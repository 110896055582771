export function isTouchDevice(): boolean {
    if (typeof window == 'undefined') {
        console.warn('------');
        console.warn(
            '[SSR mode]: MaltCalendar is based in window settings to dissociate mobile rendering and desktop. Please use <ClientOnly /> around your component',
        );
        console.warn('------');
        return false;
    }
    // @ts-ignore
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}
